<template>
  <div ref="el">
    <!-- Dropdown Кнопка -->
    <PlaylistDropdown :is-open="isOpen" @toggle="$emit('toggle')" @active="$emit('active')">
      <template v-slot:icon>
        <BookmarkIcon
          v-if="activePlaylist && activePlaylist.code === MOMENTS_FEED_PLAYLIST"
          :class="$style.playlistsIcon"
        />
        <app-image
          v-else-if="activePlaylist && activePlaylist.icon"
          :src="activePlaylist.icon"
          :class="$style.playlistsIcon"
        />

        <KinomIcon v-else :class="$style.icon" />
      </template>
      <template v-slot:text>{{ playlistName || $t('pages.moments.dropdown.title') }}</template>
    </PlaylistDropdown>

    <!-- <Transition> -->
    <section v-if="isOpen" :class="$style.playlists">
      <ScrollViewport tag="ul" :y="offset" role="list">
        <!-- Что посмотреть -->
        <li>
          <PlaylistItem
            ref="playlistElements"
            :is-selected="!activePlaylist.code"
            @selected="onSelectPlaylist(null, $t('pages.moments.dropdown.title'))"
            @active="setActivePlaylist(0, playlistElements)"
          >
            <template v-slot:icon><KinomIcon :class="$style.playlistsIcon" /></template>
            <template v-slot:text>{{ $t('pages.moments.dropdown.title') }}</template>
          </PlaylistItem>
        </li>

        <!-- Моя коллекция -->
        <li v-if="user">
          <PlaylistItem
            ref="playlistElements"
            :is-selected="activePlaylist.code === MOMENTS_FEED_PLAYLIST"
            @selected="onSelectPlaylist({ code: MOMENTS_FEED_PLAYLIST }, $t('pages.collection.header'))"
            @active="setActivePlaylist(1, playlistElements)"
          >
            <template v-slot:icon><BookmarkIcon :class="$style.playlistsIcon" /></template>
            <template v-slot:text>{{ $t('pages.collection.header') }}</template>
          </PlaylistItem>
        </li>

        <!-- Плейлисты -->
        <li v-for="(playlist, index) in playlists" :key="playlist.code" ref="playlistElements">
          <PlaylistItem
            ref="playlistElements"
            :is-selected="activePlaylist.code === playlist.code"
            @selected="onSelectPlaylist(playlist, playlist.name)"
            @active="setActivePlaylist(index + user ? 2 : 1, playlistElements)"
          >
            <template v-slot:icon>
              <app-image
                :src="playlist.icon"
                :class="{
                  [$style.playlistsIcon]: true,
                  [$style.playlistsIconActive]: playlist.code === focusPlaylist,
                }"
              />
            </template>
            <template v-slot:text>{{ playlist.name }}</template>
          </PlaylistItem>
        </li>
      </ScrollViewport>
    </section>
    <!-- </Transition> -->
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import KinomIcon from '@SMART/assets/icons/40x40/kinom.svg';
import BookmarkIcon from '@SMART/assets/icons/51x51/save.svg';
import { computed, provide, ref } from '@vue/composition-api';

import AppImage from '@/components/app-image/AppImage.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import PlaylistDropdown from './PlaylistDropdown.vue';
import PlaylistItem from './PlaylistItem.vue';

const MOMENTS_FEED_PLAYLIST = ConstantsConfigInstanceSmartTV.getProperty('momentsFeedPlaylistCollection');

export default {
  components: {
    AppImage,
    BookmarkIcon,
    KinomIcon,
    PlaylistDropdown,
    ScrollViewport,
    PlaylistItem,
  },
  props: {
    playlists: Array,
    user: [String, Object],
    offset: Number,
    isOpen: Boolean,
    isMomentsOpen: Boolean,
    activeMomentIndex: Number,
    activeIndex: Number,
  },
  setup(props, { emit }) {
    const playlistName = ref('');
    const activePlaylist = ref({});
    const focusPlaylist = ref('');
    const playlistElements = ref();

    const onBackPress = () => {
      emit('toggle');
    };

    const onSelectPlaylist = async (playlist, text) => {
      activePlaylist.value = playlist || {};

      emit('select', playlist);
      onBackPress();
      if (text) {
        playlistName.value = text;
      }
    };

    const setActivePlaylist = async (index, list) => {
      emit('index', index);
      const OFFSET = 5;
      // skip 'Что посмотреть' and 'Моя коллекция'
      const elementOffset = list?.[index - (props.user ? 2 : 1)]?.offsetTop || 0;
      const firstElementOffset = list?.[0]?.offsetTop || 0;

      const newOffset = list && index > OFFSET ? elementOffset - firstElementOffset * OFFSET : 0;

      emit('offset', newOffset);
    };

    return {
      MOMENTS_FEED_PLAYLIST,
      activePlaylist,
      focusPlaylist,
      playlistElements,
      playlistName,
      onSelectPlaylist,
      setActivePlaylist,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.icon {
  margin-right: adjustPx(16px);
  width: adjustPx(48px);
}

.selected {
  border-radius: adjustPx(32px);
  background: var(--color-notheme-bg-hover-80);
}

.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);
}

.iconActive {
  filter: brightness(0%);
}

.iconRotated {
  transform: rotate(180deg);
  transition-duration: 0.3s;
  transition-property: transform;
}

.playlist {
  width: adjustPx(48px);
  height: adjustPx(48px);
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .iconWithText {
    display: flex;
    align-items: center;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: adjustPx(16px);
  padding: 0;
  width: adjustPx(96px);
  height: adjustPx(96px);
  border-radius: adjustPx(24px);

  @include f-label-2;
}

.playlists {
  position: fixed;
  top: adjustPx(192px);
  right: adjustPx(158px);
  z-index: map-get($map: $layers, $key: --z-index-modal);
  display: flex;
  flex-flow: column;
  padding-top: adjustPx(24px);
  width: adjustPx(604px);
  overflow: hidden;

  &Item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: adjustPx(24px);
    padding: adjustPx(24px);
    width: adjustPx(604px);
    height: adjustPx(96px);
    border-radius: adjustPx(24px);
    background: var(--color-notheme-bg-secondary-80);
  }

  &Selected {
    border-radius: adjustPx(32px);
    background: var(--color-notheme-bg-hover-80);
  }

  &Active {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);

    .text {
      .playlistsIcon {
        filter: brightness(0%);
      }
    }
  }

  &Icon {
    margin-right: adjustPx(16px);
    width: adjustPx(48px);
    height: adjustPx(48px);
  }
}
</style>
