import { onActivated, onDeactivated, ref } from '@vue/composition-api';

export default function useRouteCheck() {
  const isSameRoute = ref(true);

  onActivated(() => {
    isSameRoute.value = true;
  });

  onDeactivated(() => {
    isSameRoute.value = false;
  });

  return {
    isSameRoute,
  };
}
