<template>
  <div :class="$style.wrapper">{{ playButtonSubtitle }}</div>
</template>

<script>
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { storeToRefs, useContentStore, useSessionStore } from '@SMART/index';
import { computed } from '@vue/composition-api';

export default {
  setup() {
    const { isActiveSubscription, isPartnerSubscription, isPartnerUser } = storeToRefs(useSessionStore());
    const { content } = storeToRefs(useContentStore());

    const playButtonSubtitle = computed(() => {
      return playerHelpers.getPlayButtonSubtitle({
        isActiveSubscription: isActiveSubscription.value,
        isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
        content: content.value,
      });
    });

    return {
      playButtonSubtitle,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.wrapper {
  @include smartTvFonts.SmartTvBody-2();
}
</style>
