import { AnalyticService } from './analytics/analytic-service';
import { AuthService } from './auth/auth-service';
import { CatalogService } from './catalog/catalog-service';
import { ChannelsService } from './channels/channels-service';
import { CollectionService } from './collection/collection-service';
import { ContentCacheManager } from './content-cache-manager';
import { DeviceService } from './device/device-service';
import { EnvironmentService } from './environment/environment-service';
import { CustomEventsService } from './events/custom-events-service';
import { FeatureService } from './features/feature-service';
import globalEmitter from './global-emitter/global-emitter';
import { globalSettings } from './global-settings';
import { LifecycleService } from './lifecycle/lifecycle-service';
import { MarketingService } from './marketing/marketing-service';
import { MomentService } from './moment/moment-service';
import { AlertMessageTypes, alertService } from './notifications/alert-service';
import { OffersService } from './offers/offers-service';
import { Onboardings, OnboardingService } from './onboarding/onboarding-service';
import { ParentalService } from './parental/parental-service';
import { PaymentsService } from './payments/payments-service';
import { PlaylistService } from './playlist/playlist-service';
import { QRService } from './qr-service/QRService';
import { RequestService } from './request-service';
import { RouterService } from './router/router-service';
import { SearchService } from './search/search-service';
import { type IStorageService, StorageService } from './storage/storage-service';
import { TelemetryService } from './telemetry/telemetry-service';
import { UserService } from './user/user-service';

const environmentService = new EnvironmentService();
const storageService: IStorageService = new StorageService();
const routerService = new RouterService();
const deviceService = new DeviceService(storageService, environmentService, routerService);
const requestService = new RequestService(deviceService, storageService, environmentService, alertService);

const contentCacheManager = new ContentCacheManager();
const paymentsService = new PaymentsService(environmentService, requestService);
const qrService = new QRService();
const userService = new UserService(requestService, storageService, deviceService, environmentService);

const authService = new AuthService(requestService, deviceService, userService, storageService, routerService);
const featuresService = new FeatureService(environmentService, requestService);

const analyticService = new AnalyticService(deviceService, environmentService, routerService, featuresService);
const catalogService = new CatalogService(requestService, storageService, deviceService);

const lifecycleService = new LifecycleService(deviceService);

const playlistService = new PlaylistService(requestService);
const channelsService = new ChannelsService(requestService);
const searchService = new SearchService(requestService, deviceService);
const offersService = new OffersService(requestService);
const marketingService = new MarketingService(deviceService, routerService);

const parentalService = new ParentalService(requestService);
const momentService = new MomentService(requestService, storageService);
const collectionService = new CollectionService(requestService, storageService);
const onboardingService = new OnboardingService(storageService);
const telemetryService = new TelemetryService(storageService);

const customEventsService = new CustomEventsService(routerService);

export {
  AlertMessageTypes,
  alertService,
  analyticService,
  authService,
  catalogService,
  channelsService,
  collectionService,
  contentCacheManager,
  customEventsService,
  deviceService,
  environmentService,
  featuresService,
  globalEmitter,
  globalSettings,
  lifecycleService,
  marketingService,
  momentService,
  offersService,
  Onboardings,
  onboardingService,
  parentalService,
  paymentsService,
  playlistService,
  qrService,
  requestService,
  routerService,
  searchService,
  storageService,
  telemetryService,
  userService,
};
