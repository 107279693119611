<template>
  <div class="page-container">
    <QrLogin v-if="type === 'qr'" @update:type="onTypeChange" />
  </div>
</template>

<script>
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import { analyticService } from '@package/smarttv-base/src';
import { onMounted, ref } from '@vue/composition-api';

import QrLogin from './components/QrLogin.vue';

export default {
  components: {
    QrLogin,
  },
  setup() {
    const type = ref('qr');
    const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);

    const onTypeChange = (value) => {
      type.value = value;
    };

    onMounted(() => {
      accountPageAnalytics.onShowAuthLoginTv();
    });

    return {
      type,
      onTypeChange,
    };
  },
};
</script>
