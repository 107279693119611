<template>
  <div ref="el">
    <MomentDropdown
      :is-open="isOpen"
      :disabled="!moments.length"
      @toggle="$emit('toggle', activeIndex)"
      @active="$emit('active')"
    />

    <Transition>
      <MomentItem
        v-if="activeMoment && isActiveMomentPinned && isOpen"
        is-pinned
        :moment="activeMoment"
        :is-selected="true"
        :class="$style.pinned"
        @selected="onSelectMoment(activeMomentIndex)"
      />
    </Transition>

    <Transition>
      <section v-if="isOpen" :class="$style.moments">
        <ScrollViewport tag="ul" :y="offset" role="list">
          <li v-for="(item, index) in moments" :key="item.id" ref="momentElements">
            <MomentItem
              :class="{ [$style.hidden]: index < activeIndex }"
              :moment="item"
              :is-selected="activeMoment && activeMoment.id === item.id"
              :focus-key="`${FocusKeys.MOMENTS_DROPDOWN}-${index}`"
              @selected="onSelectMoment(index)"
              @active="setActiveMoment(index, momentElements)"
            />
          </li>

          <Transition>
            <li v-if="!isLastPage">
              <div :class="$style.moment">
                <UISpinner />
              </div>
            </li>
          </Transition>
        </ScrollViewport>
      </section>
    </Transition>
  </div>
</template>

<script>
import { FocusKeys } from '@SMART/index';
import { computed, ref } from '@vue/composition-api';

import UISpinner from '@/components/loader/UISpinner.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import MomentDropdown from './MomentDropdown.vue';
import MomentItem from './MomentItem.vue';

export default {
  components: {
    MomentDropdown,
    ScrollViewport,
    MomentItem,
    UISpinner,
  },
  props: {
    moments: Array,
    activeMoment: Object,
    offset: Number,
    activeIndex: Number,
    isOpen: Boolean,
    isLastPage: Boolean,
    isPlaylistsOpen: Boolean,
    activePlaylistIndex: Number,
  },
  setup(props, { emit }) {
    const momentElements = ref();

    const onSelectMoment = async (index) => {
      emit('select', index);
      emit('index', index);
      emit('toggle', index);
    };

    const activeMomentIndex = computed(() => props.moments?.findIndex((x) => x.id === props.activeMoment?.id) ?? -1);
    const isActiveMomentPinned = computed(() => props.activeIndex > activeMomentIndex.value);

    const setActiveMoment = (index, list) => {
      const elementOffset = list?.[index]?.offsetTop || 0;
      const elementHeight = list?.[index]?.getBoundingClientRect()?.height || 0;
      let newOffset = 0;

      if (index === 0) {
        newOffset = 0;
      } else if (props.activeIndex === activeMomentIndex.value && index > props.activeIndex) {
        newOffset = elementOffset - elementHeight;
      } else if (props.activeIndex === activeMomentIndex.value && index === props.activeIndex) {
        newOffset = newOffset = elementOffset;
      } else if (
        isActiveMomentPinned.value &&
        props.activeIndex > activeMomentIndex.value &&
        index === activeMomentIndex.value
      ) {
        newOffset = elementOffset;
      } else if (!isActiveMomentPinned.value && index > activeMomentIndex.value) {
        newOffset = elementOffset - elementHeight;
      } else if (!isActiveMomentPinned.value) {
        newOffset = elementOffset;
      } else {
        newOffset = elementOffset - elementHeight;
      }

      emit('index', index);
      emit('load', index);
      emit('offset', newOffset);
    };

    return { momentElements, onSelectMoment, setActiveMoment, isActiveMomentPinned, FocusKeys };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.moments {
  position: fixed;
  top: adjustPx(192px);
  right: adjustPx(48px);
  z-index: map-get($map: $layers, $key: --z-index-modal);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: adjustPx(24px);
  background-color: transparent;
  overflow: hidden;
}

.moment {
  display: flex;
  width: adjustPx(708px);
  height: adjustPx(180px);
  border-radius: adjustPx(24px);
  background-color: transparent;
}

.pinned {
  position: fixed;
  top: adjustPx(180px);
  right: adjustPx(58px);
  z-index: map-get($map: $layers, $key: --z-index-pinned);
  display: flex;
  width: adjustPx(708px);
  height: adjustPx(140px);
  border-radius: adjustPx(24px);
  overflow: hidden;

  img {
    width: adjustPx(280px) !important;
    height: adjustPx(140px) !important;
    border-radius: adjustPx(24px);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.3s linear,
    opacity 0.3s linear;
}

.stub {
  width: adjustPx(708px);
  height: adjustPx(180px);
}

$skeleton-background-size: adjustPx(300px);

@keyframes skeleton-animation {
  0% {
    background-position: -$skeleton-background-size;
  }
  100% {
    background-position: $skeleton-background-size;
  }
}

.skeleton {
  width: adjustPx(708px);
  height: adjustPx(180px);
  border-radius: adjustPx(24px);
  background: var(--color-bg-modal);
  animation-name: skeleton-animation;
  animation-duration: 1.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
</style>
