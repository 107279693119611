<template>
  <div :class="$style.infoItem">
    <h3 :class="$style.infoTitle">{{ title }}</h3>
    <p :class="$style.infoValue">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style module lang="scss">
@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/mixins';

.infoItem {
  display: flex;
  flex: 1;
  flex-basis: 0;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: adjustPx(24px);
  min-width: adjustPx(240px);
}

.infoTitle {
  color: var(--color-text-tertiary);

  @include SmartTvBody-2();
}

.infoValue {
  margin-top: adjustPx(8px);
  color: var(--color-text-primary);

  @include SmartTvBody-2();
}
</style>
