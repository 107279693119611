<template>
  <div :class="$style.icon">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    width: { type: Number },
    height: { type: Number },
  },
};
</script>

<style lang="scss" module>
@import '@/styles/mixins';

.icon {
  display: inline-block;
  flex-shrink: 0;
  width: adjustPx(48px);
  height: adjustPx(48px);
  line-height: 1em;
  color: currentColor;
  text-align: center;
  vertical-align: initial;
  user-select: none;
}
</style>
