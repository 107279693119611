<template>
  <section ref="el" :class="$style.navigation">
    <section>
      <template v-if="isActiveSubscription">
        <NavigatableItem
          :tag="AppButton"
          variation="smart-button-secondary"
          :focus-key="FocusKeys.SETTINGS_PERSONAL_DATA_TAB"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.personDataButton')"
          @click="onSelectSection($RouterPage.PersonalData)"
        />
        <NavigatableItem
          :tag="AppButton"
          :focus-key="FocusKeys.SETTINGS_CONTACTS_TAB"
          variation="smart-button-secondary"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.contactsButton')"
          @click="onSelectSection($RouterPage.HelpContacts)"
        />
        <NavigatableItem
          v-if="isMainProfile"
          :tag="AppButton"
          :focus-key="FocusKeys.SETTINGS_SUBSCRIBE_TAB"
          variation="smart-button-secondary"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.subscribeButton')"
          @click="onSelectSection($RouterPage.Subscriptions)"
        />
      </template>
      <template v-else>
        <NavigatableItem
          v-if="isMainProfile"
          :tag="AppButton"
          :focus-key="FocusKeys.SETTINGS_SUBSCRIBE_TAB"
          variation="smart-button-secondary"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.subscribeButton')"
          @click="onSelectSection($RouterPage.Subscriptions)"
        />
        <NavigatableItem
          :tag="AppButton"
          variation="smart-button-secondary"
          :focus-key="FocusKeys.SETTINGS_PERSONAL_DATA_TAB"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.personDataButton')"
          @click="onSelectSection($RouterPage.PersonalData)"
        />
        <NavigatableItem
          :tag="AppButton"
          :focus-key="FocusKeys.SETTINGS_CONTACTS_TAB"
          variation="smart-button-secondary"
          :class="$style.navigationButton"
          :active-class="$style.active"
          :text="$t('pages.settings.contactsButton')"
          @click="onSelectSection($RouterPage.HelpContacts)"
        />
      </template>
    </section>
    <NavigatableItem
      :tag="AppButton"
      variation="smart-button-secondary"
      :class="$style.navigationButton"
      :active-class="$style.active"
      :text="$t('pages.settings.logoutButton')"
      @click="onLogout"
    />
  </section>
</template>

<script>
import { AnalyticPageName, useAccountPageAnalytics } from '@package/sdk/src/analytics';
import { ProfileType } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  analyticService,
  FocusKeys,
  RouterPage,
  routerService,
  storeToRefs,
  useAuthActions,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';

export default {
  setup(_, { root: { $route: route }, emit }) {
    const sessionStore = useSessionStore();
    const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);
    const { profile, isActiveSubscription } = storeToRefs(sessionStore);
    const { showParentalPageIfNeeded } = useAuthActions();

    const isMainProfile = computed(() => profile.value?.kind === ProfileType.MAIN);

    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.SETTINGS_PAGE_NAVIGATION,
      hasGlobalAccess: true,
    });
    provide('parentFocusKey', focusKey.value);

    onMounted(async () => {
      await sessionStore.fetchUser({ forceFetchUser: true });
      showParentalPageIfNeeded();

      switch (route.name) {
        case RouterPage.UserSubscriptions:
          SpatialNavigation.setFocus(FocusKeys.SETTINGS_SUBSCRIBE_TAB);
          break;
        case RouterPage.PersonalData:
          SpatialNavigation.setFocus(FocusKeys.SETTINGS_PERSONAL_DATA_TAB);
          break;
        case RouterPage.HelpContacts:
          SpatialNavigation.setFocus(FocusKeys.SETTINGS_CONTACTS_TAB);
          break;
      }
    });

    const onSelectSection = (name) => {
      switch (name) {
        case RouterPage.Subscriptions:
          accountPageAnalytics.onGotoSubscribePage(AnalyticPageName.Account);
          break;
        case RouterPage.HelpContacts:
          accountPageAnalytics.onGotoSupportPage(AnalyticPageName.Account);
          break;
      }

      return routerService.push({ name });
    };

    const onLogout = () => {
      emit('logout');
    };

    return {
      el,
      isActiveSubscription,
      AppButton,
      FocusKeys,
      onSelectSection,
      isMainProfile,
      onLogout,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

.navigation {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: adjust.adjustPx(60px) 0 adjust.adjustPx(68px) 0;
  min-width: adjust.adjustPx(544px);
  height: 100%;
  background: var(--color-bg-secondary);

  &Button {
    justify-content: flex-start;
    margin: 0 adjust.adjustPx(40px);
    width: adjust.adjustPx(466px);
    border-color: transparent;
    border-radius: adjust.adjustPx(24px);
    background-color: transparent;
    text-align: start;
  }
}

.active {
  box-shadow: none;
}
</style>
