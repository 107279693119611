import { type AnalyticAuthPageFrom, useAuthPageAnalytics } from '@package/sdk/src/analytics';

import { type AuthState, useAuthStore } from '../../../stores/use-auth-store';
import { type SessionGetters, type SessionState, useSessionStore } from '../../../stores/use-session-store';
import { storeToRefs } from '../../../stores/utils/store-to-refs';
import { analyticService, globalSettings, onboardingService, routerService } from '../../index';
import { RouterPage } from '../../router/router';

type RouteLocationRaw = any;

interface IRedirectUserOptions {
  replace: boolean;
}

export enum AuthSteps {
  SignInUp = 'signInUp',
  SignInPassword = 'password',
  SignUpPassword = 'newPassword',
  RecoverPassword = 'restorePassword',

  RecoverCode = 'passwordCode',
}

export function useAuthActions() {
  const authStore = useAuthStore();
  const sessionStore = useSessionStore();
  const authPageAnalytics = useAuthPageAnalytics(analyticService.sender);

  const { isPartnerUser, isAuth, isActiveSubscription, isChildProfileSet, hasChildProfile } = storeToRefs<
    SessionState,
    SessionGetters,
    unknown
  >(sessionStore);
  const { backUrl, selectedOfferId, loginInput, otpCode } = storeToRefs<AuthState, unknown, unknown>(authStore);

  // Пока так
  const isBackUrlToOffers = globalSettings.computed(() => backUrl.value.includes('offer'));

  const redirectUserWithBackUrl = (route: RouteLocationRaw, options?: IRedirectUserOptions) => {
    if (options?.replace) {
      return routerService.replace(route);
    }

    return routerService.push(route);
  };

  const openAuthPage = (from?: AnalyticAuthPageFrom) => {
    if (!backUrl.value) {
      authStore.setBackUrl(routerService.fullPath);
    }

    if (from) {
      authPageAnalytics.onGotoAuthPage(analyticService.getAnalyticPageName(), from);
    }

    return routerService.push({ name: RouterPage.AuthPage });
  };

  const getBackRedirectUrl = (): RouteLocationRaw => {
    if (!backUrl.value) {
      return { name: isChildProfileSet.value ? RouterPage.ProfilesPage : RouterPage.MainPage };
    }

    return backUrl.value;
  };

  const getRedirectRouteAfterChoosingProfile = () => {
    if (backUrl.value) {
      return getBackRedirectUrl();
    }

    return { name: RouterPage.MainPage };
  };

  const getRedirectRouterAfterSuccessfulSubscription = () => {
    if (!isChildProfileSet.value) {
      return { name: RouterPage.ParentalPage };
    }

    return { name: RouterPage.ProfilesPage };
  };

  const getRedirectRouterAfterAuthorization = (): RouteLocationRaw => {
    if (isActiveSubscription.value) {
      return hasChildProfile.value ? { name: RouterPage.ProfilesPage } : getBackRedirectUrl();
    }

    if (!isPartnerUser.value) {
      if (selectedOfferId.value) {
        return { name: RouterPage.OfferInfo, params: { offerId: selectedOfferId.value } };
      }

      return getBackRedirectUrl();
    }

    return isBackUrlToOffers.value
      ? { name: RouterPage.MainPage, query: routerService._router.query }
      : getBackRedirectUrl();
  };

  const redirectWithHistoryClear = async (replace?: boolean) => {
    const route = getRedirectRouterAfterAuthorization();
    await redirectUserWithBackUrl(route, { replace: Boolean(replace) });

    sessionStore.fetchOffers();
    history.replaceState({ firstPage: true }, '');
    showParentalPageIfNeeded();
  };

  const redirect = async () => {
    await sessionStore.fetchUser();

    authStore.clearAuthArtifacts();
    await redirectWithHistoryClear(true);
  };

  const showParentalPageIfNeeded = () => {
    if (
      isAuth &&
      isActiveSubscription.value &&
      !isChildProfileSet.value &&
      !onboardingService.isParentalCodeFinished()
    ) {
      routerService.push({ name: RouterPage.ParentalPage });
    }
  };

  return {
    openAuthPage,
    redirectUserWithBackUrl,
    getRedirectRouterAfterAuthorization,
    getRedirectRouteAfterChoosingProfile,
    getRedirectRouterAfterSuccessfulSubscription,
    redirect,
    loginInput,
    otpCode,
    redirectWithHistoryClear,
    showParentalPageIfNeeded,
  };
}
