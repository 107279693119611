import useLogger from '@package/logger/src/use-logger';
import { Disposable } from '@package/sdk/src/core';
import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios';

import {
  type ApiFeatureToggleItem,
  availableAliasesToggleValues,
  type FeatureToggle,
  type FeatureToggleItem,
  FeatureToggleItemMapper,
} from './feature-toggle';

export interface UnleashApiGetRequestHeadersOptions {
  request: string;
  body: Record<string, any>;
  method: string;
}

interface UnleashApiConstructorOptions {
  baseURL: string;
  adapter: CreateAxiosDefaults['adapter'];
  getRequestHeaders: (options: UnleashApiGetRequestHeadersOptions) => Headers;
}

interface BaseRequestParams {
  with_variant: boolean;
}

const logger = useLogger('UnleashApi');

export class UnleashApi extends Disposable {
  private axios: AxiosInstance;

  constructor(private readonly options: UnleashApiConstructorOptions) {
    super();

    const { baseURL, adapter } = options;

    this.axios = axios.create({
      baseURL,
      timeout: 15000,
      adapter,
    });
  }

  private prepareHeaders(headers: Headers): Record<string, any> {
    const raw: Record<string, any> = {};

    headers.forEach((value, key) => {
      raw[key] = value;
    });

    return raw;
  }

  public async fetchAll(params: BaseRequestParams = { with_variant: true }): Promise<FeatureToggleItem[]> {
    const { getRequestHeaders } = this.options;

    const headers = getRequestHeaders({
      request: 'toggles/',
      method: 'GET',
      body: params,
    });

    try {
      const result = await this.axios.get<ApiFeatureToggleItem[]>('toggles/', {
        params,
        headers: this.prepareHeaders(headers),
      });

      return FeatureToggleItemMapper.mapMany(result.data);
    } catch (error) {
      logger.error('fetchAll', error);
      throw error;
    }
  }

  public async fetchByAliases(
    aliases: FeatureToggle[] = availableAliasesToggleValues,
    params: BaseRequestParams = { with_variant: true },
  ): Promise<FeatureToggleItem[]> {
    const { getRequestHeaders } = this.options;

    const headers = getRequestHeaders({
      request: 'toggles/',
      method: 'POST',
      body: params,
    });

    try {
      const result = await this.axios.post<ApiFeatureToggleItem[]>('toggles/', aliases, {
        params,
        headers: this.prepareHeaders(headers),
      });
      return FeatureToggleItemMapper.mapMany(result.data);
    } catch (error) {
      logger.error('fetchByAliases', error);
      throw error;
    }
  }

  public async fetchByAlias(
    alias: FeatureToggle,
    params: BaseRequestParams = { with_variant: true },
  ): Promise<FeatureToggleItem> {
    const { getRequestHeaders } = this.options;

    const headers = getRequestHeaders({
      request: `toggles/${alias}`,
      method: 'GET',
      body: params,
    });

    try {
      const result = await this.axios.get<ApiFeatureToggleItem>(`toggles/${alias}`, {
        params,
        headers: this.prepareHeaders(headers),
      });
      return FeatureToggleItemMapper.map(result.data);
    } catch (error) {
      logger.error('fetchByAlias', error);
      throw error;
    }
  }
}
