<template>
  <article :class="$style.illustration">
    <div :class="[$style.border, $style.borderLeft]" />
    <div :class="$style.slides">
      <img :class="$style.image" data-image src="@SMART/assets/images/my-channel-slider-1.webp" alt="my-channel" />
      <img
        data-image
        :class="[$style.image, $style.margin]"
        :width="250"
        src="@SMART/assets/images/my-channel-slider-2.webp"
        alt="my-channel"
      />
      <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-3.webp" alt="my-channel" />
      <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-4.webp" alt="my-channel" />
    </div>

    <div :class="$style.frame">
      <div :class="$style.frameSlides">
        <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-2.webp" alt="my-channel" />
        <img data-image :class="$style.image" src="@SMART/assets/images/my-channel-slider-3.webp" alt="my-channel" />
      </div>
      <KinomIcon :class="$style.frameIcon" />
    </div>
    <div :class="[$style.border, $style.borderRight]" />
  </article>
</template>

<script>
import KinomIcon from '@SMART/assets/icons/40x40/kinom.svg';

export default {
  components: {
    KinomIcon,
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.illustration {
  display: flex;
  align-items: center;
  margin-right: adjustPx(48px);
  width: adjustPx(960px);
  height: adjustPx(668px);
  overflow: hidden;

  .border {
    position: absolute;
    z-index: 10;
    width: adjustPx(96px);
    height: adjustPx(668px);
    background: linear-gradient(270deg, rgba(8, 17, 16, 1) 0%, rgba(18, 33, 33, 0) 100%);

    &Left {
      left: 0;
    }

    &Right {
      left: adjustPx(864px);
    }
  }

  .slides {
    position: fixed;
    top: adjustPx(438px);
    left: adjustPx(120px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: adjustPx(768px);

    .image {
      margin-right: adjustPx(18px);
      width: adjustPx(336px);
      height: adjustPx(216px);
      border-radius: adjustPx(36px);
      opacity: 0.6;
      object-fit: cover;
      -webkit-filter: blur(3px);
      filter: blur(3px);
    }

    .margin {
      margin-right: adjustPx(120px);
    }
  }

  .frame {
    position: fixed;
    top: adjustPx(360px);
    left: adjustPx(198px);
    display: flex;
    align-items: center;
    width: adjustPx(540px);
    height: adjustPx(360px);
    border-radius: adjustPx(90px);
    background: rgba(196, 196, 196, 0.01);
    overflow: hidden;

    box-shadow:
      0px 15.068px 67.805px 0px rgba(193, 114, 206, 0.3) inset,
      0px 369.16px 376.694px -180.813px rgba(0, 199, 178, 0.1) inset,
      0px -308.889px 256.152px -241.084px rgba(155, 205, 233, 0.3) inset,
      0px 21px 39px -12px #fff inset,
      0px 146.911px 210.949px -135.61px rgba(255, 255, 255, 0.4) inset;

    &Icon {
      position: absolute;
      top: adjustPx(102px);
      left: adjustPx(192px);
      width: adjustPx(156px);
      height: adjustPx(156px);
    }

    &Slides {
      position: absolute;
      left: adjustPx(-200px);
      display: flex;
      align-items: center;
      width: adjustPx(540px);
      height: adjustPx(360px);
      border-radius: adjustPx(90px);

      .image {
        margin-right: adjustPx(120px);
        width: adjustPx(390px);
        height: adjustPx(252px);
        border-radius: adjustPx(36px);
        object-fit: cover;
      }
    }
  }
}
</style>
