import { adjustPx, environmentService, translate } from '@package/smarttv-base/src';
import IconArrowUpDouble from '@SMART/assets/icons/40x40/arrow-up-double-filled.svg';
import IconArrowUp from '@SMART/assets/icons/40x40/arrow-up-filled.svg';
import IconBackspace from '@SMART/assets/icons/40x40/backspace.svg';
import { FocusKeys } from '@SMART/index';
import { computed } from '@vue/composition-api';

export const CONTROL_KEYS = {
  clear: 'clear',
  backspace: 'backspace',
  shift: 'shift',
  left: 'left',
  right: 'right',
  engLang: 'eng',
  rusLang: 'rus',
  symbLang: 'symb',
};

export const KEYBOARD_TYPES = {
  eng: 'eng',
  rus: 'rus',
  symb: 'symb',
};

// SearchKeyboard
export const getSearchKeyboard = ({ input, keyboardRef, onClear }) => {
  return [
    {
      type: KEYBOARD_TYPES.rus,
      keys: [
        [...'абвгдежзийк'.split('')],
        [...'лмнопрстуфх'.split('')],
        [...'цчшщъыьэюя+'.split('')],
        [...'1234567890№'.split('')],
        [
          {
            key: CONTROL_KEYS.engLang,
            control: true,
            label: translate('keyboard.eng'),
            span: 2,
            focusKey: FocusKeys.LANG,
            callback: () => {
              keyboardRef.value.changeType(KEYBOARD_TYPES.eng);
            },
          },
          { key: ' ', label: translate('keyboard.space'), span: 4 },
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            span: 2,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            label: translate('keyboard.clear'),
            span: 3,
            callback: () => {
              onClear(input.value);
              input.value = '';
            },
          },
        ],
      ],
    },
    {
      type: KEYBOARD_TYPES.eng,
      keys: [
        [...'abcdefghijk'.split('')],
        [...'lmnopqrstuv'.split('')],
        [...'wxyz$:%&-=+'.split('')],
        [...'1234567890№'.split('')],
        [
          {
            key: 'rus',
            control: true,
            label: translate('keyboard.rus'),
            span: 2,
            focusKey: FocusKeys.LANG,
            callback: () => {
              keyboardRef.value.changeType(KEYBOARD_TYPES.rus);
            },
          },
          { key: ' ', label: translate('keyboard.space'), span: 4 },
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            span: 2,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            label: translate('keyboard.clear'),
            span: 3,
            callback: () => {
              input.value = '';
            },
          },
        ],
      ],
    },
  ];
};

// LoginKeyboard
export const getLoginKeyboard = ({ input, keyboardRef, shiftState, clearCallback }) => {
  const emailBlockStyle = {
    'margin-left': adjustPx(8) + 'px',
  };
  const setUpperCase = (value) => {
    keyboardRef.value.setUpperCase(value);
  };

  const isReleaseMode = environmentService.getVariable('isRelease');

  return [
    {
      type: KEYBOARD_TYPES.eng,
      keys: [
        [
          ...'0987654321'.split('').map((k) => ({ key: k, style: { 'background-color': 'rgba(54, 60, 61, 1)' } })),
          { key: '@', style: emailBlockStyle },
          { key: isReleaseMode ? '@mail.ru' : '@test.ru', label: isReleaseMode ? '@mail.ru' : '@test.ru', span: 2 },
        ],
        [...'qwertyuiop'.split(''), { key: '@yandex.ru', label: '@yandex.ru', span: 3, style: emailBlockStyle }],
        [...'asdfghjkl_'.split(''), { key: '@gmail.com', label: '@gmail.com', span: 3, style: emailBlockStyle }],
        [
          {
            key: CONTROL_KEYS.shift,
            control: true,
            icon: computed(() => {
              return shiftState.value === 'caps' ? IconArrowUpDouble : IconArrowUp;
            }),
            span: 2,
            callback: () => {
              switch (shiftState.value) {
                case 'off':
                  shiftState.value = 'active';
                  setUpperCase(true);
                  break;
                case 'active':
                  shiftState.value = 'caps';
                  setUpperCase(true);
                  break;
                case 'caps':
                  shiftState.value = 'off';
                  setUpperCase(false);
                  break;
              }
            },
          },
          ...'zxcvbnm.'.split(''),
          { key: '.ru', label: '.ru', span: 3, style: emailBlockStyle },
        ],
        [
          {
            key: CONTROL_KEYS.symbLang,
            control: true,
            label: '!#$',
            span: 4,
            focusKey: FocusKeys.LANG,
            callback: () => {
              keyboardRef.value.changeType(KEYBOARD_TYPES.symb);
            },
          },
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            span: 3,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            label: translate('keyboard.clear'),
            span: 3,
            callback: () => {
              input.value = '';
              clearCallback && clearCallback();
            },
          },
          { key: '.com', label: '.com', span: 3, style: emailBlockStyle },
        ],
      ],
    },
    {
      type: KEYBOARD_TYPES.symb,
      keys: [
        [
          ...'0987654321'.split('').map((k) => ({ key: k, style: { 'background-color': 'rgba(54, 60, 61, 1)' } })),
          { key: '@', style: emailBlockStyle },
          { key: isReleaseMode ? '@mail.ru' : '@test.ru', label: isReleaseMode ? '@mail.ru' : '@test.ru', span: 2 },
        ],
        [...'`~!#@$%+&*'.split(''), { key: '@yandex.ru', label: '@yandex.ru', span: 3, style: emailBlockStyle }],
        [...'()[]{}\\/-_'.split(''), { key: '@gmail.com', label: '@gmail.com', span: 3, style: emailBlockStyle }],
        [...':\'";<>?=,.'.split(''), { key: '.ru', label: '.ru', span: 3, style: emailBlockStyle }],
        [
          {
            key: CONTROL_KEYS.engLang,
            control: true,
            label: 'abc',
            span: 4,
            focusKey: FocusKeys.LANG,
            callback: () => {
              keyboardRef.value.changeType(KEYBOARD_TYPES.eng);
            },
          },
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            span: 3,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            label: translate('keyboard.clear'),
            span: 3,
            callback: () => {
              input.value = '';
              clearCallback && clearCallback();
            },
          },
          { key: '.com', label: '.com', span: 3, style: emailBlockStyle },
        ],
      ],
    },
  ];
};

// PasswordKeyboard
export const getPasswordKeyboard = ({ input, keyboardRef, shiftState }) => {
  const setUpperCase = (value) => {
    keyboardRef.value.setUpperCase(value);
  };

  return [
    {
      type: KEYBOARD_TYPES.eng,
      keys: [
        [...'0987654321'.split('').map((k) => ({ key: k, style: { 'background-color': 'rgba(54, 60, 61, 1)' } }))],
        [...'qwertyuiop'.split('')],
        [...'asdfghjkl_'.split('')],
        [
          {
            key: CONTROL_KEYS.shift,
            control: true,
            icon: computed(() => {
              return shiftState.value === 'caps' ? IconArrowUpDouble : IconArrowUp;
            }),
            span: 2,
            callback: () => {
              switch (shiftState.value) {
                case 'off':
                  shiftState.value = 'active';
                  setUpperCase(true);
                  break;
                case 'active':
                  shiftState.value = 'caps';
                  setUpperCase(true);
                  break;
                case 'caps':
                  shiftState.value = 'off';
                  setUpperCase(false);
                  break;
              }
            },
          },
          ...'zxcvbnm.'.split(''),
        ],
        [
          {
            key: CONTROL_KEYS.symbLang,
            control: true,
            label: '!#$',
            span: 4,
            focusKey: FocusKeys.LANG,
            callback: () => {
              keyboardRef.value.changeType(KEYBOARD_TYPES.symb);
            },
          },
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            span: 3,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            focusKey: FocusKeys.CLEAR,
            label: translate('keyboard.clear'),
            span: 3,
            callback: () => {
              input.value = '';
            },
          },
        ],
      ],
    },
    {
      type: KEYBOARD_TYPES.symb,
      keys: [
        [...'0987654321'.split('').map((k) => ({ key: k, style: { 'background-color': 'rgba(54, 60, 61, 1)' } }))],
        [...'`~!#@$%+&*'.split('')],
        [...'()[]{}\\/-_'.split('')],
        [...':\'";<>?=,.'.split('')],
        [
          {
            key: CONTROL_KEYS.engLang,
            control: true,
            label: 'abc',
            span: 4,
            focusKey: FocusKeys.LANG,
            callback: () => {
              keyboardRef.value.changeType(KEYBOARD_TYPES.eng);
            },
          },
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            span: 3,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            focusKey: FocusKeys.CLEAR,
            label: translate('keyboard.clear'),
            span: 3,
            callback: () => {
              input.value = '';
            },
          },
        ],
      ],
    },
  ];
};

// NumberKeyboard
export const getNumberKeyboard = ({ input }) => {
  return [
    {
      type: KEYBOARD_TYPES.eng,
      keys: [
        [...'123'.split('')],
        [...'456'.split('')],
        [...'789'.split('')],
        [
          {
            key: CONTROL_KEYS.backspace,
            control: true,
            icon: IconBackspace,
            callback: () => {
              input.value = input.value.slice(0, -1);
            },
          },
          {
            key: '0',
          },
          {
            key: CONTROL_KEYS.clear,
            control: true,
            label: translate('keyboard.clear'),
            callback: () => {
              input.value = '';
            },
          },
        ],
      ],
    },
  ];
};
