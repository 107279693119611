<template>
  <app-sdk-button
    :is-focused="isFocused"
    :text="text"
    platform="legacy-smart-tv"
    :variation="variation"
    :text-class-name="textClassName"
    transition-duration="200ms"
  >
    <template v-slot:icon>
      <slot name="icon" />
    </template>
    <template #icon-right>
      <slot name="icon-right" />
    </template>
  </app-sdk-button>
</template>

<script>
import AppSdkButton from '@package/ui/src/components/AppButton.vue';

export default {
  components: {
    AppSdkButton,
  },
  props: {
    isFocused: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    textClassName: {
      type: String,
      default: '',
    },
    variation: {
      type: String,
      default: 'smart-button-primary',
    },
  },
};
</script>
