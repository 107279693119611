<template>
  <div ref="el" :class="$style.wrapper">
    <div :class="$style.controls">
      <NavigatableItem
        :class="$style.control"
        :active-class="$style.active"
        variation="default"
        autofocus
        :tag="UIButton"
        :on-click="changeVideoPlayState"
      >
        <IconPause v-if="isPlaying" :class="$style.icon" />
        <IconPlay v-else :class="$style.icon" />
      </NavigatableItem>

      <LinkedKinom
        v-if="isLinkedKinomShown"
        :kinom="linkedKinom"
        :active-episode-index="currentEpisodeIndex"
        :active-season-index="currentSeasonIndex"
        @seek="onSeekToLinked"
      />

      <NavigatableItem
        v-if="isDvr"
        :class="[$style.control, $style.controlLive]"
        :active-class="$style.active"
        variation="default"
        :tag="UIButton"
        :on-click="onSeekToLive"
      >
        <IconLiveOn v-if="isCurrentTimeLive" :class="$style.icon" />
        <IconLiveOff v-else :class="$style.icon" />

        <span :class="$style.controlLiveText">
          {{ liveText }}
        </span>
      </NavigatableItem>

      <div :class="$style.controlsList">
        <NavigatableItem
          v-if="isSerial && nextAvailableEpisode"
          :class="$style.control"
          :active-class="$style.active"
          :tag="UIButton"
          :on-click="handlePlayNextAvailableEpisode"
          @focus="setNextEpisodePreviewShown(true)"
          @blur="setNextEpisodePreviewShown(false)"
        >
          <IconAudioNext :class="$style.icon" />

          <NextEpisodePreviewElement
            v-if="isNextEpisodePreviewShown && nextEpisode && currentSeason && !isCurrentEpisodeLastAvailable"
            :episode="nextEpisode"
            :season="currentSeason"
            :seasons="media && media.seasons"
            :request-next-episode="handlePlayNextAvailableEpisode"
            @blur="isNextEpisodePreviewShown = false"
          />
          <span :class="$style.controlText">Следующая</span>
        </NavigatableItem>

        <NavigatableItem
          v-if="isSerial"
          :class="$style.control"
          :active-class="$style.active"
          :tag="UIButton"
          :on-click="handleShowSeriesMenu"
        >
          <IconEpisodes :class="$style.icon" />

          <span :class="$style.controlText">Серии</span>
        </NavigatableItem>
      </div>

      <VideoTimelineNextContent
        v-if="isTimestampActive && !isUserForcedWatchTitles && nextEpisode"
        :class="$style.nextContent"
        :preview-src="nextEpisode.preview"
        :on-request-episode="handlePlayNextAvailableEpisode"
        :on-watch-titles="() => (isUserForcedWatchTitles = true)"
        :episode-title="nextEpisode.title"
      />
    </div>
  </div>
</template>

<script>
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import useSeriesContentHelpers from '@package/content-utils/src/code/use-series-content-helpers';
import { MediaContentType } from '@package/sdk/src/api';
import { indexOutOfRange } from '@package/sdk/src/core';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import IconAudioNext from '@SMART/assets/icons/51x51/audio-next.svg';
import IconEpisodes from '@SMART/assets/icons/51x51/episodes.svg';
import IconPause from '@SMART/assets/icons/51x51/pause.svg';
import IconPlay from '@SMART/assets/icons/51x51/play.svg';
import { computed, defineComponent, inject, ref } from '@vue/composition-api';

import IconLiveOff from '@/assets/icons/live-off.svg';
import IconLiveOn from '@/assets/icons/live-on.svg';
import UIButton from '@/components/button/UIButton.vue';

import VideoTimelineNextContent from '../timeline/VideoTimelineNextContent.vue';
import LinkedKinom from './LinkedKinom.vue';
import NextEpisodePreviewElement from './NextEpisodePreviewElement.vue';

export default defineComponent({
  components: {
    IconPlay,
    IconPause,
    IconAudioNext,
    IconEpisodes,
    IconLiveOn,
    IconLiveOff,
    LinkedKinom,
    NextEpisodePreviewElement,
    VideoTimelineNextContent,
  },
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    linkedKinom: {
      type: Object,
      default: () => ({}),
    },
    isLinkedKinomShown: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isDvr: {
      type: Boolean,
      default: false,
    },
    isCurrentTimeLive: {
      type: Boolean,
      default: false,
    },
    onSeekToLive: {
      type: Function,
      default: () => {},
    },
    isTimestampActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  setup(props, { root: { $route: route }, emit }) {
    const isNextEpisodePreviewShown = ref(false);
    const isSerialContent = computed(() =>
      [MediaContentType.Serial, MediaContentType.Season, MediaContentType.Episode].includes(props.media?.contentType),
    );

    const { getEpisodeDetails } = useSeriesContentHelpers();

    const { episodeId } = route.query;

    const isLinkedKinomTargetContent = computed(() => {
      if (!isSerialContent.value) {
        return true;
      }

      return (
        props.media?.watchingItem?.episodeNumber === props.linkedKinom.value?.episodeNumber &&
        props.media?.watchingItem?.seasonNumber === props.linkedKinom.value?.seasonNumber
      );
    });

    const episodeDetail = computed(() => {
      return getEpisodeDetails(episodeId, props.media?.seasons);
    });
    const currentSeason = computed(() => episodeDetail.value?.season);
    const currentEpisode = computed(() => episodeDetail.value?.episode);
    const currentEpisodeIndex = computed(() => episodeDetail.value?.episodeNumber);
    const currentSeasonIndex = computed(() => episodeDetail.value?.seasonNumber);

    const nextEpisode = computed(() => {
      if (
        !isSerialContent.value ||
        !currentSeason.value ||
        currentEpisodeIndex.value === null ||
        currentSeasonIndex.value === null
      ) {
        return null;
      }

      const seasons = props.media?.seasons;

      if (currentSeason.value?.episodes[currentEpisodeIndex.value + 1]) {
        return currentSeason.value?.episodes[currentEpisodeIndex.value + 1];
      }

      if (seasons[currentSeasonIndex.value + 1]?.episodes[0]) {
        return currentSeason.value?.episodes[currentEpisodeIndex.value + 1];
      }
      return null;
    });

    const isCurrentEpisodeLastAvailable = computed(() => {
      return false;
    });

    const setNextEpisodePreviewShown = (state) => {
      window.setTimeout(() => {
        if (!state && SpatialNavigation?.getCurrentFocusKey() === 'NEXT_EPISODE_PREVIEW') {
          return;
        }
        isNextEpisodePreviewShown.value = state;
      }, 100);
    };

    const onSeekToLinked = () => {
      if (isLinkedKinomTargetContent.value) {
        const episodeId =
          props.media.seasons?.[props.linkedKinom.seasonNumber - 1]?.episodes[props.linkedKinom.episodeNumber - 1]?.id;
        if (!episodeId) {
          return emit('seek');
        }

        const episodeIndex = Number(currentEpisodeIndex.value || 0);
        const kinomEpisodeIndex = Number(props.linkedKinom?.episodeNumber || 0);
        const seasonIndex = Number(currentSeasonIndex.value || 0);
        const kinomSeasonIndex = Number(props.linkedKinom?.seasonNumber || 0);

        if (kinomSeasonIndex === seasonIndex && kinomEpisodeIndex === episodeIndex) {
          return emit('seek');
        }

        const event = {
          id: episodeId,
          type: MediaContentType.EPISODE,
          serial: {
            season: props.linkedKinom.seasonNumber || 0,
            episode: props.linkedKinom.episodeNumber || 0,
          },
          startOffset: props.linkedKinom.startOffset,
        };

        return emit('seek', event);
      }
      emit('seek');
    };

    const isUserForcedWatchTitles = ref(false);

    return {
      UIButton,
      isSerialContent,
      isCurrentEpisodeLastAvailable,
      currentSeason,
      currentEpisode,
      nextEpisode,
      isNextEpisodePreviewShown,
      setNextEpisodePreviewShown,
      onSeekToLinked,
      isUserForcedWatchTitles,
      currentEpisodeIndex,
      currentSeasonIndex,
    };
  },
  computed: {
    isSerial: function () {
      return this.media?.contentType === MediaContentType.Serial;
    },
    liveText: function () {
      return this.isCurrentTimeLive ? 'Прямой эфир' : 'Вернуться в эфир';
    },
    nextAvailableEpisode: function () {
      const route = this.$route;
      const seasons = this.media.seasons;
      const episodeId = route.query.episodeId;
      let currentSeasonNumber = 1;
      let currentEpisodeNumber = 1;

      for (let i = 0; i < seasons.length; i++) {
        const episodeIndex = seasons[i].episodes.find((episode) => episode.id === episodeId)?.number - 1;
        if (episodeIndex >= 0) {
          currentSeasonNumber = seasons[i].number || i + 1;
          currentEpisodeNumber = episodeIndex + 1;
          break;
        }
      }

      return this.getNextAvailableEpisode({ seasons, currentSeasonNumber, currentEpisodeNumber });
    },
  },
  methods: {
    handleShowSeriesMenu: function () {
      this.$emit('showSeriesMenu');
    },
    handlePlayNextAvailableEpisode: function () {
      const next = this.nextAvailableEpisode;

      if (!next) {
        return;
      }

      this.$emit('playNextAvailableEpisode', { id: next.episode.id, type: next.episode?.contentType });
    },
    changeVideoPlayState: function () {
      this.$emit(this.isPlaying ? 'pause' : 'play');
    },
    getNextAvailableEpisode: function (options) {
      const { isAvailable } = useMediaContentAvailability();
      const { currentSeasonNumber, currentEpisodeNumber, seasons } = options;

      const startSeasonIndex = seasons?.findIndex((season) => currentSeasonNumber === season.number);

      const startEpisodeIndex = seasons[startSeasonIndex]?.episodes.findIndex(
        (episode) => currentEpisodeNumber === episode.number,
      );

      if (indexOutOfRange(startSeasonIndex) || indexOutOfRange(startEpisodeIndex)) {
        return null;
      }

      let episodeIndex = startEpisodeIndex + 1;

      function getNextAvailable(startIndex, endIndex) {
        for (let i = startIndex; i < endIndex; i++) {
          for (let j = episodeIndex; j < seasons[i].episodes.length; j++) {
            if (j === startEpisodeIndex && i === startSeasonIndex) {
              return;
            }

            const currentSeason = seasons[i];
            const currentEpisode = currentSeason.episodes[j];

            const matchedEpisode = isAvailable(currentEpisode);

            if (matchedEpisode) {
              const episode = seasons[i].episodes[j];
              const season = seasons[i];

              return {
                episode,
                season,
              };
            }
          }

          episodeIndex = 0;
        }

        if (startIndex !== 0) {
          return getNextAvailable(0, startSeasonIndex);
        }
      }

      return getNextAvailable(startSeasonIndex, seasons.length);
    },
  },
});
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/fonts';

.wrapper {
  display: flex;
  padding-top: adjustPx(16px);
}

.controls {
  display: flex;

  &List {
    display: flex;
    margin-left: adjustPx(40px);

    .control:not(:first-child) {
      margin-left: adjustPx(16px);
    }
  }
}

.control {
  display: flex;
  align-items: center;
  padding: adjustPx(26px);
  border: none;
  border-radius: adjustPx(18px);
  outline: none;
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);

  @include f-label-2;

  &Text {
    margin-left: adjustPx(12px);
  }

  &Live {
    margin-left: adjustPx(16px);

    &Text {
      margin-left: adjustPx(12px);
    }
  }
}

.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);
}

.icon {
  width: adjustPx(48px);
  height: adjustPx(48px);
}

.nextContent {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  margin-right: var(--g-spacing-20);
}
</style>
