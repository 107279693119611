export enum Order {
  KinopoiskRating = 'kinopoisk_rating',
  ImdbRating = 'imdb_rating',
  CreatedAt = 'created_at',
}

/**
 * @description
 * Нужно год выпуска контента, превращать в фильтр для каталога (который он сможет применить.
 *
 * @example
 *  1994 -> 1990
 *  2008 -> 2000
 *  1939 -> 1930
 *
 * @param {number} year
 * @return {number}
 */
export function getMostSuitablePeriodSlugFilter(year: number) {
  const yearString = String(year);

  return Number(yearString.slice(0, -1) + 0);
}

export const VISIBLE_LABELS_NUMBER = 4;
