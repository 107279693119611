import { isDefined } from '@package/sdk/src/core';

type FocusKeyIndexType = string | number;

const resolveIndex = (index?: FocusKeyIndexType) => {
  return isDefined(index) ? `:${index}` : '';
};

export const FocusKeys = {
  CATALOG_PAGE: 'CATALOG_PAGE',
  CHANNELS_PAGE: 'CHANNELS_PAGE',
  MY_COLLECTION_PAGE: 'MY_COLLECTION_PAGE',
  MAIN_PAGE: 'MAIN_PAGE',
  MY_CHANNEL_PAGE: 'MY_CHANNEL_PAGE',
  MOMENTS_PAGE: 'MOMENTS_PAGE',
  OFFERS_PAGE: 'OFFERS_PAGE',
  OFFERS_SPECIAL_PAGE: 'OFFERS_SPECIAL_PAGE',
  PARENTAL_PAGE: 'PARENTAL_PAGE',
  PARENTAL_CODE_PAGE: 'PARENTAL_CODE_PAGE',
  SEARCH_PAGE: 'SEARCH_PAGE',
  SETTINGS_PAGE: 'SETTINGS_PAGE',
  SETTINGS_SUBSCRIPTION_PAGE: 'SETTINGS_SUBSCRIPTION_PAGE',
  MEDIA_CARD_PAGE: 'MEDIA_CARD_PAGE',
  PROFILES_PAGE: 'PROFILES_PAGE',

  // Components
  APP_MENU: 'APP_MENU',
  UI_CONTENT: 'UI_CONTENT',
  VIRTUAL_KEYBOARD: 'VIRTUAL_KEYBOARD',
  AUTH_CONTAINER: 'AUTH_CONTAINER',
  AUTH_QR_LOGIN_MANUALLY: 'AUTH_QR_LOGIN_MANUALLY',
  AUTH_PRIVACY_POLICY: 'AUTH_PRIVACY_POLICY',
  AUTH_USER_AGREEMENT: 'AUTH_USER_AGREEMENT',
  AUTH_MAILING_RULES: 'AUTH_MAILING_RULES',
  QR_LOGIN: 'QR_LOGIN',
  CONTENT_SELECT: 'CONTENT_SELECT',
  SETTINGS_PAGE_NAVIGATION: 'SETTINGS_PAGE_NAVIGATION',

  MEDIA_CARD_SEASONS: 'MEDIA_CARD_SEASONS',
  MEDIA_CARD_EPISODES_SLIDER: 'MEDIA_CARD_EPISODES_SLIDER',
  MEDIA_CARD_DETAILS: 'MEDIA_CARD_DETAILS',
  MEDIA_CARD_PAGE_SEASONS_SLIDER: 'MEDIA_CARD_PAGE_SEASONS_SLIDER',
  MEDIA_CARD_COLLECTIONS_SLIDER: 'MEDIA_CARD_COLLECTIONS_SLIDER',
  MEDIA_CARD_COLLECTION_LIST_SLIDER: 'MEDIA_CARD_COLLECTION_LIST_SLIDER',

  // Modals
  FULL_SCREEN_MODAL: 'FULL_SCREEN_MODAL',
  MY_CHANNEL_MODAL: 'MY_CHANNEL_MODAL',
  FILTERS_MODAL: 'FILTERS_MODAL',
  SHUFFLE_MODAL: 'SHUFFLE_MODAL',
  CHANNELS_MODAL: 'CHANNELS_MODAL',
  PLAYLIST_DROPDOWN: 'PLAYLIST_DROPDOWN',
  MOMENTS_DROPDOWN: 'MOMENTS_DROPDOWN',
  MOMENTS_DROPDOWN_ITEM: 'MOMENTS_DROPDOWN_ITEM',
  PROMO_SUB_MODAL: 'PROMO_SUB_MODAL',
  PLAYER_SERIES_MENU: 'PLAYER_SERIES_MENU',
  PLAYER_SERIES_MENU_SEASONS_LIST: 'PLAYER_SERIES_MENU_SEASONS_LIST',
  PLAYER_SERIES_MENU_EPISODES_LIST: 'PLAYER_SERIES_MENU_EPISODES_LIST',
  CANCEL_SUBSCRIPTION_MODAL: 'CANCEL_SUBSCRIPTION_MODAL',

  // Buttons
  OK_BUTTON: 'OK_BUTTON',
  BACK_OK_BUTTON: 'BACK_OK_BUTTON',
  SAVE_BUTTON: 'SAVE_BUTTON',
  PLAY_BUTTON: 'PLAY_BUTTON',
  CONTINUE_BUTTON: 'CONTINUE_BUTTON',
  FORGET_BUTTON: 'FORGET_BUTTON',
  BOOKMARK_BUTTON: 'BOOKMARK_BUTTON',
  PROMO_BUTTON: 'PROMO_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  BACK_BUTTON_MODAL: 'BACK_BUTTON_MODAL',

  CHANNELS_MODAL_CLOSE: 'CHANNELS_MODAL_CLOSE',
  CHANNELS_CONTROL_OPEN: 'CHANNELS_CONTROL_OPEN',
  CATALOG_SHUFFLE_BUTTON: 'CATALOG_SHUFFLE_BUTTON',
  CATALOG_FILTERS_BUTTON: 'CATALOG_FILTERS_BUTTON',

  SETTINGS_SUBSCRIBE_TAB: 'SETTINGS_SUBSCRIBE_TAB',
  SETTINGS_PERSONAL_DATA_TAB: 'SETTINGS_PERSONAL_DATA_TAB',
  SETTINGS_CONTACTS_TAB: 'SETTINGS_CONTACTS_TAB',
  SETTINGS_SUBSCRIPTION_BUTTON: 'SETTINGS_SUBSCRIPTION_BUTTON',

  PLAY_CONTENT_BUTTON: 'PLAY_CONTENT_BUTTON',
  UNAVAILABLE_CONTENT_MODAL_PLAY_BUTTON: 'UNAVAILABLE_CONTENT_MODAL_PLAY_BUTTON',

  CATALOG_RESET_FILTERS_EMPTY_CONTENT: 'CATALOG_RESET_FILTERS_EMPTY_CONTENT',
  SELECT_MEDIA_BUTTON: 'SELECT_MEDIA_BUTTON',

  PLAYER_ROOT: 'PLAYER_ROOT',
  PLAYER_TIMELINE: 'PLAYER_TIMELINE',

  MEDIA_CARD_TO_TOP_BUTTON: 'MEDIA_CARD_TO_TOP_BUTTON',
  MEDIA_CARD_PLAY_BUTTON: 'MEDIA_CARD_PLAY_BUTTON',
  MY_COLLECTION_OPEN_CATALOG: 'MY_COLLECTION_OPEN_CATALOG',
  MY_CHANNEL_NOTIFICATIONS_ACTIONS: 'MY_CHANNEL_NOTIFICATIONS_ACTIONS',

  OFFERS_SPECIAL_CONTINUE: 'OFFERS_SPECIAL_CONTINUE',

  // Keyboard keys
  LANG: 'LANG',
  CLEAR: 'CLEAR',
  VOICE_SEARCH: 'VOICE_SEARCH',

  // Legacy only keys
  VIDEO_TIMELINE: 'VIDEO_TIMELINE',
  VIDEO_TIMELINE_HANDLE: 'VIDEO_TIMELINE_HANDLE',
  VIDEO_TIMELINE_NEXT_CONTENT_BUTTON: 'VIDEO_TIMELINE_NEXT_CONTENT_BUTTON',

  // Dynamic keys
  PROFILES_BUTTON: (type: FocusKeyIndexType) => `PROFILES_BUTTON_${type}`,
  KEYBOARD_KEY: (key: FocusKeyIndexType) => `KEYBOARD_KEY_${key}`,

  // Indexed keys
  MY_CHANNEL_BUTTON: (index: FocusKeyIndexType) => `MY_CHANNEL_BUTTON${resolveIndex(index)}`,
  MY_COLLECTION_TAB: (index: FocusKeyIndexType) => `MY_COLLECTION_TAB${resolveIndex(index)}`,
  MY_COLLECTION_ITEM: (index: FocusKeyIndexType) => `MY_COLLECTION_ITEM${resolveIndex(index)}`,
  CHANNEL: (index: FocusKeyIndexType) => `CHANNEL_ITEM${resolveIndex(index)}`,
  PLAYER_SEASONS_ITEM: (index: FocusKeyIndexType) => `PLAYER_SEASONS_ITEM${resolveIndex(index)}`,
  PLAYER_EPISODES_ITEM: (index: FocusKeyIndexType) => `PLAYER_EPISODES_ITEM${resolveIndex(index)}`,
  PLAYER_SERIES_ITEM_PLAY_BUTTON: (index: FocusKeyIndexType) => `PLAYER_SERIES_ITEM_PLAY_BUTTON${resolveIndex(index)}`,
  CATALOG_FILTERS_SLIDER_ITEM: (index?: FocusKeyIndexType) => `CATALOG_FILTERS_SLIDER_ITEM${resolveIndex(index)}`,
  CATALOG_FILTERS_CATEGORY_ITEM: (category: string, index?: FocusKeyIndexType) =>
    `CATALOG_FILTERS_${category.toUpperCase()}_ITEM${resolveIndex(index)}`,
  MEDIA_CARD_SLIDER: (type?: FocusKeyIndexType) => `MEDIA_CARD_SLIDER${resolveIndex(type)}`,
  MEDIA_CARD_SLIDER_ITEM: (type?: FocusKeyIndexType, index?: FocusKeyIndexType) =>
    `MEDIA_CARD_SLIDER_ITEM${resolveIndex(type)}${resolveIndex(index)}`,
  MEDIA_CARD_SEASONS_ITEM: (index: FocusKeyIndexType) => `MEDIA_CARD_SEASONS_ITEM${resolveIndex(index)}`,
  MEDIA_CARD_EPISODES_ITEM: (index: FocusKeyIndexType) => `MEDIA_CARD_EPISODES_ITEM${resolveIndex(index)}`,

  MEDIA_CARD_METADATA_SECTION: (type: FocusKeyIndexType) => `MEDIA_CARD_METADATA_SECTION${resolveIndex(type)}`,

  PLAYLIST_SLIDER: (row?: FocusKeyIndexType) => `PLAYLIST_SLIDER${resolveIndex(row)}`,
  PLAYLIST_ITEM: (row?: FocusKeyIndexType, column?: FocusKeyIndexType) =>
    `PLAYLIST_ITEM${resolveIndex(row)}${resolveIndex(column)}`,

  OFFERS_ITEM: (index: FocusKeyIndexType) => `OFFERS_ITEM${resolveIndex(index)}`,
};
