<template>
  <div :class="$style.loader">
    <app-loader-icon />
  </div>
</template>

<script>
import AppLoaderIcon from './AppLoaderIcon.vue';

export default {
  components: {
    AppLoaderIcon,
  },
};
</script>

<style lang="scss" module>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
}
</style>
