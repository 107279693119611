var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"el",class:_vm.$style.content},[_vm._t("catalog-shuffle"),(_vm.isContentEmpty)?_c('div',{class:_vm.$style.emptyHeader},[_vm._t("not-found",function(){return [_c('UITypography',{class:_vm.$style.contentEmptyHeader,attrs:{"shimmer-variant":"subtitle"}},[_vm._v(" "+_vm._s(_vm.emptyHeader)+" ")])]})],2):_vm._e(),(_vm.showShimmer)?_c('ScrollViewport',{ref:"scroll1",attrs:{"tag":"ul","orientation":"vertical","y":_vm.offset,"role":"list"}},[_c('ui-content-row',{class:_vm.$style.row,attrs:{"items":[1, 2, 3]}},_vm._l((5),function(rowItem){
var _obj;
return _c('UIPoster',{key:rowItem,class:( _obj = {}, _obj[_vm.$style.contentCell] = true, _obj.ceil = true, _obj[_vm.$style.shimmer] = true, _obj ),attrs:{"tabindex":"0","is-loading":true}})}),1)],1):_vm._e(),_c('ScrollViewport',{directives:[{name:"show",rawName:"v-show",value:(_vm.normalizedItems.length >= _vm.items.length),expression:"normalizedItems.length >= items.length"}],ref:"scroll",class:_vm.$style.scroll,attrs:{"tag":"ul","orientation":"vertical","y":_vm.offset,"role":"list"}},[_c('ui-content-row',{ref:"momentElements",class:_vm.$style.row,attrs:{"items":_vm.normalizedItems},on:{"vue:mounted":_vm.onVNodeMounted},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _vm._l((item.row),function(rowItem){
var _obj;
return _c('UIPoster',{key:rowItem.id + _vm.contentAssemblyId,class:( _obj = {}, _obj[_vm.$style.contentCell] = true, _obj.ceil = true, _obj ),attrs:{"data-key":rowItem.id + _vm.contentAssemblyId,"tabindex":"0","scroll-block":_vm.scrollBlock,"src":_vm.contentType === _vm.CollectionContentType.ContentMoment ? rowItem.preview : rowItem.poster,"title":rowItem.title,"size":_vm.posterSize},on:{"active":function($event){return _vm.onActive(rowItem, item.id, index, $event)},"vue:activated":_vm.onPosterActivated,"clicked":function($event){return _vm.onSelect(rowItem, item.id)}}})})}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }