// @ts-nocheck

import 'whatwg-fetch';
import 'core-js/es/object/get-own-property-descriptors';
import 'core-js/es/object/assign';
import 'core-js/es/set';
import 'core-js/es/weak-map';
import 'core-js/es/promise';
import 'unfetch/polyfill';
import 'intersection-observer';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import '@package/ui/src/styles/style.css';
import './styles/app.scss';
import '@package/ui/src/styles/custom-normalize.scss';
import 'normalize.css';
import 'requestidlecallback-polyfill';
import '../../../node_modules/fast-text-encoding/text.min.js';

import useLogger from '@package/logger/src/use-logger';
import SmartTvCriticalErrorView from '@package/smarttv-base/src/components/errors/SmartTvCriticalErrorView.vue';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import {
  alertService,
  analyticService,
  authService,
  customEventsService,
  deviceService,
  environmentService,
  globalSettings,
  i18n,
  initStore,
  OperationSystem,
  requestService,
  RouterPage,
  routerService,
  useContentStore,
  useSessionStore,
} from '@SMART/index';
import VueCompositionApi, {
  computed,
  inject,
  nextTick,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import Axios, { isCancel } from 'axios';
import cryptoJs from 'crypto-js';
import * as dateFns from 'date-fns';
import { ru } from 'date-fns/locale';
import JwtDecode from 'jwt-decode';
import PortalVue from 'portal-vue';
import * as QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import VueRouter, { RouterLink } from 'vue-router';
import Vuex from 'vuex';

import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import App from './App.vue';
import routes from './pages/routes';

const logger = useLogger('main.ts');

const createAppView = (store, router) => {
  return new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

const LOG_LEVEL = 0;

const setupLoggers = () => {
  logger.level = LOG_LEVEL;
};

const showAppVersion = () => {
  console.info(
    '%c Viju smarttv version: ' + '%c '.concat(environmentService.getVariable<() => string>('appVersion')() || '', ' '),
    'color: #fff; background: #0A1E1F',
    'color: #fff; background: #cc6666',
  );
};

const setupWebWorker = () => {
  //
};

let isInitialized = false;
let runningTimeoutId: number;

async function main() {
  if (isInitialized) {
    return;
  }

  const isDev = process.env.NODE_ENV === 'development';

  environmentService.init({
    isDev,
    webAppURL: process.env.WEB_APP_URL || 'https://viju.ru/',
    dsmlPartnerId: process.env.DSML_PARTNER_ID,
    dsmlPassword: process.env.DSML_PASSWORD,
    apiBaseURL: process.env.API_BASE_URL || 'https://api.stg.testvip.ru/',
    isRelease: process.env.IS_RELEASE_MODE === 'true',
    isStrictMode: process.env.IS_RELEASE_MODE !== 'true',
    apiFlagsBaseURL: process.env.API_FLAGS_BASE_URL || 'https://flags.viju.ru/',
  });

  dateFns.setDefaultOptions({ locale: ru });

  Vue.use(PortalVue);

  globalSettings.axios = Axios;
  globalSettings.axiosIsCancel = isCancel;
  globalSettings.vueVersion = 'vue2';
  globalSettings.computed = computed;
  globalSettings.onBeforeUnmount = onBeforeUnmount;
  globalSettings.ref = ref;
  globalSettings.onMounted = onMounted;
  globalSettings.vueCompositionApi = VueCompositionApi;
  globalSettings.vue = Vue;
  globalSettings.vuex = Vuex;
  globalSettings.router = VueRouter;
  globalSettings.routes = routes;
  globalSettings.nextTick = nextTick;
  globalSettings.jwt = JwtDecode;
  globalSettings.dateFns = dateFns;
  globalSettings.qrcode = QRCode;
  globalSettings.uuidv4 = uuidv4;
  globalSettings.cryptoJs = cryptoJs;
  globalSettings.inject = inject;
  globalSettings.onUnmounted = onUnmounted;
  globalSettings.onActivated = onActivated;
  globalSettings.onDeactivated = onDeactivated;

  Vue.use(VueCompositionApi);
  Vue.use(VueRouter);

  Vue.prototype.$RouterPage = RouterPage;

  Vue.component('NavigatableItem', NavigatableItem);
  Vue.component('RouterLink', RouterLink);

  const store = initStore();

  setupWebWorker();
  setupLoggers();
  showAppVersion();

  requestService.init();
  routerService.initialize();
  alertService.initialize();
  customEventsService.init();
  // Инициализируем системные API
  await deviceService.init();
  // активируем хуки авторизации / род. контроля.
  authService.init();
  Vue.use(i18n);

  try {
    await analyticService.init();
  } catch (e) {
    console.error(e);
  }

  const visualDebug = process.env.IS_VISUAL_DEBUG_ENABLED === 'true';
  const debug = process.env.IS_NAVIGATION_DEBUG_ENABLED === 'true';

  SpatialNavigation.init({ debug, visualDebug, throttleKeypresses: true, throttle: 100 });
  if (isDev) {
    window.SN = SpatialNavigation;
  }

  const contentStore = useContentStore();
  const sessionStore = useSessionStore();

  try {
    await sessionStore.fetchUser({ forceFetchUser: true });
  } catch (error) {
    console.error(error);
  }

  await contentStore.fetchGenres();
  await contentStore.fetchPeriods();
  await contentStore.fetchCountries();
  await sessionStore.fetchOffers();

  const router = routerService._router;
  createAppView(store, router);
  isInitialized = true;
}

const onError = (error) => {
  new Vue({
    render: (h) => h(SmartTvCriticalErrorView as any, { props: { error } }),
  }).$mount('#app');
};

const run = () => {
  main().catch((error) => {
    onError(error);
    run();
  });
};

const os = deviceService.getOSInformation();

document.addEventListener('DOMContentLoaded', () => run());

if (os === OperationSystem.WebOs) {
  runningTimeoutId = window.setTimeout(() => run(), 2000);
}
