<template>
  <div>
    <Transition>
      <UINotification
        v-if="!isOnboardingShown"
        :class="$style.notification"
        :title="$t('pages.moments.onboarding.firstTimeTitle')"
        :subtitle="$t('pages.moments.onboarding.firstTimeSubTitle')"
      />
    </Transition>

    <Transition>
      <UINotification
        v-if="!isOnboardingLikeShown"
        :class="$style.notification"
        :title="$t('pages.moments.onboarding.likeTitle')"
        :subtitle="$t('pages.moments.onboarding.likeSubtitle')"
      />
    </Transition>
    <Transition>
      <UINotification
        v-if="!isOnboardingDislikeShown"
        :class="$style.notification"
        :title="$t('pages.moments.onboarding.dislikeTitle')"
        :subtitle="$t('pages.moments.onboarding.dislikeSubtitle')"
      />
    </Transition>
    <Transition>
      <UINotification
        v-if="!isOnboardingSaveShown"
        :class="$style.notification"
        :title="$t('pages.moments.onboarding.saveTitle')"
        :subtitle="$t('pages.moments.onboarding.saveSubtitle')"
      />
    </Transition>
    <Transition>
      <UINotification
        v-if="isAuthError"
        :class="$style.notification"
        :title="$t('pages.moments.limitReached.title')"
        :subtitle="$t('pages.moments.limitReached.subtitle')"
      >
        <template v-slot:actions>
          <div ref="itemRef" :class="$style.actions">
            <NavigatableItem
              :class="$style.button"
              :active-class="$style.active"
              :tag="UIButton"
              :on-click="() => $emit('login')"
            >
              {{ $t('pages.moments.limitReached.login') }}
            </NavigatableItem>
            <NavigatableItem
              :class="$style.button"
              :active-class="$style.active"
              :tag="UIButton"
              :on-click="() => $emit('cancel')"
            >
              {{ $t('pages.moments.limitReached.later') }}
            </NavigatableItem>
          </div>
        </template>
      </UINotification>
    </Transition>
  </div>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys } from '@SMART/index';
import { nextTick, onMounted, provide, ref, watch } from '@vue/composition-api';

import UIButton from '@/components/button/UIButton.vue';
import UINotification from '@/components/notification/UINotification.vue';

export default {
  components: {
    UINotification,
  },
  props: {
    isOnboardingShown: Boolean,
    isOnboardingLikeShown: Boolean,
    isOnboardingDislikeShown: Boolean,
    isOnboardingSaveShown: Boolean,
    isAuthError: Boolean,
  },
  data: () => ({ UIButton, FocusKeys }),
  setup(props, { emit }) {
    const itemRef = ref(null);

    const { el, focusKey, addFocusable, removeFocusable } = useNavigatable({
      focusKey: FocusKeys.MY_CHANNEL_NOTIFICATIONS_ACTIONS,
      hasGlobalAccess: true,
    });
    provide('parentFocusKey', focusKey.value);

    watch(
      () => props.isAuthError,
      async (value) => {
        if (value) {
          await nextTick();
          addFocusable();
        } else {
          removeFocusable();
        }
      },
    );

    watch(
      () => itemRef.value,
      (element) => {
        el.value = element;
      },
    );

    onMounted(() => {
      removeFocusable();
    });

    return { itemRef };
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.actions {
  display: flex;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: adjustPx(24px);
    margin-right: adjustPx(24px);
    padding: adjustPx(16px) adjustPx(24px);
    width: adjustPx(144px);
    height: adjustPx(80px);
    border-radius: adjustPx(20px);
    background: var(--color-bg-button-secondary);
    @include f-label-2;
  }

  .active {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.notification {
  position: fixed;
  bottom: adjustPx(176px);
  right: adjustPx(48px);
}
</style>
