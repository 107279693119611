import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { AppLanguage } from '@package/sdk/src/core/variation/variation';
import type { Locale } from 'date-fns';
import { format as formatFunc, formatDuration, intervalToDuration } from 'date-fns';
import enGB from 'date-fns/locale/en-GB/index';
import hy from 'date-fns/locale/hy/index';
import ru from 'date-fns/locale/ru/index';

const DateFormat = {
  [AppLanguage.EN]: 'dd.MM.yyyy',
  [AppLanguage.RU]: 'dd.MM.yyyy',
  [AppLanguage.AM]: 'dd.MM.yyyy',
};

const zeroPad = (num: number) => String(num).padStart(2, '0');

export const AppDateManager = new (class {
  private locale: AppLanguage = AppLanguage.RU;

  public setLocale(locale: AppLanguage) {
    this.locale = locale;
  }

  public formatDuration = (seconds: number) => {
    return formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), {
      locale: {
        formatDistance: (_token, count) => zeroPad(count),
      },
      format: ['hours', 'minutes', 'seconds'],
      zero: true,
      delimiter: ':',
    });
  };

  public format = (date: Date | number): string => {
    const format = this.getFormat();
    const locale = this.getLocale();

    return formatFunc(date, format, {
      locale,
    });
  };

  public formatDateWithCustomFormat = (date: Date | number, format: string): string => {
    const locale = this.getLocale();

    return formatFunc(date, format, {
      locale,
    });
  };

  private getFormat() {
    return DateFormat[this.locale] || ConstantsConfigPlayer.getProperty('defaultDateFormat');
  }

  private getLocale(): Locale {
    if (this.locale === AppLanguage.RU) {
      return ru;
    }

    if (this.locale === AppLanguage.AM) {
      return hy;
    }

    if (this.locale === AppLanguage.EN) {
      return enGB;
    }

    return ru;
  }
})();
