import { webosUserAgents } from '@package/sdk/src/core/smarttv/webos/webos-user-agents';

export function getChromeVersion(userAgent = navigator.userAgent): number {
  const raw = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : 0;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('WebOS TV 24 return 108', () => {
    expect(getChromeVersion(webosUserAgents['24'].value)).toBe(108);
  });

  it('WebOS TV 23 return 94', () => {
    expect(getChromeVersion(webosUserAgents['23'].value)).toBe(94);
  });

  it('WebOS TV 22 return 87', () => {
    expect(getChromeVersion(webosUserAgents['22'].value)).toBe(87);
  });

  it('WebOS TV 6.x return 79', () => {
    expect(getChromeVersion(webosUserAgents['6.x'].value)).toBe(79);
  });

  it('WebOS TV 5.x return 68', () => {
    expect(getChromeVersion(webosUserAgents['5.x'].value)).toBe(68);
  });

  it('WebOS TV 4.x return 53', () => {
    expect(getChromeVersion(webosUserAgents['4.x'].value)).toBe(53);
  });

  it('WebOS TV 3.x return 38', () => {
    expect(getChromeVersion(webosUserAgents['3.x'].value)).toBe(38);
  });
}
