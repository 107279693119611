var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"el",class:_vm.$style.wrapper},[(!_vm.isMenuHidden)?_c('AppMenu',{key:_vm.menuKey,ref:"appMenuRef"}):_vm._e(),(_vm.isBuildBadgeShown)?_c('AppBuildBadge'):_vm._e(),_c('div',{class:( _obj = {}, _obj[_vm.$style.pageMenuExpanded] = _vm.hasFocusedMenu, _obj[_vm.$style.page] = true, _obj )},[_c('KeepAlive',{key:_vm.cacheKey,attrs:{"data-key":_vm.cacheKey,"include":[
        _vm.$RouterPage.CatalogPage,
        // $RouterPage.CollectionPage,
        _vm.$RouterPage.SearchPage,
        _vm.$RouterPage.ChannelsPage,
        _vm.$RouterPage.MyChannelPage,
        _vm.$RouterPage.MainPage ],"max":1}},[_c('RouterView',{key:_vm.cacheKey,ref:"keepAlive",attrs:{"data-key":_vm.cacheKey}})],1)],1),_c('UIAlert'),_c('portal-target',{attrs:{"name":"modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }