<template>
  <ScrollViewport ref="el" tag="div" :class="$style.seasons" :x="offsetLeftPx" role="list">
    <NavigatableItem
      v-for="(season, index) in seasons"
      :key="season.id"
      :class="{ [$style.season]: true, [$style.activeSeason]: activeSeason === index }"
      :tag="AppButton"
      :data-testid="`seasons-${index + 1}`"
      :data-index="index"
      :text="$t('pages.mediaCard.season', { index: season.number })"
      :focus-key="FocusKeys.MEDIA_CARD_SEASONS_ITEM(index)"
      @active="(element) => handleActive(element, index)"
      @click="$emit('click', index)"
    />
  </ScrollViewport>
</template>

<script>
import { FocusKeys, useSliderOffset } from '@package/smarttv-base/src';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { provide, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

export default {
  props: {
    activeSeason: {
      type: Number,
      required: true,
    },
    seasons: {
      type: Array,
      required: true,
    },
  },
  components: {
    AppButton,
    NavigatableItem,
    ScrollViewport,
  },
  setup(_, { emit }) {
    const { focusKey, el } = useNavigatable({
      saveLastFocusedChild: true,
      focusKey: FocusKeys.MEDIA_CARD_PAGE_SEASONS_SLIDER,
      focusBoundaryDirections: ['right'],
      hasGlobalAccess: true,
      isFocusBoundary: true,
    });
    provide('parentFocusKey', focusKey.value);

    const { handleUpdateOffset, offsetLeftPx } = useSliderOffset();

    const handleActive = (element, index) => {
      handleUpdateOffset(element, el.value.$el.offsetWidth, false);
      emit('active', index);
    };

    return {
      focusKey,
      el,
      offsetLeftPx,
      handleActive,
      AppButton,
      NavigatableItem,
      ScrollViewport,
      FocusKeys,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.seasons {
  display: flex;
  margin-bottom: adjust.adjustPx(32px);
}
.season {
  margin-right: adjust.adjustPx(24px);
  width: adjust.adjustPx(163px);
  min-width: adjust.adjustPx(163px);
  height: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(80px);
  background-color: var(--color-bg-tertiary);
}

.activeSeason {
  background-color: var(--color-bg-button);
}
</style>
