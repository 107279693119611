<template>
  <div ref="el" :class="$style.wrapper">
    <video
      ref="videoEl"
      playsinline
      :autoplay="autoplay"
      :loop="loop"
      :muted="isMuted"
      :preload="preload"
      :poster="poster"
    />

    <app-loader v-if="isShowVideoLoader" />

    <video-action v-if="isShowVideoAction && !isTimelineHidden && !isShowVideoLoader" :current-action="currentAction" />

    <div v-if="true" :class="{ [$style.controlsLayerWrapper]: true }">
      <div :class="$style.controlsLayer">
        <video-header v-if="hideable.isShowVideoHeader" :is-title-shown="isVOD" v-bind="videoHeaderContent" />

        <video-timeline
          v-if="isTimelinePlaybackShown"
          :mark="isLinkedKinomShown && linkedKinom && linkedKinom.startOffset"
          :current-time="normalizedCurrentTime"
          :displayed-current-time="displayedCurrentTime"
          :duration="normalizedDuration"
          :buffer-length="0"
          :is-playing="isPlaying"
          :is-dvr="false"
          :is-timeline-dragging="isTimelineDragging"
          @updateCurrentTime="_onVideoCurrentTimeUpdate"
          @updateTimelineDragging="_onTimelineDragging"
          @click="togglePlay"
        />

        <video-controls
          v-if="!isControlsHidden"
          :is-playing="isPlaying"
          :is-dvr="isDVR"
          :is-current-time-live="isCurrentTimeLive"
          :on-seek-to-live="_onSeekToLive"
          :is-linked-kinom-shown="isLinkedKinomShown"
          :is-timestamp-active="isTimestampActive"
          :linked-kinom="linkedKinom"
          :media="media"
          @showSeriesMenu="_onShowSeriesMenu"
          @playNextAvailableEpisode="_onPlayNextAvailableEpisode"
          @play="play"
          @pause="pause"
          @seek="_onSeekToLinkedKinom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, DisposableStore, EventEmitter, throttle, TvKeyCode } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { keyboardEventHandler, translate } from '@SMART/index';
import { computed, inject, onMounted, provide, ref } from '@vue/composition-api';

import AppLoader from '@/components/loader/AppLoader.vue';
import VideoControls from '@/components/video-player/components/controls/VideoControls.vue';
import VideoHeader from '@/components/video-player/components/header/VideoHeader.vue';
import VideoTimeline from '@/components/video-player/components/timeline/VideoTimeline.vue';
import VideoAction from '@/components/video-player/components/video/VideoAction.vue';
import VideoLoader from '@/components/video-player/components/video/VideoLoader.vue';
import usePlayerMediaSourceTech from '@/utils/use-player-media-source-tech';

// сhunk length x 3 (default value for edge)
const liveTimeoutEdgeSeconds = 3 * 6;
const defaultTitlesDuration = 20;

export default {
  components: {
    AppLoader,
    VideoLoader,
    VideoAction,
    VideoHeader,
    VideoTimeline,
    VideoControls,
  },
  props: {
    isControlsHidden: { default: false, type: Boolean },
    isTimelineHidden: { default: false, type: Boolean },
    isControlDisabled: { default: false, type: Boolean },
    activeSeasonIndex: { type: Number },
    activeEpisodeIndex: { type: Number },
    isMuted: { default: false, type: Boolean },
  },
  data() {
    return {
      src: '',
      projector: 'kinom',
      autoplay: false,
      loop: false,
      muted: true,
      preload: true,
      poster: '',
      currentTime: 0,
      duration: 0,
      isPlaying: false,
      isTimelineDragging: false,
      showSeriesMenu: false,
      'content.media': {},
      'content.playlists': [],
      'video.isDVR': false,
      'content.linkedKinom': null,
      isLinkedKinomShown: false,
      hideable: {
        isShowVideoPlayControls: true,
        isShowVideoHeader: true,
        isShowLayerControls: true,
      },
      isInitialized: false,
      initLiveTime: 0,
      currentAction: '',
      isMediaSourceTechInitialized: false,
      subtitle: '',
    };
  },
  setup(props) {
    const { initializeMediaSourceTech } = usePlayerMediaSourceTech();
    const manifest = inject('manifest', {});

    const mediaSourceTech = ref(
      initializeMediaSourceTech({
        projector: 'kinom',
        isDurationInfinity: false,
      }),
    );
    const disposableStore = new DisposableStore();

    disposableStore.add(mediaSourceTech.value);

    let elementRef;
    if (!props.isControlDisabled) {
      const { el, focusKey, focusSelf } = useNavigatable({
        focusKey: 'VIDEO_CONTROLS',
        trackChildren: true,
      });

      elementRef = el;
      provide('parentFocusKey', focusKey.value);
      onMounted(() => focusSelf());
    }

    const emitter = new EventEmitter();

    const isTimestampActive = ref(false);
    const closingCreditsStartsAt = computed(() => {
      return manifest?.value?.credits?.closingCreditsStartsAt;
    });

    provide('isTimestampActive', isTimestampActive.value);

    return {
      el: elementRef,
      mediaSourceTech,
      emitter,
      disposableStore,
      manifest,
      closingCreditsStartsAt,
      isTimestampActive,
    };
  },
  computed: {
    hasLinkedKinom() {
      return Boolean(this['content.linkedKinom']);
    },
    linkedKinom() {
      return this['content.linkedKinom'];
    },
    videoEl: function () {
      return this.$refs.videoEl;
    },
    isShowControls: function () {
      if (this.isKinom) {
        return false;
      }

      return this.isInitialized;
    },
    isShowVideoLoader: function () {
      return !this.isInitialized;
    },
    isShowVideoAction: function () {
      if (this.isKinom || this.isTimelineDragging) {
        return false;
      }

      return this.currentAction !== '';
    },
    media: function () {
      return this['content.media'];
    },
    playlists: function () {
      return this['content.playlists'];
    },
    videoHeaderContent: function () {
      const { title, ageLimit } = this['content.media'] || {};

      return {
        title,
        subtitle: this.subtitle,
        limit: ageLimit,
      };
    },
    isDVR: function () {
      return this['video.isDVR'];
    },
    isCurrentTimeLive: function () {
      return this.normalizedDuration - this.currentTime < 30;
    },
    isTimelinePlaybackShown: function () {
      if (this.showSeriesMenu) {
        return false;
      }

      if (this.isKinom) {
        return false;
      }

      if (this.isVOD) {
        return true;
      }

      return this.isLive && this.isDVR;
    },
    isKinom: function () {
      return this.projector === 'kinom';
    },
    isLive: function () {
      return this.projector === 'live';
    },
    isVOD: function () {
      return this.projector === 'vod';
    },
    normalizedDuration: function () {
      if (this.isLive && this.isDVR && this.initLiveTime) {
        return this.duration - (+new Date() - this.initLiveTime) / 1000;
      }

      return this.duration;
    },
    normalizedCurrentTime: function () {
      if (this.isLive && this.isDVR) {
        return this.currentTime - (+new Date() - this.initLiveTime) / 1000;
      }

      return this.currentTime;
    },
    displayedCurrentTime: function () {
      if (this.isLive && this.isDVR) {
        return this.currentTime - this.duration;
      }

      return this.currentTime;
    },
  },
  created() {
    this.debouncedHideControls = debounce(this.hideControls, 6000);
  },
  async mounted() {
    window.addEventListener('keydown', this._onInteraction);
    window.addEventListener('wheel', this._onInteraction);

    await this.mediaSourceTech.init();

    await this.$nextTick();

    this.videoEl.addEventListener('playing', this._onPlayingVideo);
    this.videoEl.addEventListener('timeupdate', this._onCurrentTimeUpdate);
    this.videoEl.addEventListener('loadedmetadata', this._onLoadedMetadata);
    this.videoEl.addEventListener('durationchange', this._onDurationChange);
    this.videoEl.addEventListener('loadeddata', this._onLoadedData);
    this.videoEl.addEventListener('ended', this._onEnded);

    this.videoEl.volume = 1;

    this.disposableStore.add(keyboardEventHandler.on(TvKeyCode.PLAY, () => this.play()));
    this.disposableStore.add(keyboardEventHandler.on(TvKeyCode.PAUSE, () => this.pause()));
    this.disposableStore.add(keyboardEventHandler.on(TvKeyCode.STOP, () => this.pause()));

    if (!this.isControlDisabled) {
      this._onInteraction();
    }

    if (this.hls) {
      this.hls.on(Hls.Events.FRAG_BUFFERED, this._onHlsFragBuffered);
    }

    this.$emit('mounted');
    this.updateSubtitle();
  },
  methods: {
    updateSubtitle() {
      const playlist = this.playlists?.[this.activeSeasonIndex];

      if (!playlist?.episodes) {
        return '';
      }

      const season = playlist.number;
      const episode = this.activeEpisodeIndex + 1;

      this.subtitle = translate('player.episodeNumber', {
        season,
        episode,
      });
    },
    setLinkedKinomState(state) {
      this.isLinkedKinomShown = state;
    },
    load: function ({ src }) {
      this.mediaSourceTech.attachMedia(this.videoEl);

      this.mediaSourceTech.loadSource({
        src,
        isLive: this.isLive,
        initialQualityLevel: 'auto',
      });
      this.isMediaSourceTechInitialized = true;
    },
    togglePlay: function () {
      if (this.isPlaying) {
        this.pause();
        return;
      }

      this.play();
    },
    play: function () {
      if (!this.isMediaSourceTechInitialized) {
        return;
      }
      const playPromise = this.mediaSourceTech.play();

      if (playPromise) {
        playPromise.catch((error) => console.error(error));
      }

      this.isPlaying = true;
      this.currentAction = 'play';
    },
    pause: function () {
      this.mediaSourceTech.pause();
      this.isPlaying = false;
      this.currentAction = 'pause';
    },
    seekTo: function (options) {
      const { offset } = options;

      try {
        this._onVideoCurrentTimeUpdate(offset);
      } catch (e) {
        const timeoutId = window.setInterval(() => {
          if (!this.isInitialized) {
            return;
          }

          this._onVideoCurrentTimeUpdate(offset);
          window.clearInterval(timeoutId);
        });
      }
    },
    on: function (event, callback) {
      this.emitter.on(event, callback);
    },
    setConfigProperty: function (key, value) {
      this[key] = value;
    },
    showControls: function () {
      this.hideable.isShowVideoPlayControls = true;
      this.hideable.isShowVideoHeader = true;
      this.hideable.isShowLayerControls = true;
    },
    hideControls: function () {
      if (this.showSeriesMenu || !this.isPlaying) {
        return;
      }

      this.hideable.isShowVideoPlayControls = false;
      this.hideable.isShowVideoHeader = false;
      this.hideable.isShowLayerControls = false;
    },
    hideSeriesMenu: function () {
      this.showSeriesMenu = false;
    },
    _onInteraction: function () {
      this.showControls();
      this.debouncedHideControls();
    },
    _onPlayingVideo: function () {
      this.isPlaying = true;

      if (!this.isInitialized) {
        this.isInitialized = true;
      }
    },
    _onCurrentTimeUpdate: function () {
      this.currentTime = parseFloat(this.videoEl.currentTime);
    },
    _onVideoCurrentTimeUpdate: function (value) {
      if (this.isLive) {
        const minTime = (+new Date() - this.initLiveTime) / 1000;

        this.mediaSourceTech.seekTo((value < 0 ? 0 : value) + minTime);
      } else {
        this.mediaSourceTech.seekTo(value < 0 ? 0 : value);
      }

      if (!this.isPlaying) {
        this.play();
      }

      if (this.isTimelineDragging) {
        this.isTimelineDragging = false;
      }
    },
    _onTimelineDragging: function (value) {
      this.isTimelineDragging = value;

      if (!value) {
        return;
      }

      if (this.isPlaying) {
        this.pause();
      }
    },
    _onLoadedMetadata: function () {
      this.duration = this.videoEl.duration;
      this.isInitialized = true;
      this.initLiveTime = +new Date();
      this.play();
    },
    _onDurationChange: function () {
      this.duration = this.videoEl.duration;
    },
    _onLoadedData: function () {
      const currentTime = parseFloat(this.videoEl.currentTime);

      this.isInitialized = true;

      if (this.isLive) {
        this.duration = currentTime + liveTimeoutEdgeSeconds;
      }
    },
    _onShowSeriesMenu: function () {
      this.emitter.emit('redirect-required', { data: { page: 'episodes-menu' } });
      this.showSeriesMenu = true;
    },
    _onPlayNextAvailableEpisode: function (data) {
      this.emitter.emit('media-play-requested', { data });
    },
    _onSeekToLive: function () {
      this._onVideoCurrentTimeUpdate(this.videoEl.duration - liveTimeoutEdgeSeconds);
      this.play();
    },
    _onSeekToLinkedKinom: function (data) {
      if (data) {
        this.$emit('update-series', data.serial);
        return this.emitter.emit('media-play-requested', { data });
      }

      this._onVideoCurrentTimeUpdate(this.linkedKinom.startOffset);
      this.play();
      this.setLinkedKinomState(false);
    },
    _onEnded: function () {
      this.$emit('ended');
      this.emitter.emit('ended');
    },
  },
  watch: {
    playlists() {
      this.updateSubtitle();
    },
    activeEpisodeIndex() {
      this.updateSubtitle();
    },
    activeSeasonIndex() {
      this.updateSubtitle();
    },
    isInitialized(state) {
      this.$emit('initialized', state);
    },
    hasLinkedKinom(state) {
      this.setLinkedKinomState(state);
      if (state) {
        window.setTimeout(() => {
          this.setLinkedKinomState(false);
        }, 10_000);
      }
    },
    displayedCurrentTime: throttle(function (offset) {
      if (!offset || this.isLive) {
        return;
      }

      this.emitter.emit('media-watching-progress-updated', {
        data: {
          offset: Math.floor(offset),
        },
      });
    }, 1000),
    currentTime(val) {
      if (!val) {
        return;
      }

      if (this.closingCreditsStartsAt) {
        this.isTimestampActive = this.closingCreditsStartsAt <= val;
        return;
      }

      this.isTimestampActive = this.media.duration - defaultTitlesDuration <= val;
    },
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this._onInteraction);
    window.removeEventListener('wheel', this._onInteraction);

    this.videoEl.removeEventListener('playing', this._onPlayingVideo);
    this.videoEl.removeEventListener('timeupdate', this._onCurrentTimeUpdate);
    this.videoEl.removeEventListener('loadedmetadata', this._onLoadedMetadata);
    this.videoEl.removeEventListener('durationchange', this._onDurationChange);
    this.videoEl.removeEventListener('loadeddata', this._onLoadedData);
    this.videoEl.removeEventListener('ended', this._onEnded);

    this.disposableStore.dispose();
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.wrapper {
  display: block;
  background-color: var(--color-notheme-bg-stream);
}

video {
  width: 100%;
  height: 100%;
}

.controlsLayer {
  display: flex;
  flex-direction: column;
  padding: adjustPx(16px) adjustPx(48px) adjustPx(48px) adjustPx(48px);
  width: 100%;

  &Wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.hidden {
  visibility: hidden;
  z-index: -1;
}
</style>
