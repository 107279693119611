import type { MediaContentType } from '../../api/content/types/content-type';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';
import { getAnalyticContentTypeValue } from './get-kmza-content-type-value';

interface IClickCollectionsRemove {
  id: string;
  kinomId?: string;
  episodeId?: string;
  title: string;
  kinomTitle?: string;
  type: MediaContentType;
}

export function useMyCollectionPageAnalytics(sender: AnalyticEventSender) {
  const onAutoCollectionsDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoCollectionsDisplayed,
      page: AnalyticPageName.Collections,
    });
  };

  const onGotoCollectionsPage = (page: AnalyticPageName) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoCollectionsPage,
      page,
    });
  };

  const onShowCollectionsPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowCollectionsPage,
      page: AnalyticPageName.Collections,
    });
  };

  const onClickCollectionsItems = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCollectionsItems,
      page: AnalyticPageName.Collections,
    });
  };

  const onClickCollectionsKinoms = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCollectionsKinoms,
    });
  };

  const onClickCollectionsItemRemove = (options: IClickCollectionsRemove) => {
    const { id, title, type } = options;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCollectionsItemRemove,
      page: AnalyticPageName.Collections,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: title,
        },
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: getAnalyticContentTypeValue(type),
        },
      ],
      options: {
        itemId: id,
      },
    });
  };

  const onClickCollectionsKinomRemove = (options: IClickCollectionsRemove) => {
    const { id, title, kinomTitle, type, kinomId, episodeId } = options;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCollectionsKinomRemove,
      page: AnalyticPageName.Collections,
      values: [
        {
          label: AnalyticPayloadLabelName.KinomTitle,
          value: kinomTitle,
        },
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: title,
        },
        {
          label: AnalyticPayloadLabelName.ItemType,
          value: getAnalyticContentTypeValue(type),
        },
      ],
      options: {
        itemId: id,
        ...(kinomId && { kinomId }),
        ...(episodeId && { episodeId }),
      },
    });
  };

  return {
    onClickCollectionsItems,
    onClickCollectionsKinoms,
    onClickCollectionsKinomRemove,
    onClickCollectionsItemRemove,
    onGotoCollectionsPage,
    onShowCollectionsPage,
    onAutoCollectionsDisplayed,
  };
}
