import type { ApiStreamManifest } from './content-types/stream-manifest-types';
import type { StreamManifest } from './types/stream-manifest';

export class StreamManifestMapper {
  static map({ hls, sprite, subs, metadata, sign, credits }: ApiStreamManifest): StreamManifest {
    return {
      hls,
      sprite,
      credits: {
        closingCreditsStartsAt: credits?.closing_credits_starts_at,
      },
      watchingItemToken: sign,
      subtitles: subs,
      isDVR: Boolean(metadata?.is_dvr),
      isLive: Boolean(metadata?.is_live),
    };
  }
}
