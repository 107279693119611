<template>
  <div :class="$style.tabs">
    <div
      role="tablist"
      :class="{
        [$style.tabsList]: true,
        [$style.tabsSecondary]: variant === 'secondary',
      }"
    >
      <slot :tab-index="tabIndex" :on-tab-changed="onTabChanged" name="tabs" />
    </div>

    <div role="tabpanel" :class="$style.tabsPanel">
      <slot name="items" :tab-index="tabIndex" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';

export default {
  props: {
    tab: { default: 0 },
    variant: { type: String, default: 'primary' },
  },
  setup(props, { emit }) {
    const index = computed({
      get: () => props.tab,
      set: (value) => emit('update:tab', value),
    });

    const tabIndex = ref(props.tab ?? 0);

    const onTabChanged = (value) => {
      tabIndex.value = value;
      index.value = value;
      emit('update:tab', value);
    };
    return { index, tabIndex, onTabChanged };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.tabs {
  display: flex;
  flex-direction: column;
  height: fit-content;

  &List {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: adjust.adjustPx(6px);
    width: max-content;
    height: adjust.adjustPx(84px);
    border: adjust.adjustPx(3px) solid var(--color-stroke-input-border);
    border-radius: adjust.adjustPx(24px);
    color: var(--color-text-primary);
    cursor: pointer;

    @include smartTvFonts.SmartTvLabel-2();
  }

  &Item {
    height: 100%;
  }

  &Secondary {
    padding: 0;
    border: none;
  }

  &Panel {
    display: flex;
    color: var(--color-text-primary);

    @include smartTvFonts.SmartTvLabel-2();
  }
}
</style>
