import { type DeviceService } from '../device/device-service';
import globalEmitter from '../global-emitter/global-emitter';
import type { RouterService } from '../router/router-service';
import { LGSpecialOffer, MarketingFeature } from './features';

export interface MarketingServiceHooks {
  onAppStarted: () => void;
  onAppMounted: () => void;
}

export class MarketingService {
  private features: MarketingFeature[] = [];

  constructor(deviceService: DeviceService, routerService: RouterService) {
    MarketingFeature.init(deviceService, routerService);
    this.features = [new LGSpecialOffer()];
  }

  public init() {
    globalEmitter.on('app-started', () => this.onAppStarted());
    globalEmitter.on('app-mounted', () => this.onAppMounted());
  }

  private onAppStarted() {
    this.features.forEach((feature) => feature.onAppStarted());
  }

  private onAppMounted() {
    this.features.forEach((feature) => feature.onAppMounted());
  }
}
