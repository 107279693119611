<template>
  <NavigatableItem
    :class="{
      [$style.poster]: true,
      [$style.sizeMedium]: size === 'medium',
      [$style.sizeSmall]: size === 'small',
      [$style.sizeKinom]: size === 'kinom',
      [$style.borderless]: isLoading,
    }"
    tabindex="0"
    :tag="UIButton"
    :active-class="$style.posterActive"
    @active="onActive"
    @click="$emit('clicked')"
    @keyup.enter="$emit('clicked')"
  >
    <template #icon>
      <app-image
        :id="id"
        :class="{
          [$style.posterImg]: true,
          [$style.sizeMedium]: size === 'medium',
          [$style.sizeSmall]: size === 'small',
          [$style.sizeKinom]: size === 'kinom',
          [$style.posterImgKinom]: size === 'kinom',
          [$style.active]: isActive,
        }"
        :src="src"
        :alt="title"
        :width="420"
        :is-loading="isLoading"
      />
      <p
        v-if="variant === 'row'"
        :class="{
          [$style.text]: true,
          [$style.variantRow]: variant === 'row',
          [$style.sizeMedium]: size === 'medium',
          [$style.sizeSmall]: size === 'small',
          [$style.textActive]: isActive,
        }"
      >
        <slot>{{ title }}</slot>
      </p>
    </template>
  </NavigatableItem>
</template>
<script>
import AppImage from '@/components/app-image/AppImage.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import UIButton from '../button/UIButton.vue';

export default {
  components: {
    AppImage,
    NavigatableItem,
    UIButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'column',
    },
    size: {
      type: String,
      default: 'medium',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    scrollBlock: {
      type: String,
      default: 'center',
    },
    scrollInline: {
      type: String,
      default: 'center',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const onActive = async (el) => {
      emit('active', el);
    };

    return {
      onActive,
      UIButton,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/colors';

$border-width: adjustPx(7px);

.active {
  border: $border-width solid var(--color-bg-accent);
  border-radius: adjustPx(37px);
  outline: none;
}

.poster {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-right: adjustPx(16px);
  border: none;
  border-radius: adjustPx(40px);
  outline: none;
  background-color: var(--color-bg-modal);
  scroll-behavior: smooth;
  cursor: pointer;

  &Kinom {
    width: adjustPx(413px);
    height: adjustPx(310px);
  }

  &ImgKinom {
    transform: scale(1.45, 1.45);
  }

  &Img {
    border-radius: adjustPx(38px);
    scroll-behavior: smooth;
  }

  &Active::after,
  &:hover::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: $border-width solid var(--color-bg-accent);
    border-radius: adjustPx(37px);
    outline: none;
    overflow: hidden;
    content: '';
  }
}

.size {
  &Medium {
    width: adjustPx(315px);
    max-width: adjustPx(315px);
    height: adjustPx(430px);
  }

  &Small {
    width: adjustPx(240px);
    max-width: adjustPx(240px);
    height: adjustPx(336px);
  }

  &Kinom {
    margin-right: 0;
    width: adjustPx(413px);
    max-width: adjustPx(413px) !important;
    height: adjustPx(310px);
    overflow: hidden;
  }
}

.variant {
  &Row {
    position: absolute;
    left: adjustPx(240px);
    overflow: visible;
  }

  &Column {
    position: absolute;
    margin-top: adjustPx(-70px);
    max-width: adjustPx(413px);
    height: max-content;
    overflow: hidden;
  }
}

.text {
  bottom: 0;
  padding: adjustPx(24px);
  width: 100%;
  border-radius: 0 0 adjustPx(30px) adjustPx(30px);
  background-color: var(--color-bg-secondary-60);
  white-space: nowrap;
  text-overflow: ellipsis;

  @include f-body;

  &Active {
    bottom: $border-width;
    width: calc(100% - 2 * $border-width);
  }
}

.borderless {
  border: none;
}
</style>
