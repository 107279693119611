<template>
  <component :is="tag" :class="$style.scrollViewport" :style="style">
    <ui-content-row v-if="isLoading" :items="[1]">
      <UIPoster
        v-for="rowItem in 5"
        :key="rowItem"
        :class="$style[shimmerSize]"
        tabindex="0"
        is-loading
        :size="shimmerSize"
      />
    </ui-content-row>

    <slot v-else />
  </component>
</template>

<script>
import UiContentRow from '../content/UiContentRow.vue';
import UIPoster from '../poster/UIPoster.vue';

export default {
  components: {
    UiContentRow,
    UIPoster,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    orientation: {
      type: String,
      required: false,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    shimmerSize: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    transform() {
      if (this.orientation) {
        return '';
      }

      let xVal = parseFloat(this.x.toString());
      let yVal = parseFloat(this.y.toString());

      xVal = !isNaN(xVal) ? xVal * -1 : 0;
      yVal = !isNaN(yVal) ? yVal * -1 : 0;

      return `transform: translate(${xVal}px, ${yVal}px)`;
    },
    style() {
      if (this.orientation) {
        return {
          'overflow-x': this.orientation === 'horizontal' ? 'auto' : 'hidden',
          'overflow-y': this.orientation === 'vertical' ? 'auto' : 'hidden',
        };
      } else {
        return this.transform;
      }
    },
  },
  mounted() {
    this.$emit('vue:mounted');
  },
  // render(h) {
  //   return h(this.tag, { style: this.style }, this.$slots.default);
  // },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
.scrollViewport {
  transition: transform 0.32s ease 0s;
  will-change: transform;

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
}

.kinom {
  margin-right: adjust.adjustPx(24px);
  min-width: adjust.adjustPx(370px);
  max-width: adjust.adjustPx(370px);
  height: adjust.adjustPx(224px);
}
</style>
