<template>
  <div ref="el" :class="$style.wrapper">
    <AppMenu v-if="!isMenuHidden" ref="appMenuRef" :key="menuKey" />
    <AppBuildBadge v-if="isBuildBadgeShown" />

    <div :class="{ [$style.pageMenuExpanded]: hasFocusedMenu, [$style.page]: true }">
      <KeepAlive
        :key="cacheKey"
        :data-key="cacheKey"
        :include="[
          $RouterPage.CatalogPage,
          // $RouterPage.CollectionPage,
          $RouterPage.SearchPage,
          $RouterPage.ChannelsPage,
          $RouterPage.MyChannelPage,
          $RouterPage.MainPage,
        ]"
        :max="1"
      >
        <RouterView ref="keepAlive" :key="cacheKey" :data-key="cacheKey" />
      </KeepAlive>
    </div>

    <UIAlert />

    <portal-target name="modal" />
  </div>
</template>

<script>
import useLogger from '@package/logger/src/use-logger';
import { isDefined, isFunction, isUndefined } from '@package/sdk/src/core';
import { FeatureToggle } from '@package/unleash/src/feature-toggle/index';
import {
  analyticService,
  authService,
  channelsService,
  contentCacheManager,
  environmentService,
  featuresService,
  onboardingService,
  requestService,
  RouterPage,
  routerService,
  storeToRefs,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@SMART/index';
import { computed, onErrorCaptured, ref, watch } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';

import UIAlert from './components/alert/UIAlert.vue';

export default {
  components: {
    AppBuildBadge,
    AppMenu,
    UIAlert,
  },
  setup() {
    const contentStore = useContentStore();
    const sessionStore = useSessionStore();
    const tvChannelsStore = useTvChannelsStore();
    const mainPageStore = useMainPageStore();

    const logger = useLogger('App.vue');

    const { currentOffer, _profile, _user } = storeToRefs(sessionStore);

    const isMenuHidden = ref(false);
    const isProcessResetSessionData = ref(null);
    const cacheKey = ref(uuidv4());

    const route = ref();
    const routeQuery = ref('{}');
    const routeParams = ref('{}');

    const isReleaseMode = environmentService.getVariable('isRelease');

    const menuRef = ref(null);
    const hasFocusedMenu = computed(() => Boolean(menuRef.value?.hasFocusedChild));

    const isBuildBadgeShown = computed(() => {
      if (!isReleaseMode) {
        return true;
      }

      return route.value.path.includes('settings') || route.value.path.includes('auth');
    });

    const keepAlive = ref();

    const hiddenAppMenuRoutes = [
      RouterPage.MediaCardPage,
      RouterPage.RecoverCodePage,
      RouterPage.MediaCardPlayerPage,
      RouterPage.ChannelsPlayerPage,
      RouterPage.AuthPage,
      RouterPage.LoginPage,
      RouterPage.SignUpPage,
      RouterPage.SignInPage,
      RouterPage.ProfilesPage,
      RouterPage.Offers,
      RouterPage.OfferInfo,
      RouterPage.ParentalPage,
      RouterPage.ParentalCodePage,
      RouterPage.ParentalCodeRecoverPage,
      RouterPage.AppExitPage,
      RouterPage.RecoverPasswordPage,
    ];

    const routesToCacheAlways = [
      RouterPage.ProfilesPage,
      RouterPage.Offers,
      RouterPage.OfferInfo,
      RouterPage.AppExitPage,
      // RouterPage.AuthPage,
      RouterPage.ParentalPage,
      RouterPage.ParentalCodePage,
      RouterPage.ParentalCodeRecoverPage,
      RouterPage.RecoverPasswordPage,
      RouterPage.RecoverCodePage,
      RouterPage.MediaCardPage,
    ];

    let oldQuery;
    const pathsToCache = [
      {
        from: RouterPage.MediaCardPage,
        to: RouterPage.CatalogPage,
        shouldCache: (to, from) => {
          // route.query doesn't work for old versions (cached)
          const [_, query] = window.location.href.split('?') || [];
          if (to === RouterPage.CatalogPage && oldQuery !== query) {
            oldQuery = query;
            return false;
          }

          oldQuery = query;
          return true;
        },
      },
      { to: RouterPage.SearchPage, from: RouterPage.MediaCardPage },
      { to: RouterPage.CollectionPage, from: RouterPage.MediaCardPage },
      { to: RouterPage.MainPage, from: RouterPage.MediaCardPage },
      // { to: RouterPage.MediaCardPlayerPage, from: RouterPage.MediaCardPlayerPage },
    ];

    const routesWithNoCheckParams = [RouterPage.MediaCardPlayerPage];

    const resetSessionData = () => {
      contentStore.reset();
      tvChannelsStore.reset();
      contentCacheManager.clear();
      mainPageStore.reset();
      requestService.clearCache();
      cacheKey.value = uuidv4();

      isProcessResetSessionData.value = Promise.all([
        channelsService.fetchChannels(true),
        contentStore.fetchGenres(),
        contentStore.fetchPeriods(),
        contentStore.fetchCountries(),
        sessionStore.fetchOffers(),
        featuresService.fetchFeatureFlags(),
      ]);
    };

    routerService.addBeforeEach(async (_to, _from, next) => {
      if (isProcessResetSessionData.value) {
        await isProcessResetSessionData.value;
        isProcessResetSessionData.value = null;
      }

      next();
    });

    authService.emitter.on('user.logout', () => {
      onboardingService.resetParentalCode();
      resetSessionData();
    });

    const onDidProfileUpdated = (oldProfile, newProfile) => {
      if (oldProfile?.id === newProfile?.id) {
        return;
      }

      const routeName = route.value?.name;

      analyticService.setFeatureFlags({
        [FeatureToggle?.AllPlatformTestAA]: featuresService.getFeatureFlag(FeatureToggle?.AllPlatformTestAA)?.variant
          ?.value,
      });

      // TODO проверить после плеера
      // SmartTvVijuPlayer.setSession({ offer: currentOffer.value, user: _user?.value });

      if (
        (isUndefined(oldProfile) && isUndefined(newProfile)) ||
        (!isUndefined(oldProfile) && isUndefined(newProfile))
      ) {
        return;
      }

      if (routeName === RouterPage.AuthPage) {
        return;
      }

      resetSessionData();
    };

    const onBeforeEach = (to, from) => {
      isMenuHidden.value = isDefined(to) && hiddenAppMenuRoutes.includes(to);

      if (to && routesToCacheAlways.includes(to)) {
        return;
      }

      const path = pathsToCache.find((path) => {
        if (path.to && path.from) {
          return (to === path.to && from === path.from) || (from === path.to && to === path.from);
        }

        return path.from ? path.from === from : path.to === to;
      });

      const shouldCache = Boolean(path && isFunction(path.shouldCache) ? path.shouldCache(to, from) : path);

      if (!shouldCache) {
        keepAlive.value?.$destroy();
        cacheKey.value = uuidv4();
      }
    };

    // TODO поменять на ulid
    const menuKey = computed(() => _user?.value?.id ?? Math.random());

    const watchRouterParams = (params) => {
      const paramsString = JSON.stringify(params);
      if (
        routerService.lastVisitedRoute?.name === route.value.name &&
        routeParams.value !== paramsString &&
        !routesWithNoCheckParams.includes(route.value.name)
      ) {
        routeParams.value = paramsString;
        cacheKey.value = uuidv4();
      }
    };
    const watchRouterQuery = (query) => {
      const queryString = JSON.stringify(query);
      if (
        routerService.lastVisitedRoute?.name === route.value.name &&
        routeQuery.value !== queryString &&
        !routesWithNoCheckParams.includes(route.value.name)
      ) {
        routeQuery.value = queryString;
        cacheKey.value = uuidv4();
      }
    };
    watch(() => _profile?.value, onDidProfileUpdated, { immediate: true });

    onErrorCaptured((error) => {
      logger.error(error?.toString());
      logger.error(error?.stack);

      throw error;
    });

    return {
      isMenuHidden,
      isBuildBadgeShown,
      hasFocusedMenu,
      cacheKey,
      menuKey,
      menuRef,
      onBeforeEach,
      watchRouterParams,
      watchRouterQuery,
      keepAlive,
      route,
    };
  },
  watch: {
    $route(to, from) {
      this.route = to;
      this.onBeforeEach(to.name, from.name);
    },
    '$route.params'(params) {
      this.watchRouterParams(params);
    },
    '$route.query'(query) {
      this.watchRouterQuery(query);
    },
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.pageMenuExpanded {
  left: adjust.adjustPx(406px);
}
</style>
