import { EventEmitter } from '@package/sdk/src/core';

interface GlobalEventMap {
  'app-started': void;
  'app-mounted': void;
  'deeplink-resolved': void;
}

const globalEmitter: EventEmitter<GlobalEventMap> = new EventEmitter<GlobalEventMap>();
export default globalEmitter;
