import type { CollectionContentType, Media, Moment } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { debounce } from '@package/sdk/src/core';
import { computed, ref } from '@vue/composition-api';

import type { LoadFuncProps } from '../virtual-scroll/useVirtualScroller';

export interface UseUIContentProps {
  onSelect: (content: Media | Moment, type: MediaContentType | CollectionContentType, index: number) => void;
  isContentChanging: () => Promise<boolean>;
  fetchItems: (
    type: MediaContentType | CollectionContentType,
    filter: { page: number; size: number },
  ) => Promise<Media[] | Moment[]>;
  onLoaded: () => void;
  type?: MediaContentType | CollectionContentType;
  initialLoadSize?: number;
  itemsNumber?: {
    perRow?: number;
    perScroll?: number;
  };
}

/**
 * Helper functions and variables for using UIContent
 * - update cache
 * - update loader state
 * - reset page number for pagination
 * - load chunks logic with pagination
 * @param param0
 * @returns
 */
export const useUiContent = ({
  onSelect,
  isContentChanging,
  fetchItems,
  onLoaded,
  type,
  initialLoadSize,
  itemsNumber,
}: UseUIContentProps) => {
  const contentType = ref<MediaContentType | CollectionContentType>(type || MediaContentType.MOVIE);
  const isLoading = ref(true);
  const isFirstChunkLoading = ref(true);

  const itemsPerRow = computed(() => itemsNumber?.perRow ?? 3);

  const itemsPerScroll = computed(() => itemsNumber?.perScroll ?? 3);

  const firstLoadSize = computed(() => initialLoadSize ?? 27);

  const onSelectItem = debounce((content: Media | Moment, index: number) => {
    onSelect(content, contentType.value, index);
  }, 200);

  const onLoadCollectionChunk = async (props: LoadFuncProps) => {
    try {
      const {
        boundaries: { firstId, lastId },
        direction,
        size: loadSize,
        page: chunkPage,
      } = props;

      let page: number;
      let size: number;

      page = chunkPage ?? (direction > 0 ? lastId + 1 : firstId - 1);
      size = itemsPerRow.value * itemsPerScroll.value;
      isFirstChunkLoading.value = !chunkPage || chunkPage === 1;

      if (await isContentChanging()) {
        page = chunkPage ?? 1;
        size = loadSize ?? firstLoadSize.value;
      }

      isLoading.value = true;
      const data = await fetchItems(contentType.value, {
        page,
        size,
      });

      return { data, lineIndex: (page - 1) * itemsPerScroll.value };
    } finally {
      window.setTimeout(() => {
        isLoading.value = false;
        isFirstChunkLoading.value = false;
      }, 1_000);

      onLoaded();
    }
  };

  return {
    contentType,
    itemsPerRow,
    itemsPerScroll,
    firstLoadSize,
    isLoading,
    isFirstChunkLoading,
    onSelectItem,
    onLoadCollectionChunk,
  };
};
