<template>
  <div :class="$style.build">
    <div>App: {{ appVersion }}</div>
    <template v-if="!isRelease">
      <div>Device_UUID: {{ deviceName.uid }}</div>
      <div>Host: {{ host }}</div>
      <div>Build Date: {{ buildDate }}</div>
    </template>
  </div>
</template>

<script>
import { deviceService, environmentService } from '@SMART/index';
import { ref } from '@vue/composition-api';

export default {
  setup() {
    const isRelease = ref(environmentService.getVariable('isRelease'));
    const appVersion = ref(environmentService.getVariable('appVersion')());
    const deviceName = ref(deviceService.getDevice());
    const buildDate = ref(environmentService.getVariable('buildDate'));
    const host = ref(document.location.host);

    return {
      isRelease,
      appVersion,
      deviceName,
      buildDate,
      host,
    };
  },
};
</script>

<style lang="scss" module>
@import '@/styles/colors';

.build {
  position: absolute;
  right: 10px;
  z-index: 10000;
  font-family: monospace;
  font-size: 8px;
  color: var(--color-text-primary);
  pointer-events: none;
}
</style>
