var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"el",class:_vm.$style.filtersModal},[_c('header',{class:_vm.$style.header},[_c('NavigatableItem',{class:_vm.$style.closeButton,attrs:{"active-class":_vm.$style.active,"tag":_vm.AppButton,"transition-duration":"0.1s"},on:{"click":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconClose',{class:_vm.$style.iconClose})]},proxy:true}])}),_c('span',[_vm._v(_vm._s(_vm.$t('pages.catalog.filters')))])],1),_c('div',{class:_vm.$style.filters},[_c('div',{class:_vm.$style.genres},[_c('div',{class:_vm.$style.filterHeader},[_vm._v(_vm._s(_vm.$t('pages.catalog.genres')))]),_c('ScrollViewport',{ref:"genresScroll",class:_vm.$style.scroll,attrs:{"orientation":"vertical"},on:{"vue:mounted":_vm.onGenresVNodeMounted}},[_c('ColumnGrid',{class:_vm.$style.grid,attrs:{"items":_vm.filters.genres,"column-number":2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var id = ref.id;
var item = ref.item;
return [_c('NavigatableItem',{class:_vm.$style.filter,attrs:{"tag":_vm.FilterButton,"text":item.title,"active-class":_vm.$style.active,"variation":'smart-button-secondary',"selected":item.selected,"focus-key":_vm.FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('genres', id),"transition-duration":"0.1s"},on:{"active":function($event){return _vm.onScroll('genres', $event)},"click":function($event){return _vm.$emit('filter:select', item)},"blur":function($event){return _vm.onBlur('genres')},"focus":function($event){return _vm.onFocus('genres')}}})]}}])})],1)],1),(_vm.isGenresMounted)?[_c('div',{class:_vm.$style.delimiter}),_c('div',{class:_vm.$style.countries},[_c('div',{class:_vm.$style.filterHeader},[_vm._v(_vm._s(_vm.$t('pages.catalog.country')))]),_c('ScrollViewport',{ref:"countriesScroll",class:_vm.$style.scroll,attrs:{"orientation":"vertical"}},[_c('ColumnGrid',{class:_vm.$style.grid,attrs:{"items":_vm.filters.countries,"column-number":2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var id = ref.id;
var item = ref.item;
return [_c('NavigatableItem',{class:_vm.$style.filter,attrs:{"tag":_vm.FilterButton,"text":item.title,"active-class":_vm.$style.active,"variation":'smart-button-secondary',"selected":item.selected,"focus-key":_vm.FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('countries', id),"transition-duration":"0.1s"},on:{"active":function($event){return _vm.onScroll('countries', $event)},"click":function($event){return _vm.$emit('filter:select', item)},"blur":function($event){return _vm.onBlur('countries')},"focus":function($event){return _vm.onFocus('countries')}}})]}}],null,false,2308138765)})],1)],1),_c('div',{class:_vm.$style.delimiter}),_c('div',{class:_vm.$style.periods},[_c('div',{class:_vm.$style.filterHeader},[_vm._v(_vm._s(_vm.$t('pages.catalog.periods')))]),_c('ScrollViewport',{ref:"periodsScroll",class:_vm.$style.scroll,attrs:{"orientation":"vertical"}},[_c('ColumnGrid',{class:_vm.$style.grid,attrs:{"items":_vm.filters.periods,"column-number":1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var id = ref.id;
var item = ref.item;
return [_c('NavigatableItem',{class:_vm.$style.filter,attrs:{"tag":_vm.FilterButton,"text":item.title,"active-class":_vm.$style.active,"variation":'smart-button-secondary',"selected":item.selected,"focus-key":_vm.FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('periods', id),"transition-duration":"0.1s"},on:{"active":function($event){return _vm.onScroll('periods', $event)},"click":function($event){return _vm.$emit('filter:select', item)},"blur":function($event){return _vm.onBlur('periods')},"focus":function($event){return _vm.onFocus('periods')}}})]}}],null,false,3481076877)})],1)],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }