<template>
  <section v-if="moment" ref="el" :class="{ [$style.poster]: true, [$style.posterSmall]: !isControlShown }">
    <!-- key нужен для правильного применения  $style.posterImageSmall -->
    <!-- есть баг с отрисовкой при смене isControlShown - класс не применяется -->
    <app-image
      :id="moment.id"
      :key="moment.id + isControlShown"
      :class="{ [$style.posterImage]: true, [$style.posterImageSmall]: !isControlShown }"
      :src="moment.contentPoster"
      :alt="moment.contentTitle"
      :width="isControlShown ? 106 : 50"
    />
    <div :class="{ [$style.posterControlWrapper]: true, [$style.posterControlWrapperSmall]: !isControlShown }">
      <div :class="$style.posterControl">
        <div :class="$style.posterHeader">
          <p :class="$style.posterText">{{ moment.contentTitle }}</p>
          <p :class="$style.posterHeaderTitle">{{ getTitle(moment) }}</p>
        </div>

        <Transition>
          <div v-show="isControlShown" :class="$style.posterButtons">
            <PlayButton
              :class-name="$style.posterButton"
              :content-type="moment.contentType"
              :content-id="moment.contentId"
              :should-disable-content-when-unavailable="false"
              :moment="moment"
              :active-class="$style.active"
              :extra-props="extraProps"
              variation="primary"
              @active="$emit('active')"
              @on-play-content="onPlayContent"
              @close-modal="onCloseModal"
            />
            <NavigatableItem
              :class="$style.posterButton"
              :tag="AppButton"
              :extra-props="extraProps"
              :active-class="$style.active"
              :text="
                $t('pages.moments.poster.about') + ' ' + $t(`pages.moments.poster.${moment.contentType || 'film'}`)
              "
              :on-click="() => onClickAbout(moment)"
              @active="$emit('active')"
            />
          </div>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script>
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import { useContentStore, useMediaContentActions } from '@SMART/index';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import PlayButton from '@/pages/media-card/components/PlayButton.vue';

export default {
  components: {
    NavigatableItem,
    AppImage,
    PlayButton,
  },
  props: {
    moment: Object,
    isControlShown: Boolean,
    extraProps: Object,
  },
  mounted() {
    this.$emit('mounted');
  },
  setup(props, { emit }) {
    const { openContentPage } = useMediaContentActions();
    const contentStore = useContentStore();

    const getTitle = (item) => [item.contentYear, item.genres?.[0]?.title || ''].filter((x) => !!x).join(', ');

    const onPlayContent = () => {
      contentStore.setCurrentLinkedKinom(props.moment);
    };

    const onClickAbout = (moment) => {
      emit('navigate');

      const { contentId, contentType, contentTitle, title, seasonNumber, episodeNumber, id } = moment;

      return openContentPage({
        contentType,
        id: contentId,
        title: contentTitle,
        kinomTitle: title,
        kinomId: id,
        seasonNumber,
        episodeNumber,
      });
    };

    const onCloseModal = () => {
      SpatialNavigation.setFocus('PLAY_BUTTON');
    };

    return {
      getTitle,
      onClickAbout,
      onCloseModal,
      onPlayContent,
    };
  },
  data: () => ({ AppButton }),
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.poster {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: auto;
  margin-bottom: adjustPx(60px);

  &Small {
    bottom: adjustPx(32px);
    left: adjustPx(161px);
    width: max-content;
  }

  &Image {
    margin-right: adjustPx(16px);
    width: adjustPx(160px);
    max-width: adjustPx(160px);
    height: adjustPx(240px);
    border-radius: adjustPx(20px);
    outline: none;
    scroll-behavior: smooth;
  }

  &ImageSmall {
    width: adjustPx(76px);
    max-width: adjustPx(76px);
    height: adjustPx(115px);
  }

  &Button {
    margin-right: adjustPx(24px) !important;
    padding: 0 adjustPx(50px);
    height: adjustPx(96px);
    border: none !important;
    border-radius: adjustPx(24px);
    background-color: var(--color-bg-button-secondary);
    @include f-subtitle;
  }

  &Button:hover {
    background: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
  }

  &Text {
    @include f-subtitle-1;
  }

  &ControlWrapper {
    margin-left: adjustPx(32px);
    height: adjustPx(240px);
  }

  &ControlWrapperSmall {
    height: adjustPx(115px);
  }

  &Control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  &Header {
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--spacing-spacing-none, 0px);
  }

  &Buttons {
    display: flex;
  }

  &HeaderTitle {
    margin-top: adjustPx(4px);
    color: var(--color-notheme-text-secondary);
    @include f-body-3;
  }
}

.active {
  background: var(--color-bg-accent) !important;
  color: var(--color-notheme-text-accent) !important;
}
</style>
