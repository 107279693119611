export default function mergeHeaders(...sources: Headers[]) {
  const result = new Headers();

  for (const source of sources) {
    source.forEach((value, key) => {
      result.set(key, value);
    });
  }

  return result;
}
