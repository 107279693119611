<template>
  <div ref="el" class="page-container" :class="$style.page">
    <section :class="$style.search">
      <span ref="anchorRef" />
      <div
        :class="{
          [$style.keyboardWrapper]: true,
          [$style.keyboardWrapperHidden]: isKeyboardHidden,
        }"
      >
        <VirtualKeyboard
          ref="keyboardRef"
          :input="input"
          :keyboards="searchKeyboard"
          :placeholder="inputPlaceholder"
          :upper-case="isKeyboardUppercase"
          :symbols-upper-case="true"
          :debounce-ms="150"
          input-type="text"
          input-variant="secondary"
          @vue:mounted="onVNodeMounted"
          @active="onActivateKeyboard"
          @voice:recording:start="onVoiceRecordingStart"
          @voice:recording:stop="onVoiceRecordingStop"
          @update:input="input = $event"
        />

        <section v-if="isSearchContentShown" v-show="!isKeyboardHidden" ref="historyRef" :class="$style.history">
          <NavigatableItem
            v-for="(item, index) in history"
            :key="item.createdAt"
            :prop-parent-focus-key="FocusKeys.VIRTUAL_KEYBOARD"
            :tag="AppButton"
            variation="smart-button-secondary"
            :class="$style.historyButton"
            :active-class="$style.active"
            :text="item.query"
            :text-class-name="$style.text"
            @active="onActivateHistory"
            @click="onHistorySelect(item.query, index)"
          />
        </section>
      </div>
    </section>

    <search-content-page
      ref="searchContentPage"
      :input="input"
      :history="history"
      :is-keyboard-hidden="isKeyboardHidden"
      :is-skeleton-shown="shouldShowSkeleton"
      :is-input-empty="isInputEmpty"
      @update:is-search-active="(val) => (isSearchActive = val)"
      @update:is-input-empty="(val) => (isInputEmpty = val)"
      @history:fetch="onHistoryFetch"
      @keyboard:hide="toggleKeyboardHidden(true)"
    />
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { useSearchPageAnalytics } from '@package/sdk/src/analytics';
import { debounce, DisposableStore } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  AlertMessageTypes,
  alertService,
  analyticService,
  customEventsService,
  FocusKeys,
  RouterPage,
  searchService,
  translate,
  useSessionVariables,
} from '@SMART/index';
import {
  computed,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  provide,
  ref,
  watch,
} from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import SearchContentPage from '@/components/search/SearchContentPage.vue';
import { getSearchKeyboard } from '@/components/virtual-keyboard/keyboards';
import VirtualKeyboard from '@/components/virtual-keyboard/VirtualKeyboard.vue';

export default {
  components: {
    AppButton,
    NavigatableItem,
    SearchContentPage,
    VirtualKeyboard,
  },
  name: RouterPage.SearchPage,
  setup() {
    const anchorRef = ref(null);

    const disposableStore = new DisposableStore();
    const searchPageAnalytics = useSearchPageAnalytics(analyticService.sender);
    const { isAuth } = useSessionVariables();

    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: FocusKeys.SEARCH_PAGE,
      saveLastFocusedChild: true,
      hasGlobalAccess: true,
    });
    provide('parentFocusKey', focusKey.value);

    const { isVNodeMounted: isSearchContentShown, onVNodeMounted } = useVNodeMounted({
      withTimeout: true,
      timeout: 1000,
    });

    const input = ref('');
    const inputPlaceholder = ref(translate('pages.search.placeholder'));
    const shouldUpdateContent = ref(true);
    const isSearchActive = ref(true);

    const shouldShowSkeleton = computed(() => isSearchActive.value);
    const isInputEmpty = ref(true);

    const search = debounce(() => {
      shouldUpdateContent.value = true;
    }, ConstantsConfigInstanceSmartTV.getProperty('debounceTimeoutModernMs'));

    watch(input, async () => {
      if (!input.value?.length) {
        keyboardRef.value.setUpperCase(true);
        isSearchActive.value = false;
        isInputEmpty.value = true;
        return;
      }

      keyboardRef.value.setUpperCase(false);
      searchPageAnalytics.onSearchRequestEntering(input.value);

      isSearchActive.value = true;
      isInputEmpty.value = false;

      search();
    });

    // Keyboard
    const keyboardRef = ref(null);
    const isKeyboardHidden = ref(false);
    const isKeyboardUppercase = ref(true);

    const onClear = (request) => {
      searchPageAnalytics.onClickSearchRequestCancel(request);
    };

    const searchKeyboard = getSearchKeyboard({ input, keyboardRef, onClear });

    const toggleKeyboardHidden = (value) => {
      isKeyboardHidden.value = value;
    };

    function onActivateKeyboard() {
      inputPlaceholder.value =
        SpatialNavigation.getCurrentFocusKey() === FocusKeys.KEYBOARD_KEY(FocusKeys.VOICE_SEARCH)
          ? translate('pages.search.placeholderVoice')
          : translate('pages.search.placeholder');

      if (!isKeyboardHidden.value) {
        return;
      }

      toggleKeyboardHidden(false);
      customEventsService.setWheelAction({ dec: 'up', inc: 'down' });

      if (input.value) {
        SpatialNavigation.setFocus(FocusKeys.KEYBOARD_KEY(FocusKeys.CLEAR));
      }
    }

    const onVoiceRecordingStart = () => {
      input.value = '';
      inputPlaceholder.value = translate('pages.search.placeholderSpeak');
      searchPageAnalytics.onClickSearchVoiceMode();
    };

    const onVoiceRecordingStop = (value) => {
      inputPlaceholder.value = translate('pages.search.placeholderVoice');

      SpatialNavigation.setFocus(FocusKeys.KEYBOARD_KEY('А'));

      if (value) {
        searchPageAnalytics.onAutoSearchVoiceSuccess(value);
        return;
      }

      searchPageAnalytics.onAutoSearchVoiceError();

      alertService.addAlert({
        message: translate('pages.search.notRecognized'),
        type: AlertMessageTypes.ErrorVoiceSearch,
      });
    };

    const history = ref([]);
    const historyRef = ref(null);

    const onActivateHistory = () => {
      toggleKeyboardHidden(false);
    };

    const onHistorySelect = (query, index) => {
      input.value = query;
      searchPageAnalytics.onClickSearchHistoryItem(query, index);
    };

    const onHistoryFetch = async () => {
      if (!isAuth.value) {
        return;
      }

      history.value = await searchService.getSearchHistory({ page: 1, perPage: 5 });
    };

    const searchContentPage = ref(null);

    watch(
      () => history.value,
      () => searchPageAnalytics.onAutoSearchHistoryDisplayed(),
      { once: true },
    );

    onActivated(() => {
      SpatialNavigation.setFocus(FocusKeys.KEYBOARD_KEY('А'));
      searchContentPage.value?.restoreFocusKey(FocusKeys.SEARCH_PAGE);
    });

    onDeactivated(() => {
      customEventsService.setWheelAction({ dec: 'up', inc: 'down' });
    });

    onMounted(async () => {
      focusSelf();

      searchPageAnalytics.onShowSearchPage();

      return onHistoryFetch();
    });

    onBeforeUnmount(() => {
      disposableStore.dispose();
    });

    return {
      el,
      anchorRef,
      keyboardRef,
      historyRef,
      searchContentPage,
      input,
      inputPlaceholder,
      shouldUpdateContent,
      isSearchActive,
      shouldShowSkeleton,
      isInputEmpty,
      isKeyboardHidden,
      isKeyboardUppercase,
      searchKeyboard,
      toggleKeyboardHidden,
      onActivateKeyboard,
      onVoiceRecordingStart,
      onVoiceRecordingStop,
      history,
      onActivateHistory,
      onHistorySelect,
      onHistoryFetch,
      FocusKeys,
      AppButton,
      onVNodeMounted,
      isSearchContentShown,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.page {
  .title {
    @include f-subtitle-2();
  }
}

.keyboardWrapper {
  display: flex;
  flex-flow: row;

  &Hidden {
    position: relative;
    top: adjust.adjustPx(-600px);
  }
}

.search {
  display: flex;
  flex-flow: row;
  margin-top: adjust.adjustPx(20px);
  margin-bottom: adjust.adjustPx(40px);
  margin-left: adjust.adjustPx(45px);
}

.history {
  display: flex;
  flex-flow: column;

  margin-top: adjust.adjustPx(113.5px);
  margin-left: adjust.adjustPx(20px);
  overflow: hidden;

  &Button {
    justify-content: flex-start;
    margin-bottom: adjust.adjustPx(8px);
    width: adjust.adjustPx(521px);
    min-height: adjust.adjustPx(68px);
    max-height: adjust.adjustPx(68px);
    border: none;
    border-radius: adjust.adjustPx(16px);
    background-color: transparent;
  }

  .active {
    background-color: var(--color-bg-accent);
  }

  .text {
    display: block;
    width: 100%;
    text-align: start;

    @include f-body;
  }
}
</style>
