/**
 * @vitest-environment jsdom
 */

/**
 @ @author Teodor_Dre <swen295@gmail.com>
 *
 * @description return first element
 *
 * @param {T[]} array
 * @return {T | undefined}
 */
export function getFirstElement<T>(array: T[]): T | undefined {
  if (!array) {
    return undefined;
  }

  if (array.length > 0) {
    return array[0];
  }

  return undefined;
}

export function findRight<T>(array: T[], predicate: (value: T) => boolean): T | undefined {
  let index = array.length;

  while (index > 0) {
    index = index - 1;

    const item = array[index];

    const predicateResult = Reflect.apply(predicate, undefined, [item]);

    if (predicateResult) {
      return item;
    }
  }

  return undefined;
}

/**
 * @author Teodor_Dre <swen295@gmail.com>
 *
 * @description
 *  Return random element from array
 *
 * @returns {boolean}
 */
export function isEmptyArray(array: unknown): boolean {
  return (array as unknown[])?.length === 0;
}

/**
 * @author Teodor_Dre <swen295@gmail.com>
 *
 * @description
 *  Return last element from array
 *
 * @param {T[]} array - array with elements;
 *
 * @returns T[]
 */
export function getLastElement<T>(array: readonly (T | undefined | null)[]): T | undefined | null {
  const length = array?.length;

  if (!length) {
    return;
  }

  return array[array.length - 1];
}

export function indexOutOfRange(index: number): boolean {
  return index === -1;
}

export function coalesce<T>(array: readonly (T | undefined | null)[]): T[] {
  return array.filter((e) => Boolean(e)) as T[];
}

export function getRandomArrayItem<T>(array: T[]): T | undefined {
  if (!array.length) {
    return undefined;
  }

  return array[Math.floor(Math.random() * array.length)];
}
