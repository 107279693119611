<template>
  <section ref="el" role="tablist" :class="$style.header">
    <div :class="$style.content">
      <div :class="$style.contentContainer">
        <UITabs :class="$style.tabs" :tab="contentTab">
          <template #tabs>
            <NavigatableItem
              :class="$style.tabsItem"
              :tag="UITab"
              :selected="moviesSelected"
              :active-class="$style.active"
              @click="onTabSelected(MediaContentType.Movie)"
            >
              <span :class="$style.text">{{ $t('pages.catalog.filmsTab') }}</span>
            </NavigatableItem>
            <NavigatableItem
              :class="{ [$style.tabsItem]: true, [$style.showDivider]: contentTab === 2 }"
              :tag="UITab"
              :selected="serialsSelected"
              :active-class="$style.active"
              @click="onTabSelected(MediaContentType.Serial)"
            >
              <span :class="$style.text">{{ $t('pages.catalog.serialsTab') }}</span>
            </NavigatableItem>
            <NavigatableItem
              :class="{ [$style.tabsItem]: true, [$style.showDivider]: contentTab === 0 }"
              :tag="UITab"
              :selected="allSelected"
              :active-class="$style.active"
              @click="onTabSelected(MediaContentType.All)"
            >
              <span :class="$style.text">{{ $t('pages.catalog.allTab') }}</span>
            </NavigatableItem>
          </template>
        </UITabs>

        <NavigatableItem
          :focus-key="FocusKeys.CATALOG_FILTERS_BUTTON"
          :tag="AppButton"
          :disabled="disabled"
          :active-class="$style.active"
          navigation-key="filters"
          :text="$t('pages.catalog.filters')"
          @click="onFiltersOpen"
        >
          <template #icon>
            <IconFilters :class="$style.icon" />
          </template>
        </NavigatableItem>
      </div>

      <NavigatableItem
        :focus-key="FocusKeys.CATALOG_SHUFFLE_BUTTON"
        :tag="AppButton"
        :disabled="disabled"
        :class="$style.shuffle"
        :active-class="$style.active"
        navigation-key="shuffle"
        :text="$t('pages.catalog.contentShuffle')"
        @click="onSelectShuffle"
      >
        <template #icon>
          <IconShuffle
            :class="{
              [$style.icon]: true,
              [$style.iconDisabled]: disabled,
            }"
          />
        </template>
      </NavigatableItem>
    </div>

    <div v-if="filterItems.length" :class="$style.filters">
      <NavigatableItem
        :tag="AppButton"
        :disabled="disabled"
        :active-class="$style.active"
        :class="{ [$style.resetFilters]: true, [$style.scrolled]: isFiltersScrolled }"
        :text="$t('pages.catalog.resetFilters')"
        @click="resetFilters"
      >
        <template #icon>
          <IconDeleteOutlined
            :class="{
              [$style.icon]: true,
              [$style.iconDisabled]: disabled,
            }"
          />
        </template>
      </NavigatableItem>

      <div :class="$style.filtersScrollWrapper">
        <ScrollViewport ref="filtersScroll" :class="$style.filterItems" orientation="horizontal" role="list">
          <NavigatableItem
            v-for="(filter, index) in filterItems"
            :key="`filter-${index}`"
            :focus-key="FocusKeys.CATALOG_FILTERS_SLIDER_ITEM(index)"
            :tag="AppButton"
            :disabled="disabled"
            :class="$style.filterItem"
            :active-class="$style.filterItemActive"
            :text="filter.title"
            @active="onFilterSelect"
            @click="onFilterToggle(filter)"
          >
            <template #icon-right>
              <IconClose :class="$style.filterItemIcon" />
            </template>
          </NavigatableItem>
        </ScrollViewport>
      </div>
    </div>

    <FiltersModal
      v-if="isFiltersModalVisible"
      :filters="filters"
      @filter:select="onFilterToggle"
      @close="onFilterModalClose"
    />

    <ShuffleModal v-if="isShuffleModalVisible" :content-type="contentType" @close="isShuffleModalVisible = false" />
  </section>
</template>

<script>
import { useCatalogPageAnalytics } from '@package/sdk/src/analytics';
import { MediaContentType } from '@package/sdk/src/api';
import useListNavigationActions from '@package/smarttv-base/src/navigation/use-list-navigation-actions';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconClose from '@SMART/assets/icons/24x24/close.svg';
import IconDeleteOutlined from '@SMART/assets/icons/33x33/delete-outlined.svg';
import IconFilters from '@SMART/assets/icons/40x40/filters.svg';
import IconShuffle from '@SMART/assets/icons/40x40/shuffle.svg';
import { adjustPx, analyticService, FocusKeys, scrollToElement, storeToRefs, useContentStore } from '@SMART/index';
import { computed, nextTick, onMounted, provide, reactive, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import UITab from '@/components/tabs/UITab.vue';
import UITabs from '@/components/tabs/UITabs.vue';
import useReactiveSet from '@/utils/use-reactive-set';

import FiltersModal from './FiltersModal.vue';
import ShuffleModal from './ShuffleModal.vue';

export default {
  props: {
    contentTypeProp: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppButton,
    NavigatableItem,
    ScrollViewport,
    UITab,
    UITabs,
    IconClose,
    IconDeleteOutlined,
    IconFilters,
    IconShuffle,
    FiltersModal,
    ShuffleModal,
  },
  setup(props, { emit, root: { $route: route } }) {
    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: FocusKeys.CONTENT_SELECT,
    });
    provide('parentFocusKey', focusKey.value);

    const filterList = useListNavigationActions(FocusKeys.CATALOG_FILTERS_SLIDER_ITEM);

    // Filters
    const contentStore = useContentStore();
    const catalogPageAnalytics = useCatalogPageAnalytics(analyticService.sender);

    const { genres, countries, periods } = storeToRefs(contentStore);

    const selectedGenresFilters = useReactiveSet();
    const selectedCountriesFilters = useReactiveSet();
    const selectedPeriodsFilters = useReactiveSet();

    const filtersScroll = ref(null);
    const isFiltersScrolled = ref(false);

    const onFilterSelect = (filter) => {
      const offset = filter?.offsetLeft || 0;

      scrollToElement(filtersScroll.value?.$el, { left: offset - adjustPx(60) });
      isFiltersScrolled.value = Boolean(filtersScroll.value?.$el.scrollLeft);
    };

    const isFiltersModalVisible = ref(false);

    const initFilter = function (array) {
      return array.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
    };

    const filters = reactive({
      genres: initFilter(genres.value),
      countries: initFilter(countries.value),
      periods: initFilter(periods.value),
    });

    const selectedFilters = computed(() => {
      return {
        genres: Array.from(selectedGenresFilters.value.values()),
        countries: Array.from(selectedCountriesFilters.value.values()),
        periods: Array.from(selectedPeriodsFilters.value.values()),
      };
    });

    const filterItems = computed(() => {
      return [
        ...Array.from(selectedGenresFilters.value.values()),
        ...Array.from(selectedCountriesFilters.value.values()),
        ...Array.from(selectedPeriodsFilters.value.values()),
      ];
    });

    const onFilterToggle = (item) => {
      let target = null;
      switch (item.filterType) {
        case 'genre':
          target = filters.genres.find((f) => f.id === item.id);

          if (target) {
            if (selectedGenresFilters.value.has(item)) {
              selectedGenresFilters.value.delete(item);
              target.selected = false;
            } else {
              selectedGenresFilters.value.add(item);
              target.selected = true;
            }
          }
          break;
        case 'country':
          target = filters.countries.find((f) => f.code === item.code);

          if (target) {
            if (selectedCountriesFilters.value.has(item)) {
              selectedCountriesFilters.value.delete(item);
              target.selected = false;
            } else {
              selectedCountriesFilters.value.add(item);
              target.selected = true;
            }
          }
          break;
        case 'year':
          target = filters.periods.find((f) => f.id === item.id);

          if (target) {
            if (selectedPeriodsFilters.value.has(item)) {
              selectedPeriodsFilters.value.delete(item);
              target.selected = false;
            } else {
              selectedPeriodsFilters.value.add(item);
              target.selected = true;
            }
          }
          break;
      }

      if (filterList.isFocused()) {
        nextTick(() => {
          filterList.handleDelete(FocusKeys.CONTENT_SELECT);
        });
      }

      emit('update:filters', selectedFilters.value);
    };

    const resetFilters = async () => {
      selectedGenresFilters.value.clear();
      selectedCountriesFilters.value.clear();
      selectedPeriodsFilters.value.clear();

      filters.genres = initFilter(genres.value);
      filters.countries = initFilter(countries.value);
      filters.periods = initFilter(periods.value);

      focusSelf();

      emit('update:filters', selectedFilters.value);
    };

    const onFilterModalClose = async () => {
      isFiltersModalVisible.value = false;
      await nextTick();
      if (SpatialNavigation.doesFocusableExist(FocusKeys.CATALOG_RESET_FILTERS_EMPTY_CONTENT)) {
        SpatialNavigation.setFocus(FocusKeys.CATALOG_RESET_FILTERS_EMPTY_CONTENT);
      } else {
        SpatialNavigation.setFocus(FocusKeys.CATALOG_FILTERS_BUTTON);
      }
    };

    const contentTab = ref(0);

    const contentType = computed({
      get: () => props.contentTypeProp,
      set: (value) => value && emit('update:type', value),
    });

    const moviesSelected = computed(() => contentType.value === MediaContentType.Movie);
    const serialsSelected = computed(() => contentType.value === MediaContentType.Serial);
    const allSelected = computed(() => contentType.value === MediaContentType.All);

    const onTabSelected = (type) => {
      contentType.value = type;

      switch (type) {
        case MediaContentType.Movie:
          contentTab.value = 0;
          catalogPageAnalytics.onClickCatalogMovies();
          break;
        case MediaContentType.Serial:
          contentTab.value = 1;
          catalogPageAnalytics.onClickCatalogSeries();
          break;
        case MediaContentType.All:
          contentTab.value = 2;
          break;
      }
    };

    const isShuffleModalVisible = ref(false);

    const onSelectShuffle = () => {
      isShuffleModalVisible.value = true;
      isFiltersModalVisible.value = false;
    };

    const onFiltersOpen = () => {
      isFiltersModalVisible.value = true;
      isShuffleModalVisible.value = false;
    };

    onMounted(() => {
      if (route.query.contentType && typeof route.query.contentType === 'string') {
        onTabSelected(route.query.contentType);
      }

      const queryGenre = route.query.genre;
      const queryCountry = route.query.country;

      const genreArray = Array.isArray(queryGenre) ? queryGenre : [queryGenre];
      const countryArray = Array.isArray(queryCountry) ? queryCountry : [queryCountry];

      filters.genres.forEach((genre) => {
        if (genreArray.includes(genre.slug ?? '')) {
          genre.selected = true;
          selectedGenresFilters.value.add(genre);
        }
      });

      filters.countries.forEach((country) => {
        if (countryArray.includes(country.slug ?? '')) {
          country.selected = true;
          selectedCountriesFilters.value.add(country);
        }
      });

      emit('update:filters', selectedFilters.value);
    });

    return {
      el,
      focusKey,
      filterItems,
      resetFilters,
      isFiltersModalVisible,
      isShuffleModalVisible,
      onFilterSelect,
      onFilterToggle,
      onFilterModalClose,
      onTabSelected,
      onSelectShuffle,
      onFiltersOpen,
      moviesSelected,
      serialsSelected,
      allSelected,
      contentTab,
      contentType,
      FocusKeys,
      AppButton,
      UITab,
      MediaContentType,
      filters,
      selectedGenresFilters,
      isFiltersScrolled,
      filtersScroll,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/layers';

.header {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .text {
    width: inherit;
    @include smartTvFonts.SmartTvLabel-2();
  }
}

.content {
  display: flex;
  margin-bottom: adjust.adjustPx(32px);
}

.contentContainer {
  display: flex;
  flex-grow: 1;
}

.tabs {
  margin-right: adjust.adjustPx(24px);
}

.tabsItem {
  width: adjust.adjustPx(174px) !important;
  height: 100%;

  &:not(:first-child) {
    margin-left: adjust.adjustPx(18px);

    &.showDivider::before {
      position: absolute;
      top: 20%;
      left: adjust.adjustPx(-10px);
      display: block;
      width: 2px;
      height: 60%;
      background-color: var(--color-stroke-primary);
      content: '';
    }
  }
}

.filters {
  display: flex;
}

.resetFilters {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-content);
  box-shadow: adjust.adjustPx(-400px) 0px 0px adjust.adjustPx(30px) var(--color-bg-primary);
  outline: solid adjust.adjustPx(10px) var(--color-bg-primary);

  &.scrolled::before {
    position: absolute;
    top: adjust.adjustPx(-2px) !important;
    right: adjust.adjustPx(-180px);
    z-index: map-get($map: $layers, $key: --z-index-heading);
    width: adjust.adjustPx(180px);
    height: 110%;
    content: '';
    background-image: url('@SMART/assets/images/shadow-catalog-left.webp');
    background-size: 100% 100%;
  }
}

.filtersScrollWrapper {
  width: calc(100% - adjust.adjustPx(410px) + adjust.adjustPx(20px) + adjust.adjustPx(60px));
}

.filterItems {
  position: relative;
  display: -webkit-box;
  margin-left: adjust.adjustPx(20px);
}

@mixin iconActive {
  border-radius: adjust.adjustPx(3px);

  background-color: var(--color-bg-accent);

  color: var(--color-notheme-text-accent);
  transition-duration: 0.3s;
  box-shadow: 0px 0px 0px adjust.adjustPx(16px) var(--color-bg-accent);
}

.filterItem {
  margin-right: adjust.adjustPx(20px);
  background-color: var(--color-notheme-bg-secondary-80) !important;
  color: var(--color-text-primary) !important;
  transition-duration: 0.3s;

  &:hover {
    svg {
      @include iconActive();
    }
  }

  &:last-child {
    margin-right: adjust.adjustPx(60px);
  }
}

.filterItemActive {
  svg {
    @include iconActive();
  }
}

.filterItemIcon {
  margin-left: adjust.adjustPx(24px);
  width: 24px;
  height: 24px;
}

.shuffle {
  display: flex;
  align-items: center;
  margin-left: adjust.adjustPx(16px);
}

.icon {
  margin-right: adjust.adjustPx(10px);
  width: adjust.adjustPx(33px);
  height: adjust.adjustPx(33px);
}

.iconDisabled {
  color: var(--color-state-disabled);
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}
</style>
