import { ProcessingMapper } from '@package/sdk/src/api/base/processing-mapper';

import type { ApiFeatureToggleItem } from './feature-toggle';
import type { FeatureToggleItem } from './types/feature';

export class FeatureToggleItemMapper {
  static mapMany(items: ApiFeatureToggleItem[]): FeatureToggleItem[] {
    return ProcessingMapper.process(items.map(FeatureToggleItemMapper.map));
  }

  static map({ name, status, variant }: ApiFeatureToggleItem): FeatureToggleItem {
    return ProcessingMapper.process({
      name,
      status,
      variant: variant ? { name: variant.name, type: variant.type, value: variant.value } : undefined,
    }) as FeatureToggleItem;
  }
}
