<template>
  <header :class="$style.header">
    <media-card-top-header-logo :title="title" :logo="logo" @vue:mounted="onVNodeMounted" />
    <template v-if="isVNodeMounted">
      <p v-if="restOfTime">
        {{
          $t('media.restOfTime', {
            remain: pluralize(restOfTime, 'pluralizedUnit.remain'),
            restOfTime,
            pluralizedText: pluralize(restOfTime, 'durationUnit.minute'),
          })
        }}
      </p>
      <p v-if="percent" :class="$style.approach">
        <TargetIcon :class="$style.icon" />
        {{ $t('media.relevant', { percent }) }}
      </p>

      <p :class="$style.subtitle">
        {{ subtitle }}
        <span v-if="limit" :class="$style.limit">{{ limit }}</span>
      </p>

      <p :class="$style.subtitleGenres">
        {{ subtitleGenres }}
      </p>

      <p v-if="description" :class="$style.description">{{ description }}</p>
    </template>
  </header>
</template>

<script>
import TargetIcon from '@SMART/assets/icons/other/target.svg';
import { pluralize } from '@SMART/index';
import { onMounted, ref } from '@vue/composition-api';

import MediaCardTopHeaderLogo from '@/pages/media-card/components/MediaCardTopHeaderLogo.vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleGenres: {
      type: String,
      default: '',
    },
    limit: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    percent: {
      type: [String, Number],
      default: '',
    },
    restOfTime: {
      type: Number,
      default: 0,
    },
    logo: {
      type: String,
      default: '',
    },
  },
  components: {
    MediaCardTopHeaderLogo,
    TargetIcon,
  },
  setup(props, { emit }) {
    const isVNodeMounted = ref(false);

    const onVNodeMounted = () => {
      isVNodeMounted.value = true;
    };

    onMounted(() => {
      setTimeout(() => {
        onVNodeMounted();
      }, 0);
      emit('vue:mounted');
    });

    return {
      isVNodeMounted,
      onVNodeMounted,
      pluralize,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.header {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-wrapper);
  min-height: adjust.adjustPx(135px);
}

.approach {
  display: flex;
  align-items: center;
  margin-top: adjust.adjustPx(4px);
  margin-bottom: adjust.adjustPx(32px);
  margin-right: adjust.adjustPx(16px);
  max-width: adjust.adjustPx(1112px);
  color: var(--color-text-accent);
  text-align: center;

  @include smartTvFonts.SmartTvBody-2();

  .icon {
    margin-right: adjust.adjustPx(12px);
    width: adjust.adjustPx(32px);
    height: adjust.adjustPx(44px);
  }
}

.subtitle {
  display: flex;
  align-items: center;
  margin-top: adjust.adjustPx(48px);
  margin-bottom: adjust.adjustPx(2px);
  max-width: adjust.adjustPx(1112px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-2();
}

.limit {
  display: inline-flex;
  align-items: center;
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-2();

  &::before {
    content: '\00a0·\00a0';
  }
}

.subtitleGenres {
  display: flex;
  align-items: center;
  margin-top: adjust.adjustPx(2px);
  margin-bottom: adjust.adjustPx(2px);
  max-width: adjust.adjustPx(1112px);
  height: fit-content;
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-2();
}

.description {
  display: -webkit-box;
  margin-top: adjust.adjustPx(32px);
  max-width: adjust.adjustPx(800px);
  max-height: adjust.adjustPx(156px);
  overflow: hidden;
  color: var(--color-text-primary);
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  @include smartTvFonts.SmartTvBody-1();
}
</style>
