<template>
  <UIModal modal-target="modal">
    <section ref="el" :class="$style.modal">
      <my-channel-modal-images v-if="isVNodeMounted" />
      <my-channel-modal-actions @finish="onFinish" @vue:mounted="onActionsMounted" />
    </section>
  </UIModal>
</template>

<script>
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { customEventsService, FocusKeys } from '@SMART/index';
import { onMounted, provide } from '@vue/composition-api';

import UIModal from '@/components/modal/UIModal.vue';
import MyChannelModalActions from '@/components/my-channel-modal/MyChannelModalActions.vue';
import MyChannelModalImages from '@/components/my-channel-modal/MyChannelModalImages.vue';

export default {
  components: {
    UIModal,
    MyChannelModalActions,
    MyChannelModalImages,
  },
  setup(_, { emit }) {
    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.MY_CHANNEL_MODAL,
      isFocusBoundary: true,
      saveLastFocusedChild: true,
      focusBoundaryDirections: ['right'],
    });
    provide('parentFocusKey', focusKey.value);

    const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true, timeout: 750 });

    const onActionsMounted = () => {
      onVNodeMounted();
      SpatialNavigation.setFocus(FocusKeys.MY_CHANNEL_BUTTON(0));
    };

    const onFinish = () => {
      emit('finish');
    };

    onMounted(() => {
      customEventsService.setOnPressBackCallback(onFinish, true);
    });

    return {
      el,
      isVNodeMounted,
      onVNodeMounted,
      onActionsMounted,
      onFinish,
      FocusKeys,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: adjustPx(206px) adjustPx(48px) adjustPx(206px) 0px;
  background: var(--color-bg-primary);
}
</style>
