<template>
  <app-icon :width="100" :height="100" :class="$style.icon">
    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
      <circle :class="$style.circleLeft" stroke-width="1.5" r="15" cy="50" cx="20" fill="currentColor" />
      <circle :class="$style.circleRight" stroke-width="1.5" r="15" cy="50" cx="30" fill="currentColor" />
    </svg>
  </app-icon>
</template>

<script>
import AppIcon from './AppIcon.vue';

export default {
  components: {
    AppIcon,
  },
  props: {
    isLightMode: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" module>
@import '@/styles/colors';

.icon {
  color: var(--color-text-accent) !important;
}

.iconLight {
  opacity: 0.2;
  color: var(--color-state-disabled) !important;
}

.circleRight {
  transform: translateX(50%);
  animation: preloader-animation_right linear 3s infinite;
  animation-delay: 0s;
}

.circleLeft {
  animation: preloader-animation_left linear 3s infinite;
  animation-delay: 0s;
}

@keyframes preloader-animation_left {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes preloader-animation_right {
  0% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(50%);
  }
}
</style>
