<template>
  <div ref="el">
    <BackButton :on-blur="onBlurBackButton" />

    <div v-if="content" :class="$style.wrapper">
      <div :class="$style.contentWrapper">
        <ScrollViewport tag="ul" :class="$style.list" :y="offsetTopPx" role="list" @vue:mounted="onVNodeMounted">
          <li :class="$style.header">
            <MediaCardTopHeader
              :title="content.title"
              :logo="content.logo"
              :subtitle="contentSubtitle"
              :limit="limit"
              :subtitleGenres="subtitleGenres"
              :percent="content.contentMatchPercent"
              :description="content.tagline"
              @vue:mounted="onMediaCardTopHeaderMounted"
            />
          </li>
          <li>
            <div :class="$style.controls">
              <PlayButton
                should-disable-content-when-unavailable
                with-access-kind
                :target="RouterPage.MediaCardPage"
                :focus-key="FocusKeys.MEDIA_CARD_PLAY_BUTTON"
                @active="onScroll(0)"
              />

              <NavigatableItem
                :class="{
                  [$style.save]: true,
                  [$style.selected]: isAddedToCollection,
                }"
                is-focus-boundary
                :focus-boundary-directions="['right']"
                :active-class="$style.saveActive"
                :tag="AppButton"
                :focus-key="FocusKeys.BOOKMARK_BUTTON"
                @click="
                  isAddedToCollection
                    ? onRemoveCollectionItem(content.id || '')
                    : onSaveCollectionItem(content.id || '')
                "
                @active="onSaveActive(true)"
                @inactive="onSaveActive(false)"
              >
                <template #icon>
                  <BookmarkFillIcon v-if="isAddedToCollection" :class="$style.bookMarkSavedIcon" />
                  <BookmarkIcon v-else />
                </template>
              </NavigatableItem>
            </div>
            <PlayButtonSubtitle :class="$style.playButtonSubtitle" />
          </li>
          <li
            v-if="content.seasons && content.seasons.length && isMediaCardTopHeaderMounted"
            :class="{ [$style.moments]: true, [$style.bgPrimary]: isSliderActive }"
          >
            <MediaCardSeasons
              type="moment"
              :content-watching-item="content.watchingItem"
              :items="content.seasons"
              :title="$t('pages.mediaCard.seasons')"
              :seasonIndex="Number($route.query.seasonIndex)"
              @activated="onScroll"
              @selected="onPlayEpisode"
            />
          </li>

          <li
            v-if="moments.length && isMediaCardTopHeaderMounted"
            :class="{ [$style.moments]: true, [$style.bgPrimary]: isSliderActive }"
          >
            <MediaCardSlider
              type="moment"
              :items="moments"
              :title="$t('pages.mediaCard.moments')"
              @activated="onScroll"
              @selected="onMomentSelect"
              @scrolled="onKinomScroll"
            />
          </li>

          <li :class="{ [$style.section]: true, [$style.details]: true, [$style.bgPrimary]: isSliderActive }">
            <MediaCardDetails :content="content" @activated="(x) => onScrollDetails(x)" @selected="onDetailsSelect" />
          </li>
          <!-- todo:  waiting for API -->
          <!-- <li v-if="persons.length" :class="$style.section">
          <MediaCardSlider
            type="person"
            :items="persons"
            :title="$t('pages.mediaCard.persons')"
            @activated="onScroll"
          />
        </li> -->
          <li v-if="similar && similar.length" :class="$style.section">
            <MediaCardSlider
              type="poster"
              :class="$style.similar"
              :items="similar"
              :title="$t('pages.mediaCard.similar')"
              @activated="onScroll"
              @focused="onSimilarContentDisplayed"
              @selected="onSimilarSelect"
              @scrolled="onSimilarScroll"
            />
          </li>
          <li v-if="collections && collections.length" :class="$style.section">
            <MediaCardCollectionsSlider
              type="poster"
              :class="$style.collections"
              :items="collections"
              :title="$t(`pages.mediaCard.inCollections-${content.contentType}`)"
              @activated="onScroll"
              @selected="onSimilarSelect"
            />
          </li>
          <li
            :class="[$style.sectionLinkToTop, !(collections && collections.length) && $style.sectionLinkToTopSimilar]"
          >
            <MediaCardScrollTopButton @click="onNavToTop" @active="onScrollTopButton" />
          </li>
        </ScrollViewport>
      </div>
      <PromoSubModal v-if="shouldShowPromoSub" :images="background" @finish="finishPromoSub" />
      <PartnerSubscriptionModal v-if="isPartnerSubscriptionModalShown" @close="closePartnerSubscriptionModal" />
    </div>
  </div>
</template>

<script>
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import { AnalyticPageName, ItemPageFrom, useContentPageAnalytics, useKinomAnalytics } from '@package/sdk/src/analytics';
import { ContentAccessTypes, MediaContentType } from '@package/sdk/src/api';
import { DisposableStore, timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import BookmarkIcon from '@SMART/assets/icons/51x51/bookmark.svg';
import BookmarkFillIcon from '@SMART/assets/icons/51x51/save.svg';
import {
  AlertMessageTypes,
  alertService,
  analyticService,
  collectionService,
  FocusKeys,
  RouterPage,
  routerService,
  storeToRefs,
  translate,
  useCatalogStore,
  useContentStore,
  useMediaContentActions,
  useOfferActions,
  useSessionStore,
  useSessionVariables,
} from '@SMART/index';
import { computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, provide, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import BackButton from '@/components/back-button/BackButton.vue';
import PromoSubModal from '@/components/promo-sub-modal/PromoSubModal.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import PartnerSubscriptionModal from '@/pages/channels/components/PartnerSubscriptionModal.vue';
import MediaCardDetails from '@/pages/media-card/components/MediaCardDetails.vue';
import MediaCardSeasons from '@/pages/media-card/components/MediaCardSeasons.vue';
import MediaCardSlider from '@/pages/media-card/components/MediaCardSlider.vue';
import MediaCardTopHeader from '@/pages/media-card/components/MediaCardTopHeader.vue';
import PlayButton from '@/pages/media-card/components/PlayButton.vue';
import PlayButtonSubtitle from '@/pages/media-card/components/PlayButtonSubtitle.vue';

import MediaCardCollectionsSlider from './components/MediaCardCollectionsSlider.vue';
import MediaCardScrollTopButton from './components/MediaCardScrollTopButton.vue';

export default {
  components: {
    AppButton,
    BackButton,
    PromoSubModal,
    ScrollViewport,
    PartnerSubscriptionModal,
    MediaCardDetails,
    MediaCardSeasons,
    MediaCardSlider,
    MediaCardTopHeader,
    PlayButton,
    PlayButtonSubtitle,
    MediaCardCollectionsSlider,
    MediaCardScrollTopButton,
    BookmarkIcon,
    BookmarkFillIcon,
  },
  name: RouterPage.MediaCardPage,
  setup(_, { root: { $route: route } }) {
    const contentStore = useContentStore();
    const { content, contentSubtitle, moments, similar, limit, subtitleGenres, collections, isAboutToFinish } =
      storeToRefs(contentStore);

    const { isAvailable, isUnavailableSoon } = useMediaContentAvailability();
    const { openContentPage, openPlayerPage } = useMediaContentActions();
    const { isActiveSubscription, isPartnerSubscription } = storeToRefs(useSessionStore());
    const { openOffersPage } = useOfferActions();
    const { isInactivePartnerSubscription } = useSessionVariables();

    const catalogStore = useCatalogStore();

    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.MEDIA_CARD_PAGE,
      isFocusBoundary: true,
      hasGlobalAccess: true,
      focusBoundaryDirections: ['down', 'right'],
    });
    provide('parentFocusKey', focusKey.value);

    const disposableStore = new DisposableStore();
    const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true });
    const { isVNodeMounted: isMediaCardTopHeaderMounted, onVNodeMounted: onMediaCardTopHeaderMounted } =
      useVNodeMounted({
        withTimeout: true,
      });
    const contentPageAnalytics = useContentPageAnalytics(analyticService.sender);
    const kinomAnalytics = useKinomAnalytics(analyticService.sender);

    const offsetTopPx = ref(0);
    const isSliderActive = ref(false);
    const shouldShowPromoSub = ref(isAboutToFinish.value);
    const isPartnerSubscriptionModalShown = ref(false);
    const previousFocusKey = ref('');

    const focusSelf = () => {
      const { seasonIndex, episodeIndex } = route.query;

      if (SpatialNavigation.doesFocusableExist(FocusKeys.MEDIA_CARD_SEASONS_ITEM(Number(seasonIndex)))) {
        SpatialNavigation.setFocus(FocusKeys.MEDIA_CARD_SEASONS_ITEM(Number(seasonIndex)));

        if (SpatialNavigation.doesFocusableExist(FocusKeys.MEDIA_CARD_EPISODES_ITEM(Number(episodeIndex)))) {
          SpatialNavigation.setFocus(FocusKeys.MEDIA_CARD_EPISODES_ITEM(Number(episodeIndex)));
        }

        return;
      }

      isContentAvailable.value
        ? SpatialNavigation.setFocus(FocusKeys.MEDIA_CARD_PLAY_BUTTON)
        : SpatialNavigation.setFocus(FocusKeys.BOOKMARK_BUTTON);
    };

    const finishPromoSub = () => {
      shouldShowPromoSub.value = false;
    };

    const onScroll = (offset = 0) => {
      offsetTopPx.value = offset;
      isSliderActive.value = Boolean(offset);
    };

    const onScrollTopButton = () => {
      offsetTopPx.value = offsetTopPx.value + 100;
      isSliderActive.value = true;
    };

    const onScrollDetails = (offset = 0) => {
      offsetTopPx.value = offset;
      isSliderActive.value = true;
    };

    const onMomentSelect = ({ index, item }) => {
      kinomAnalytics.onGotoKinomPage({
        page: content.value.contentType === MediaContentType.Movie ? AnalyticPageName.Movie : AnalyticPageName.Series,
        moment: item,
        position: index,
      });

      return routerService.push({
        name: RouterPage.MomentsPage,
        params: {
          page: 1,
          size: 27,
          id: content.value.id,
          type: 'content',
          momentIndex: index,
        },
      });
    };

    const onPlayEpisode = (episode, episodeIndex, activeSeasonId) => {
      const { id: episodeId, accessKind } = episode;
      const { id } = content.value;
      const isFreeEpisode = accessKind === ContentAccessTypes.AllUsers;

      if (!isActiveSubscription.value && isPartnerSubscription.value) {
        isPartnerSubscriptionModalShown.value = true;
        return;
      }

      if (!isActiveSubscription.value && !isFreeEpisode) {
        return openOffersPage();
      }

      const isContentAvailable = isAvailable(episode) || isUnavailableSoon(episode);

      if (!isContentAvailable) {
        return;
      }

      return openPlayerPage({
        id,
        contentType: content.value.contentType,
        episodeId,
        seasonIndex: String(activeSeasonId),
        episodeIndex: String(episodeIndex),
      });
    };

    const onDetailsSelect = (options) => {
      return routerService.push({
        name: RouterPage.CatalogPage,
        query: {
          genre: options.genre,
          country: options.country,
          contentType: content.value.contentType,
        },
      });
    };

    const onSimilarSelect = (payload) => {
      const item = payload.item;
      openContentPage(
        {
          contentType: item.contentType,
          id: item.id,
          title: item.title,
          from: ItemPageFrom.Similar,
          currentItemTitle: content.value.title,
          currentItemType: content.value.contentType,
        },
        true,
      );
    };

    const onSimilarScroll = (direction) => {
      contentPageAnalytics.onClickItemSimilarBeltScroll({
        itemId: content.value.id,
        title: content.value.title,
        contentType: content.value.contentType,
        page: AnalyticPageName.Item,
        direction,
      });
    };

    const onKinomScroll = (direction) => {
      contentPageAnalytics.onClickItemKinomBeltScroll({
        itemId: content.value.id,
        title: content.value.title,
        contentType: content.value.contentType,
        page: AnalyticPageName.Item,
        direction,
      });
    };

    const isAddedToCollection = ref(
      content.value?.inUserCollection ||
        collectionService.savedFilmsItems.includes(route.params.id) ||
        collectionService.savedSerialsItems.includes(route.params.id),
    );

    const isContentAvailable = computed(() => {
      if (isInactivePartnerSubscription.value) {
        return false;
      }

      return isAvailable(content.value) || isUnavailableSoon(content.value);
    });

    const background = computed(() => content.value.seasons[0].episodes?.map((episode) => episode.background));

    const shouldShowHelp = ref(false);

    const saveToCollectionKey = computed(() =>
      isAddedToCollection.value ? 'pages.mediaCard.removeFromCollection' : 'pages.mediaCard.saveToCollection',
    );

    const onSaveActive = (value) => {
      shouldShowHelp.value = value;
      onScroll(0);
    };

    const onSaveCollectionItem = async (id) => {
      if (!content.value) {
        throw new UnexpectedComponentStateError('content.value');
      }

      contentPageAnalytics.onClickItemCollectionsSet({
        title: content.value.title,
        contentType: content.value.contentType,
        itemId: content.value.id,
      });

      try {
        await collectionService.saveItems([id], content.value.contentType);

        isAddedToCollection.value = true;
        catalogStore.setUpdated(true);

        alertService.addAlert({
          message: translate('pages.mediaCard.addedToCollection'),
          type: AlertMessageTypes.Success,
        });
      } catch (e) {
        alertService.addAlert({ message: translate(e.message), type: AlertMessageTypes.Warning });
      }
    };

    const onRemoveCollectionItem = async (id) => {
      if (!content.value) {
        throw new UnexpectedComponentStateError('content.value');
      }

      contentPageAnalytics.onClickItemCollectionsRemove({
        title: content.value.title,
        contentType: content.value.contentType,
        itemId: content.value.id,
      });

      catalogStore.setUpdated(true);
      await collectionService.removeItems([id], content.value.contentType);
      isAddedToCollection.value = false;

      alertService.addAlert({
        message: translate('pages.mediaCard.removedFromCollection'),
        type: AlertMessageTypes.Success,
      });
    };

    const onNavToTop = () => {
      focusSelf();
      onScroll(0);
    };

    watch(
      () => content.value,
      () => {
        if (!shouldShowPromoSub.value) {
          focusSelf();
        }
      },
    );

    const fetchContent = async () => {
      const { id, type } = route.params;

      await collectionService.updateSavedItems();

      if (!id || !type) {
        throw new UnexpectedComponentStateError('params');
      }

      await Promise.all([
        await contentStore.fetchContent({ id, type }),
        await contentStore.fetchMoments(id),
        await contentStore.fetchCollections(id),
        await contentStore.fetchSimilar(id),
      ]);

      if (!content.value) {
        throw new UnexpectedComponentStateError('content');
      }
    };

    const onLoadError = async (error) => {
      if (error) {
        await timeout(250);
        return routerService.replace({ name: RouterPage.MainPage });
      }
    };

    const closePartnerSubscriptionModal = () => {
      isPartnerSubscriptionModalShown.value = false;

      nextTick(() => SpatialNavigation.setFocus(FocusKeys.BOOKMARK_BUTTON));
    };

    const onSimilarContentDisplayed = () => {
      contentPageAnalytics.onAutoItemSimilarBeltDisplayed({
        title: content.value.title,
        contentType: content.value.contentType,
      });
    };

    const onBlurBackButton = () => {
      const focusKey = previousFocusKey.value;

      if (!SpatialNavigation.doesFocusableExist(focusKey)) {
        return;
      }

      nextTick(() => SpatialNavigation.setFocus(focusKey));
    };

    disposableStore.add(
      SpatialNavigation.emitter.on('on-changed-current-focus-component', (component) => {
        if (component.focusKey === FocusKeys.BACK_BUTTON) {
          return;
        }

        previousFocusKey.value = component.focusKey;
      }),
    );

    onUnmounted(() => {
      contentStore.resetContentData();
    });

    onMounted(async () => {
      try {
        await fetchContent();

        const { title, contentType, id } = content.value;

        contentPageAnalytics.onShowItemPage({
          title,
          contentType,
          itemId: id,
          page: AnalyticPageName.Item,
        });

        isAddedToCollection.value =
          content.value?.inUserCollection ||
          collectionService.savedFilmsItems.includes(route.params.id) ||
          collectionService.savedSerialsItems.includes(route.params.id);
      } catch (error) {
        if (route.name === RouterPage.MainPage || route.name === RouterPage.MediaCardPage) {
          return onLoadError(error);
        }
      } finally {
        if (route.name === RouterPage.MediaCardPage) {
          focusSelf();
        }
      }
    });

    onBeforeUnmount(() => {
      disposableStore.dispose();
    });

    return {
      el,
      focusKey,
      FocusKeys,
      AppButton,
      onBlurBackButton,
      onSimilarContentDisplayed,
      closePartnerSubscriptionModal,
      onNavToTop,
      onRemoveCollectionItem,
      onSaveCollectionItem,
      onSaveActive,
      saveToCollectionKey,
      background,
      onKinomScroll,
      onSimilarScroll,
      onSimilarSelect,
      onDetailsSelect,
      onPlayEpisode,
      onMomentSelect,
      onScrollDetails,
      finishPromoSub,
      isMediaCardTopHeaderMounted,
      onMediaCardTopHeaderMounted,
      onVNodeMounted,
      isVNodeMounted,
      contentSubtitle,
      moments,
      similar,
      limit,
      subtitleGenres,
      collections,
      content,
      offsetTopPx,
      isAddedToCollection,
      isSliderActive,
      shouldShowPromoSub,
      isPartnerSubscriptionModalShown,
      onScroll,
      onScrollTopButton,
      RouterPage,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/layers';

.wrapper {
  padding-top: adjust.adjustPx(78px);
  padding-left: adjust.adjustPx(140px);
  background-color: var(--color-bg-primary);
  overflow: hidden;

  &::before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(90deg, rgba(8, 17, 16, 1) 23.88%, rgba(18, 33, 33, 0) 79.01%);
    content: '';
  }
  &::after {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(18, 33, 33, 0) 35.46%, rgba(8, 17, 16, 1) 79.35%);
    content: '';
  }
}

.header {
  margin-bottom: adjust.adjustPx(48px);
}

.playButtonSubtitle {
  margin-top: adjust.adjustPx(12px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-3();
}

.contentWrapper {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-content);
  margin-left: adjust.adjustPx(60px);
}

.bgPrimary {
  background-color: var(--color-bg-primary);
}

.moments {
  margin-top: adjust.adjustPx(80px);
}

.section {
  margin-top: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(720px);
}

.sectionLinkToTop {
  margin-top: adjust.adjustPx(62px);
  margin-left: adjust.adjustPx(-200px);
}

.sectionLinkToTopSimilar {
  margin-top: adjust.adjustPx(124px);
}

.similar {
  min-height: adjust.adjustPx(720px);
}

.collections {
  min-height: adjust.adjustPx(832px);
}

.linkToTop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .button {
    width: adjust.adjustPx(325px);
    height: adjust.adjustPx(88px);
    border-radius: var(--g-border-round-16);
    outline: none;
  }
}

.controls {
  display: flex;

  button span {
    margin-bottom: 0;
  }
}

.list {
  position: relative;
  overflow: visible;
}

.icon {
  margin-right: adjust.adjustPx(24px);
  width: adjust.adjustPx(38px);
  height: adjust.adjustPx(38px);
}

.save {
  display: flex;
  align-items: center;
  margin-left: adjust.adjustPx(20px);
  padding: adjust.adjustPx(20px) adjust.adjustPx(23px);
  width: adjust.adjustPx(88px);
  height: adjust.adjustPx(88px);
  border-radius: adjust.adjustPx(16px);

  svg {
    display: inline-flex;
    width: adjust.adjustPx(34px);
    height: adjust.adjustPx(34px);

    fill: var(--color-text-primary);
  }

  .bookMarkSavedIcon {
    width: adjust.adjustPx(51px);
    height: adjust.adjustPx(51px);

    path {
      fill: var(--color-text-primary);
      stroke: var(--color-text-primary);
    }
  }

  &Active {
    background-color: var(--color-bg-accent);

    svg {
      fill: var(--color-notheme-text-accent);
    }

    .bookMarkSavedIcon {
      path {
        fill: var(--color-notheme-text-accent);
        stroke: var(--color-notheme-text-accent);
      }
    }
  }

  &:hover {
    background: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
    path {
      fill: var(--color-notheme-text-accent);
      stroke: var(--color-notheme-text-accent);
    }
  }
}

.selected {
  &:hover {
    background-color: var(--color-bg-accent);
    svg {
      fill: var(--color-notheme-text-accent);
    }
  }
}
</style>
