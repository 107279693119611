<template>
  <div v-if="isLoading" :class="$style.shimmer" />
  <canvas v-else ref="canvasRef" :class="$style.qrCanvas" @click="onQRCodeClick()" />
</template>

<script>
import { deviceService, OperationSystem, qrService } from '@package/smarttv-base/src';
import { ref, watchEffect } from '@vue/composition-api';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    loadingClass: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const canvasRef = ref(null);

    const onQRCodeClick = () => {
      if (deviceService.os !== OperationSystem.Desktop) {
        return;
      }

      emit('click');

      const browserWindow = window.open(props.url, '_blank');

      window.setTimeout(() => browserWindow?.close(), 10000);
    };

    watchEffect(() => {
      if (!props.isLoading && props.url) {
        qrService.createQRCode({
          canvasEl: canvasRef.value,
          text: props.url,
        });
      }
    });

    return {
      canvasRef,
      onQRCodeClick,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/shimmers' as shimmers;
@import '@package/ui/src/styles/shimmers';

.qrCanvas {
  width: adjust.adjustPx(432px) !important;
  min-width: adjust.adjustPx(432px) !important;
  max-width: adjust.adjustPx(432px) !important;
  height: adjust.adjustPx(432px) !important;
  min-height: adjust.adjustPx(432px) !important;
  max-height: adjust.adjustPx(432px) !important;

  border-radius: adjust.adjustPx(24px);
}
</style>
