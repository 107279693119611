import type { SmartTvVijuPlayer } from '@package/media-player/src/player';
import { DisposableStore } from '@package/sdk/src/core';

import { globalSettings } from '../services';

export function playerToRefs(player: SmartTvVijuPlayer) {
  const disposableStore = new DisposableStore();

  const isPlaying = globalSettings.ref(false);
  const isVideoEnded = globalSettings.ref(false);

  if (player?.on) {
    const playingHandler = player.on('playing', () => {
      isPlaying.value = true;
    });

    const playHandler = player.on('play', () => {
      isPlaying.value = true;
    });

    const pauseHandler = player.on('pause', () => {
      isPlaying.value = false;
    });

    const endedHandler = player.on('ended', () => {
      isPlaying.value = false;
    });

    disposableStore.add(playHandler);
    disposableStore.add(playingHandler);
    disposableStore.add(pauseHandler);
    disposableStore.add(endedHandler);
  }

  globalSettings.onUnmounted(() => {
    disposableStore.dispose();
  });

  return {
    isVideoEnded,
    isPlaying,
  };
}
