import { computed, ref } from '@vue/composition-api';

type SetConstructorArgument<T> = readonly T[] | null | Set<T>;

class ReactiveSet<T> extends Set<T> {
  constructor(
    private readonly onMutate: () => void,
    values?: SetConstructorArgument<T>,
  ) {
    super(values);
  }

  add(value: T) {
    super.add(value);
    if (this.onMutate !== undefined) {
      this.onMutate();
    }
    return this;
  }

  delete(value: T): boolean {
    const res = super.delete(value);
    this.onMutate();
    return res;
  }

  clear() {
    super.clear();
    this.onMutate();
  }
}

export default function useReactiveSet<T>(values?: SetConstructorArgument<T>) {
  function onMutate() {
    // eslint-disable-next-line no-self-assign
    set.value = set.value;
  }

  const inner = ref(new ReactiveSet<T>(onMutate, values));

  const set = computed<Set<T>>({
    get: () => {
      return inner.value;
    },
    set: (set: Set<T>) => {
      inner.value = new ReactiveSet(onMutate, set);
    },
  });

  return set;
}
