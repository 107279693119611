<template>
  <div
    :class="{
      [$style.timelineNextContent]: true,
    }"
  >
    <NavigatableItem
      ref="el"
      :class="{ [$style.control]: true, [$style.active]: focused }"
      :active-class="$style.active"
      variation="primary"
      :tag="UIButton"
      :on-click="onWatchTitles"
    >
      Смотреть титры
    </NavigatableItem>

    <NavigatableItem
      ref="el"
      :class="{ [$style.control]: true, [$style.preview]: true, [$style.active]: focused }"
      :active-class="$style.active"
      :focus-key="FocusKeys.VIDEO_TIMELINE_NEXT_CONTENT_BUTTON"
      variation="primary"
      :tag="UIButton"
      :on-click="onRequestEpisode"
    >
      <app-image
        :class="$style.image"
        :width="192"
        background-color="var(--color-notheme-bg-hover-70)"
        :src="previewSrc"
      />

      <p :class="$style.description">
        <span :class="$style.text">Следующая серия</span>
        <span :class="$style.time">{{ episodeTitle }}</span>
      </p>
    </NavigatableItem>
  </div>
</template>

<script>
import { FocusKeys } from '@package/smarttv-base/src';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { onMounted } from '@vue/composition-api';

import AppImage from '@/components/app-image/AppImage.vue';
import UIButton from '@/components/button/UIButton.vue';

export default {
  props: {
    episodeTitle: String,
    previewSrc: String,
    onRequestEpisode: Function,
    onWatchTitles: Function,
    isButtonAnimated: Boolean,
  },
  components: {
    AppImage,
  },
  setup() {
    const { el, focused } = useNavigatable({
      preferredChildFocusKey: FocusKeys.VIDEO_TIMELINE_NEXT_CONTENT_BUTTON,
    });

    onMounted(() => {
      SpatialNavigation.setFocus(FocusKeys.VIDEO_TIMELINE_NEXT_CONTENT_BUTTON);
    });

    return {
      el,
      focused,
      UIButton,
      FocusKeys,
    };
  },
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@import '@/styles/fonts';

.timelineNextContent {
  position: fixed;
  bottom: adjust.adjustPx(240px);
  right: 0;
  display: flex;
  order: 1;

  .button {
    margin-left: var(--g-spacing-8);
  }
}

.control {
  display: flex;
  align-items: center;
  margin-left: var(--g-spacing-8);
  padding: var(--g-spacing-16);
  border: adjust.adjustPx(8px) solid transparent;
  border-radius: adjustPx(18px);
  outline: none;
  background-color: var(--color-notheme-bg-secondary-80);
  font-size: 18px;
  color: var(--color-notheme-text-primary);

  &Text {
    margin-left: adjustPx(12px);
  }

  &Live {
    margin-left: adjustPx(16px);

    &Text {
      margin-left: adjustPx(12px);
    }
  }
}

.preview {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--g-spacing-4) var(--g-spacing-8) var(--g-spacing-6);
  max-width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-notheme-text-primary);
}

.time {
  margin-top: var(--g-spacing-2);
  font-size: 14px;
  line-height: 20px;
  color: var(--color-notheme-text-secondary);
  text-transform: uppercase;
}

.image {
  border-top-left-radius: var(--g-border-round-8);
  border-top-right-radius: var(--g-border-round-8);
  height: 108px;
}
.active {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}
</style>
