<template>
  <section :class="$style.keyboard">
    <transition :name="transitionName">
      <div :class="$style.keyboardLayout">
        <div v-for="(row, index) in items" :key="`keyboardRow${index}`" :class="$style.row">
          <NavigatableItem
            v-for="item in row"
            :key="getResolvedItemKey(item)"
            :focus-key="getResolvedItemFocusKey(item)"
            :tag="AppButton"
            transition-duration="0.1s"
            :text="getResolvedItemLabel(item)"
            :class="{
              [$style.symbol]: true,
              [$style.symbolUpperCase]: symbolsUpperCase && !item.control && !item.label,
            }"
            :style="getItemStyles(item)"
            :on-click="() => onItemClick(item)"
            @active="$emit('active')"
          >
            <template #icon>
              <component
                :is="'value' in item.icon ? item.icon.value : item.icon"
                v-if="item.icon"
                :class="$style.icon"
              />
            </template>
          </NavigatableItem>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useTransitionName from '@package/smarttv-base/src/utils/use-transition-name';
import { adjustPx, FocusKeys } from '@SMART/index';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    symbolsUpperCase: {
      type: Boolean,
      default: false,
    },
    itemWidth: {
      type: Number,
      default: 68,
    },
  },
  setup(props, { emit }) {
    const keyboardColumnGap = 8;

    const { transitionName } = useTransitionName();

    const getItemStyles = (item) => {
      let itemWidth = props.itemWidth;

      if (item.span) {
        const resolvedSpannedItemWidth = props.itemWidth * item.span + keyboardColumnGap * (item.span - 1);
        itemWidth = resolvedSpannedItemWidth;
      }

      return {
        'max-width': adjustPx(itemWidth) + 'px',
        'min-width': adjustPx(itemWidth) + 'px',
        ...item.style,
      };
    };

    const getResolvedItemKey = (item) => {
      if (item.control || item.label) {
        return item.key;
      }

      return props.upperCase ? item.key.toLocaleUpperCase() : item.key;
    };

    const getResolvedItemFocusKey = (item) => {
      return FocusKeys.KEYBOARD_KEY(item?.focusKey || getResolvedItemKey(item).toUpperCase());
    };

    const getResolvedItemLabel = (item) => {
      if (item.icon) {
        return '';
      }

      if (item.label) {
        return item.label;
      }

      return getResolvedItemKey(item);
    };

    const onItemClick = async (item) => {
      const key = getResolvedItemKey(item);

      if (!key) {
        throw new UnexpectedComponentStateError('customKeyboardKey');
      }

      item.control ? emit('key:control', item) : emit('key:common', key);
    };

    return {
      transitionName,
      getItemStyles,
      getResolvedItemKey,
      getResolvedItemFocusKey,
      getResolvedItemLabel,
      onItemClick,
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.keyboard {
  width: min-content;

  &Layout {
    display: flex;
    flex-flow: column;
  }
}

.icon {
  width: adjust.adjustPx(64px);
  height: adjust.adjustPx(64px);
}

.row {
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    margin-bottom: adjust.adjustPx(8px);
  }

  .symbol {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: adjust.adjustPx(5px) adjust.adjustPx(14px);
    min-height: adjust.adjustPx(68px);
    max-height: adjust.adjustPx(68px);
    border-radius: adjust.adjustPx(6px);
    background: var(--color-bg-button-ghost-80);

    @include smartTvFonts.SmartTvLabel-1();

    &:not(:last-child) {
      margin-right: adjust.adjustPx(8px);
    }

    &::after {
      display: none;
    }

    &[data-navigatable-active='true'],
    &:hover {
      background-color: var(--color-bg-accent) !important;
      color: var(--color-notheme-text-accent);
    }

    &UpperCase {
      text-transform: uppercase;
    }

    & p {
      height: adjust.adjustPx(58px);
    }
  }
}
</style>
