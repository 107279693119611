import { ProcessingMapper } from '../base/processing-mapper';
import type { ThankYouPage } from '../content/types/thank-you';
import type { ApiThankYouPage } from './thank-you';
import { ThankYouBlockDataMapper } from './thank-you-block-data';

export class ThankYouPageMapper {
  static map({ blocks, background_url }: ApiThankYouPage): ThankYouPage {
    return ProcessingMapper.process({
      backgroundUrl: background_url,
      blocks: ThankYouBlockDataMapper.mapMany(blocks),
    });
  }
}
