interface InputLogObject {
  level?: LogLevel;
  tag?: string;
  type?: LogType;
  message?: MessageType;
  additional?: string | string[];
  args?: any[];
  date?: Date;
}

export interface LogObject extends InputLogObject {
  level: LogLevel;
  type: LogType;
  tag: string;
  args: any[];
  date: Date;

  [key: string]: unknown;
}

export enum LogLevel {
  Silent,
  Fatal,
  Error,
  Warn,
  Log,
  Info,
  Success,
  Fail,
  Ready,
  Start,
  Box,
  Debug,
  Trace,
  Verbose,
}

type LogType =
  | 'silent'
  | 'fatal'
  | 'error'
  | 'warn'
  | 'log'
  | 'info'
  | 'success'
  | 'fail'
  | 'ready'
  | 'start'
  | 'box'
  | 'debug'
  | 'trace'
  | 'verbose';

export interface LoggerReporter {
  log(obj: LogObject): void;
}

const defaultColor = '#9b9292';

const isClient = typeof window !== 'undefined';

const logLevelsColorMap: Record<LogType, string> = {
  silent: defaultColor,
  fatal: defaultColor,
  error: '#c81d1d',
  warn: '#d87420',
  log: '#15716e',
  info: '#15716e',
  success: '#66cc22',
  fail: defaultColor,
  ready: defaultColor,
  start: defaultColor,
  box: defaultColor,
  debug: '#8321da',
  trace: defaultColor,
  verbose: defaultColor,
};

const IS_UI_LOG_ENABLED = false;
const loggerWrapperCss = 'package-logger-wrapper';

type MessageType = string | Error | number | any;

export class Logger {
  private readonly reporters = new Set<LoggerReporter>();

  constructor(private readonly tag = '') {
    if (isClient && IS_UI_LOG_ENABLED) {
      if (document.body.querySelector(`.${loggerWrapperCss}`)) {
        return;
      }

      const wrapper = document.createElement('div');
      wrapper.classList.add(loggerWrapperCss);

      const wrapperStyle = document.createElement('style');
      wrapperStyle.innerHTML = `
      .${loggerWrapperCss} {
          z-index: 9999999;
          position: absolute;
          pointer-events: none;
          right: 20px;
          top: 20px;
      }
      `;

      document.head.appendChild(wrapperStyle);
      document.body.appendChild(wrapper);
    }
  }

  private addUINotification(logObject: LogObject) {
    if (isClient && IS_UI_LOG_ENABLED) {
      const wrapper = document.querySelector(`.${loggerWrapperCss}`) as HTMLElement;

      const notificationEl = document.createElement('div');
      notificationEl.textContent = String(logObject.message) || '';

      wrapper.appendChild(notificationEl);

      window.setTimeout(() => {
        notificationEl.remove();
      }, 3000);
    }
  }

  private _log(log: LogType, level: LogLevel, messageOrError: MessageType, ...args: any[]) {
    const { tag, reporters, _level } = this;

    const logObj: LogObject = {
      type: log,
      level,
      args,
      message: messageOrError,
      date: new Date(),
      tag,
    };

    if (_level >= level) {
      console.info(
        `%c ${tag}:${log} `,
        `color: #fff; background-color: ${logLevelsColorMap[log]}; font-weight: bold; border-radius: 4px;`,
        messageOrError instanceof Error ? messageOrError.message : messageOrError,
        ...args,
      );

      this.addUINotification(logObj);

      reporters.forEach((reporter) => reporter.log(logObj));
    }
  }

  private _level: LogLevel = 0;
  public set level(level: LogLevel | number) {
    this._level = level;
  }

  public get level() {
    return this._level;
  }

  public addReporter(reporter: LoggerReporter) {
    this.reporters.add(reporter);
  }

  public removeReporter(reporter: LoggerReporter) {
    this.reporters.delete(reporter);
  }

  public withTag(tag: string) {
    return new Logger(tag);
  }

  public silent(message: MessageType, ...args: any[]) {
    this._log('silent', LogLevel.Silent, message, args);
  }

  public fatal(message: MessageType, ...args: any[]) {
    this._log('fatal', LogLevel.Fatal, message, args);
  }

  public error(message: MessageType, ...args: any[]) {
    this._log('error', LogLevel.Log, message, args);
  }

  public warn(message: MessageType, ...args: any[]) {
    this._log('warn', LogLevel.Warn, message, args);
  }

  public log(message: MessageType, ...args: any[]) {
    this._log('log', LogLevel.Log, message, args);
  }

  public info(message: MessageType, ...args: any[]) {
    this._log('info', LogLevel.Info, message, args);
  }

  public success(message: MessageType, ...args: any[]) {
    this._log('success', LogLevel.Success, message, args);
  }

  public fail(message: MessageType, ...args: any[]) {
    this._log('fail', LogLevel.Fatal, message, args);
  }

  public ready(message: MessageType, ...args: any[]) {
    this._log('ready', LogLevel.Ready, message, args);
  }

  public start(message: MessageType, ...args: any[]) {
    this._log('start', LogLevel.Start, message, args);
  }

  public box(message: MessageType, ...args: any[]) {
    this._log('box', LogLevel.Box, message, args);
  }

  public debug(message: MessageType, ...args: any[]) {
    this._log('debug', LogLevel.Debug, message, args);
  }

  public trace(message: MessageType, ...args: any[]) {
    this._log('trace', LogLevel.Trace, message, args);
  }

  public verbose(message: MessageType, ...args: any[]) {
    this._log('verbose', LogLevel.Verbose, message, args);
  }
}
