<template>
  <article ref="el" :class="$style.movieDetails">
    <section :class="$style.movieInfo">
      <div :class="$style.movieText">
        <h1 :class="$style.movieTitle">{{ $t(`pages.mediaCard.about-${content.contentType}`) }}</h1>
        <p :class="$style.movieDescription">
          {{ content.description }}
        </p>
      </div>
      <div v-for="(items, index) in infoItems" :key="index" :class="$style.movieDetailsGrid">
        <div :class="$style.infoItem">
          <MediaCardInfoItem v-for="item in items" :key="item.title" :title="item.title" :value="item.value" />
        </div>
      </div>
    </section>
    <aside :class="$style.movieMetadata">
      <MediaCardMetadataSection
        v-if="content.genres.length"
        filter-type="genre"
        :title="$t('pages.mediaCard.genres')"
        :tags="content.genres"
        :startIndex="0"
        @activated="handleActivated"
        @selected="(options) => $emit('selected', options)"
      />
      <MediaCardMetadataSection
        v-if="content.countries.length"
        :class="$style.countries"
        filter-type="country"
        :title="$t('pages.mediaCard.countries')"
        :tags="content.countries"
        :startIndex="content.genres.length"
        @activated="handleActivated"
        @selected="(options) => $emit('selected', options)"
      />
    </aside>
  </article>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { formatDuration, pluralize, translate } from '@SMART/index';
import { computed, provide, ref } from '@vue/composition-api';

import MediaCardInfoItem from './MediaCardInfoItem.vue';
import MediaCardMetadataSection from './MediaCardMetadataSection.vue';

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  components: {
    MediaCardInfoItem,
    MediaCardMetadataSection,
  },
  setup(props, { emit }) {
    const { el, focusKey } = useNavigatable({
      focusKey: 'MEDIA_CARD_DETAILS',
      saveLastFocusedChild: true,
      isFocusBoundary: true,
      hasGlobalAccess: true,
      focusBoundaryDirections: ['right'],
    });
    provide('parentFocusKey', focusKey.value);

    const duration = computed(() => formatDuration(props.content?.duration, 'hhmm'));
    const director = computed(() => props.content?.directors?.[0]?.name);
    const actors = computed(() =>
      props.content?.actors
        ?.slice(0, 3)
        ?.map((x) => x.name)
        .join(', '),
    );

    const handleActivated = (offsetTop, index) => {
      emit('activated', el.value?.offsetTop || offsetTop, index);
    };

    const infoItems = computed(() => {
      const items = [];

      const title = props.content.titleEn || props.content.title;
      if (title) {
        items.push({ title: translate('pages.mediaCard.originalTitle'), value: title });
      }
      if (duration.value) {
        const isSerial = props.content.contentType === 'SERIAL';
        const seasonNumber = props.content.seasons?.length ?? 1;
        const value = isSerial
          ? `${seasonNumber} ${pluralize(seasonNumber, 'pluralizedUnit.season').toLowerCase()}`
          : duration.value;

        items.push({ title: translate('pages.mediaCard.duration'), value });
      }
      if (props.content.year) {
        items.push({ title: translate('pages.mediaCard.productionYear'), value: String(props.content.year) });
      }
      if (director.value) {
        items.push({ title: translate('pages.mediaCard.director'), value: director.value });
      }
      if (actors.value) {
        items.push({ title: translate('pages.mediaCard.actors'), value: actors.value });
      }
      const sliced = [items.slice(0, 2), items.slice(2, 4), items.slice(4)];
      return sliced;
    });

    return {
      el,
      focusKey,
      duration,
      director,
      actors,
      handleActivated,
      infoItems,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/mixins';

.movieDetails {
  display: flex;
  align-items: flex-start;
  padding-right: adjust.adjustPx(60px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.movieInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  margin-right: adjust.adjustPx(56px);
  width: adjust.adjustPx(1011px);
  min-width: adjust.adjustPx(240px);
}

.movieText {
  display: flex;
  flex-direction: column;
  margin-bottom: adjust.adjustPx(40px);
  width: 100%;
  color: var(--color-text-primary);
}

.movieTitle {
  font-weight: 500;
  font-size: adjust.adjustPx(48px);
  line-height: 1;
  letter-spacing: adjust.adjustPx(-0.48px);
}

.movieDescription {
  margin-top: adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvBody-2();
}

.movieDetailsGrid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  font-weight: 400;
  font-size: adjust.adjustPx(32px);
}

.infoItem {
  display: flex;
  flex: 1;
  flex-basis: 0;
  justify-content: flex-start;
  margin-right: adjust.adjustPx(24px);
  min-width: adjust.adjustPx(240px);
}

.movieMetadata {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: adjust.adjustPx(403px);
  min-width: adjust.adjustPx(240px);
  font-weight: 500;
  color: var(--color-text-primary);
}

.genres {
  margin-bottom: adjust.adjustPx(60px);
}
</style>
