<template>
  <h1 v-if="logo" :class="$style.title">
    <AppImage :class="$style.logo" :src="logo" :width="480" />
  </h1>
  <h1 v-else :class="$style.title">{{ title }}</h1>
</template>

<script>
import AppImage from '@/components/app-image/AppImage.vue';

export default {
  components: {
    AppImage,
  },

  props: {
    logo: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  @include smartTvFonts.SmartTvTitle-1();
  margin-right: adjust.adjustPx(60px);

  .logo {
    max-width: 100%;
    max-height: adjust.adjustPx(228px);
    object-fit: contain;
    object-position: left top;
  }
}
</style>
