import useLogger from '@package/logger/src/use-logger';
import { isNumber, WebOsSystemError } from '@package/sdk/src/core';

import type { WebOS } from '../../utils/webos-utils';
import {
  DEFAULT_DEVICE_NAME,
  getSystemWebOSInformation,
  getWebOSLaunchParams,
  VENDOR_NAME,
} from '../../utils/webos-utils';
import { globalEmitter, routerService } from '../index';
import { AbstractManufacturerService } from './abstract-manufacturer-service';
import type { SetVolumeLevel } from './device-types';
import { WebosFeaturesMap } from './performance-features';

declare let webOS: {
  systemInfo: WebOS.SystemInfoService;
  service: WebOS.InternalLunaService;
  fetchAppId(): string;
  keyboard: WebOS.KeyboardService;
  platformBack(): void;
};

declare let webOSDev: {
  systemInfo: WebOS.SystemInfoService;
  service: WebOS.InternalLunaService;
  fetchAppId(): string;
  keyboard: WebOS.KeyboardService;
  platformBack(): void;
};

const logger = useLogger('webos-service');

export class WebOsService extends AbstractManufacturerService {
  private systemInformation: WebOS.SystemInfoTransformed | undefined;

  constructor() {
    super(new WebosFeaturesMap());
  }

  private doSetVolume(type: 'up' | 'down') {
    const method = type === 'up' ? 'volumeUp' : 'volumeDown';

    logger.info('doSetVolume', type);

    try {
      webOS.service.request('luna://com.webos.audio', {
        method,
        onComplete: function () {
          //
        },
        onFailure: function (error) {
          logger.error('doSetVolume', error);
        },
      });
    } catch (error) {
      logger.error('doSetVolume', error);
    }
  }

  public setVolume(volume: SetVolumeLevel) {
    if (isNumber(volume)) {
      return;
    }

    this.doSetVolume(volume);
  }

  public getVolume(): number {
    return 0;
  }

  public async resolveStartupDeeplink(): Promise<boolean> {
    try {
      const deeplink = getWebOSLaunchParams();

      if (!deeplink) {
        return false;
      }

      const normalizedDeeplink = deeplink.replace(/ /g, '');
      const parsedDeeplink = JSON.parse(normalizedDeeplink);
      const contentTarget = parsedDeeplink?.contentTarget;

      if (!contentTarget) {
        return false;
      }

      const resolved = routerService.resolve(contentTarget);
      await routerService.replace(resolved);

      globalEmitter.emit('deeplink-resolved');
      return true;
    } catch (error) {
      logger.error('resolveStartupDeeplink', error);
      return false;
    }
  }

  public async init() {
    try {
      this.systemInformation = await getSystemWebOSInformation();

      logger.info('system', JSON.stringify(this.systemInformation));
    } catch (error) {
      logger.error('init', error);
    }
  }

  public getHumanReadableDeviceName(): string {
    if (!this.systemInformation?.modelName) {
      return DEFAULT_DEVICE_NAME;
    }

    return `${VENDOR_NAME} ${this.systemInformation.modelName}`;
  }

  public getManufacturerName(): string {
    return VENDOR_NAME;
  }

  public getDeviceInfo() {
    return this.systemInformation;
  }

  public exit() {
    if (!('webOS' in window) || !('webOSDev' in window)) {
      logger.error(new WebOsSystemError('Expect window.webOS or window.webOSDev to be defined'));
      return false;
    }

    if ('webOS' in window) {
      webOS.platformBack();
      return true;
    } else {
      webOSDev.platformBack();
      return true;
    }
  }
}
