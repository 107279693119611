<template>
  <div ref="container">
    <ScrollViewport ref="el" tag="ul" :class="$style.list" :x="offsetLeftPx" role="list">
      <li v-for="(content, index) in items" :key="content.id" :class="$style.item">
        <NavigatableItem
          :class="$style.link"
          :tag="AppSlotButton"
          :data-testid="`collection-${index + 1}`"
          :data-index="index"
          :prop-parent-focus-key="FocusKeys.MEDIA_CARD_COLLECTION_LIST_SLIDER"
          @active="handleActiveContent"
          @click="
            openContentPage({
              contentType: content.contentType,
              id: content.id,
              title: content.title,
              titlesSelectionName: content.title,
              titlesSelectionPosition: index,
              from: ItemPageFrom.Compilation,
            })
          "
        >
          <app-image :src="content.poster" :class="$style.preview" :width="550" />
        </NavigatableItem>

        <section :class="$style.subtitle">
          <p>{{ content.title }}</p>
        </section>
      </li>
    </ScrollViewport>
  </div>
</template>

<script>
import { ItemPageFrom } from '@package/sdk/src/analytics';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys, useMediaContentActions, useSliderOffset } from '@SMART/index';
import { provide, ref } from '@vue/composition-api';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    AppImage,
    AppSlotButton,
    NavigatableItem,
    ScrollViewport,
  },
  setup(_, { emit }) {
    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.MEDIA_CARD_COLLECTION_LIST_SLIDER,
      saveLastFocusedChild: true,
      hasGlobalAccess: true,
      isFocusBoundary: true,
      parentFocusKey: FocusKeys.MEDIA_CARD_PAGE,
      focusBoundaryDirections: ['right'],
    });
    provide('parentFocusKey', focusKey.value);

    const { handleUpdateOffset, offsetLeftPx } = useSliderOffset();
    const container = ref(null);

    const handleActiveContent = (element) => {
      if (!element) {
        return;
      }

      handleUpdateOffset(element, container.value?.offsetWidth);
      emit('active', element);
    };

    const { openContentPage } = useMediaContentActions();

    return {
      container,
      el,
      offsetLeftPx,
      handleActiveContent,
      openContentPage,
      FocusKeys,
      AppSlotButton,
      ItemPageFrom,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(32px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: adjust.adjustPx(32px);

  width: adjust.adjustPx(376px);
  min-width: adjust.adjustPx(376px);
  height: adjust.adjustPx(508px);
  min-height: adjust.adjustPx(508px);
  border-radius: var(--g-border-round-24);
  outline: none;
  background-color: var(--color-bg-tertiary);

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(516px);
    left: adjust.adjustPx(8px);

    display: -webkit-box;
    width: adjust.adjustPx(376px);
    min-width: adjust.adjustPx(376px);

    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}

.collections {
  display: flex;
  height: adjust.adjustPx(112px);
}
.collection {
  margin-right: adjust.adjustPx(24px);
  height: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(80px);
  border-radius: adjust.adjustPx(16px);
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--g-border-round-24);
  outline: none;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--g-border-round-24);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}
</style>
