<template>
  <section v-if="!isModalHidden" ref="el" :class="$style.shuffleModal">
    <header :class="$style.header">
      <NavigatableItem
        :active-class="$style.active"
        :class="$style.closeButton"
        :tag="AppButton"
        transition-duration="0.1s"
        @click="$emit('close')"
      >
        <template #icon>
          <IconClose :class="$style.iconClose" />
        </template>
      </NavigatableItem>

      <span :class="$style.title">{{ $t('pages.catalog.contentShuffleTitle') }}</span>

      <NavigatableItem
        :tag="AppButton"
        :class="$style.nextButton"
        :active-class="$style.active"
        :text="$t('pages.catalog.next')"
        @click="loadContent"
      />
    </header>

    <ShuffleCard :class="$style.content" v-bind="cardContent" :disabled="false" />
  </section>
</template>

<script>
import { AnalyticEventNameNew, AnalyticPageName } from '@package/sdk/src/analytics';
import { MediaContentType } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconClose from '@SMART/assets/icons/33x33/close.svg';
import {
  analyticService,
  catalogService,
  customEventsService,
  playlistService,
  useCatalogStore,
  useContentStore,
} from '@SMART/index';
import { nextTick, onActivated, onMounted, onUnmounted, provide, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ShuffleCard from '@/pages/catalog/components/ShuffleCard.vue';

export default {
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
  components: {
    AppButton,
    NavigatableItem,
    ShuffleCard,
    IconClose,
  },
  setup(props, { emit, root: { $route: route } }) {
    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: 'SHUFFLE_MODAL',
      isFocusBoundary: true,
      preferredChildFocusKey: 'SELECT_MEDIA_BUTTON',
    });
    provide('parentFocusKey', focusKey.value);

    const contentStore = useContentStore();
    const { genres } = contentStore;

    const catalogStore = useCatalogStore();

    const cardContent = ref({
      id: '',
      contentType: MediaContentType.All,
      title: '',
      subtitle: '',
      description: '',
      limit: '',
      poster: '',
    });

    const loadContent = async () => {
      let resolvedContentType = props.contentType;

      if (props.contentType === MediaContentType.ALL) {
        resolvedContentType = randomContentType.value;
        randomContentType.value =
          randomContentType.value === MediaContentType.Movie ? MediaContentType.Serial : MediaContentType.Movie;
      }

      const data = await catalogService.fetchContentRecommendationsRandom(resolvedContentType);
      await contentStore.fetchContent({ id: data.id, type: data.contentType });

      catalogStore.updateSelectedItem(null);

      cardContent.value = {
        id: data.id,
        contentType: data.contentType,
        title: data.title,
        subtitle: playlistService.getSubtitle(genres.value, data),
        description: data.description,
        limit: playlistService.getLimit(data.ageLimit),
        poster: data.poster,
      };

      analyticService.sendEvent({
        name: AnalyticEventNameNew.ClickCatalogRandomItem,
        page: AnalyticPageName.Catalog,
      });
    };

    const isModalHidden = ref(false);

    watch(
      () => route.name,
      async () => {
        isModalHidden.value = route.name !== 'CatalogPage';

        if (!isModalHidden.value) {
          await nextTick();
          focusSelf();
        }
      },
    );

    const randomContentType = ref(MediaContentType.Movie);

    const setBackCallback = () => {
      customEventsService.setOnPressBackCallback(() => {
        emit('close');
      }, true);
    };

    onActivated(setBackCallback);

    onMounted(async () => {
      setBackCallback();
      focusSelf();

      await loadContent();
    });

    onUnmounted(async () => {
      customEventsService.setOnPressBackCallback();
      SpatialNavigation.setFocus('CATALOG_SHUFFLE_BUTTON');
    });

    return {
      el,
      focusKey,
      AppButton,
      isModalHidden,
      loadContent,
      cardContent,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.shuffleModal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: adjust.adjustPx(40px) adjust.adjustPx(40px) 0px 0px;
  background-color: var(--color-bg-modal);

  padding: {
    top: adjust.adjustPx(40px);
    left: adjust.adjustPx(40px);
    right: adjust.adjustPx(40px);
  }
}

.iconClose {
  width: 33px;
  height: 33px;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: adjust.adjustPx(40px);
  width: 100%;

  .title {
    @include smartTvFonts.SmartTvTitle-3();
  }

  .closeButton {
    position: absolute;
    left: 0;
    width: adjust.adjustPx(88px);
    height: adjust.adjustPx(88px);
  }

  .nextButton {
    position: absolute;
    right: 0;
  }
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}

.content {
  display: flex;
  flex-grow: 1;
  margin-bottom: adjust.adjustPx(40px);
  width: 100%;
}
</style>
