import { RouterPage, type SessionGetters } from '../../../../src';
import { storeToRefs, useSessionStore } from '../../../../src';
import { OperationSystem } from '../../device/device-types';
import { MarketingFeature } from './marketing-feature';

const VIDEO_FETCH_TIMEOUT = 4500;
const MAX_RETRIES = 3;
const RETRY_TIMEOUT = 500;
const VIDEO_PLAYBACK_TIME = 15000;
const OFFER_TIMEOUT = 10000;

export class LGSpecialOffer extends MarketingFeature {
  private cachedVideoBlob: Blob | null = null;
  private videoEl: HTMLVideoElement | null = null;
  private retries = 0;

  public onAppStarted() {
    if (this.deviceService.os !== OperationSystem.WebOs) {
      return;
    }

    const controller = new AbortController();

    setTimeout(() => {
      controller.abort();
    }, VIDEO_FETCH_TIMEOUT);

    fetch('./lg_selling.mp4', { signal: controller.signal })
      .then((response) => response.blob())
      .then((blob) => {
        this.cachedVideoBlob = blob;
      })
      .catch();
  }

  public onAppMounted() {
    if (this.deviceService.os !== OperationSystem.WebOs) {
      return;
    }

    if (!this.cachedVideoBlob) {
      if (this.retries >= MAX_RETRIES) {
        this.handleOffer();
        return;
      }

      this.retries++;
      setTimeout(() => this.onAppMounted, RETRY_TIMEOUT);
      return;
    }

    this.videoEl = document.createElement('video');
    this.videoEl.id = 'sellingVideo';
    this.videoEl.src = URL.createObjectURL(this.cachedVideoBlob);
    this.videoEl.src = './lg_selling.mp4';
    this.videoEl.muted = true;
    this.videoEl.autoplay = true;
    this.videoEl.style.top = '0';
    this.videoEl.style.left = '0';
    this.videoEl.style.width = '100%';
    this.videoEl.style.height = '100%';
    this.videoEl.style.objectFit = 'contain';
    this.videoEl.style.position = 'absolute';
    this.videoEl.style.backgroundColor = 'black';

    this.disableKeyboard();

    let timeout: ReturnType<typeof setTimeout>;
    this.videoEl.addEventListener('started', () => {
      timeout = setTimeout(this.onVideoEnded, VIDEO_PLAYBACK_TIME + 1500);
    });

    this.videoEl.addEventListener('ended', () => {
      clearTimeout(timeout);
      setTimeout(() => {
        this.onVideoEnded();
      }, 600);
    });

    document.body.appendChild(this.videoEl);
  }

  private handleOffer() {
    setTimeout(() => {
      // TODO {go:users} isPartnerSubscription, store types
      const { isAuth, isActiveSubscription, isPartnerUser } = storeToRefs<unknown, SessionGetters, unknown>(
        useSessionStore(),
      );
      if (!isAuth.value || (!isActiveSubscription.value && !isPartnerUser.value)) {
        this.routerService.push({ name: RouterPage.OffersSpecial });
      }
    }, OFFER_TIMEOUT);
  }

  private onVideoEnded = () => {
    this.enableKeyboard();
    this.handleOffer();

    if (!this.videoEl) {
      return;
    }

    document.body.removeChild(this.videoEl);
  };

  private disableKeys: ((event: Event) => void) | undefined;

  private disableKeyboard() {
    this.disableKeys = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    // Отключаем события keydown, keyup и keypress
    window.addEventListener('keydown', this.disableKeys, true);
    window.addEventListener('keyup', this.disableKeys, true);
    window.addEventListener('keypress', this.disableKeys, true);
  }

  private enableKeyboard() {
    if (!this.disableKeys) return;
    window.removeEventListener('keydown', this.disableKeys, true);
    window.removeEventListener('keyup', this.disableKeys, true);
    window.removeEventListener('keypress', this.disableKeys, true);
  }
}
