<template>
  <!-- eslint-disable-next-line vue/no-deprecated-dollar-listeners-api -->
  <button type="button" :class="$style.button" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppSlotButton',
};
</script>

<style module lang="scss">
.button {
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  outline: none;
  background: 0;
  color: inherit;
  font: inherit;
  text-align: left;
}
</style>
