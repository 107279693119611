var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSliderShown)?_c('section',{ref:"container",class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.titleWrapper},[_c('UITypography',{class:_vm.$style.title,attrs:{"shimmer-variant":"subtitle","is-loading":_vm.isLoading}},[_c('span',[_vm._v(" "+_vm._s(_vm.block.title)+" ")]),(_vm.isChannels)?_c('div',{class:_vm.$style.live},[_vm._v("LIVE")]):_vm._e()]),(_vm.isContinueWatch)?_c('div',{class:_vm.$style.titleContinueWatch},[_vm._v(" "+_vm._s(_vm.titleContinueWatch)+" ")]):_vm._e()],1),_c('div',{ref:"playlistContainer"},[_c('scroll-viewport',{class:_vm.$style.list,attrs:{"tag":"ul","x":_vm.offsetLeftPx,"role":"list","is-loading":_vm.isLoading,"shimmer-size":"kinom"}},[(_vm.isPromoBlockOrMomentList)?_vm._l((_vm.block.contentMomentsList),function(moment,index){
var _obj;
return _c('li',{key:moment.id,class:( _obj = {}, _obj[_vm.$style.item] = true, _obj[_vm.$style.itemKinom] = _vm.isKinomBlock(_vm.block.contentMomentsList), _obj )},[_c('promo-item',{attrs:{"moment":moment,"is-kinom-block":_vm.isKinomBlock(_vm.block.contentMomentsList),"focus-key":_vm.getFocusKey(index)},on:{"active":function (element) { return _vm.handleActiveItem(
                  {
                    item: moment,
                    type: 'content-moment',
                    displayType: _vm.block.displayType,
                  },
                  element,
                  index
                ); },"focus":function($event){return _vm.onFocus(index)},"click":function($event){return _vm.openContentPage({
                contentType: moment.contentType,
                id: moment.contentId,
                kinomId: moment.id,
                kinomTitle: moment.title,
                episodeNumber: moment.episodeNumber || 0,
                seasonNumber: moment.seasonNumber || 0,
                title: moment.contentTitle,
                playlistTitle: _vm.block.title,
                position: index,
                from: _vm.block.displayType === _vm.DisplayType.PromoBlock ? _vm.ItemPageFrom.Promo : _vm.ItemPageFrom.Playlist,
              })}}})],1)}):_vm._e(),(_vm.isDsmlRecommendations)?_vm._l((_vm.recommendations),function(recommendation,index){
              var _obj;
return _c('li',{key:recommendation.id,class:( _obj = {}, _obj[_vm.$style.item] = true, _obj[_vm.$style.itemKinom] = _vm.isKinomBlock([], _vm.recommendations), _obj )},[_c('navigatable-item',{class:_vm.$style.link,attrs:{"active-class":_vm.$style.active,"tag":_vm.AppSlotButton,"focus-key":_vm.getFocusKey(index),"data-testid":_vm.getFocusKey(index)},on:{"active":function (element) { return _vm.handleActiveItem(
                  {
                    item: recommendation,
                    type: 'media',
                    displayType: _vm.block.displayType,
                  },
                  element,
                  index
                ); },"focus":function($event){return _vm.onFocus(index)},"click":function($event){return _vm.openContentPage({
                contentType: recommendation.contentType,
                id: recommendation.id,
                title: recommendation.title,
                from: _vm.ItemPageFrom.Dsml,
                position: index,
              })}}},[(_vm.isKinomBlock([], _vm.recommendations))?_c('app-image',{class:_vm.$style.preview,attrs:{"src":recommendation.background,"loading":index > 4 ? 'lazy' : 'eager',"is-loading":_vm.isLoading,"width":_vm.CONTENT_POSTER_WIDTH}}):_c('app-image',{class:_vm.$style.poster,attrs:{"src":recommendation.poster,"is-loading":_vm.isLoading,"width":_vm.CONTENT_POSTER_WIDTH}})],1)],1)}):_vm._e(),(_vm.isGenresBelt)?_vm._l((_vm.block.beltItems),function(belt,index){
              var _obj, _obj$1;
return _c('li',{key:belt.id,class:[_vm.$style.item, _vm.$style.itemBelt],style:({ background: ("linear-gradient(to right bottom, " + (belt.colors.main) + ", " + (belt.colors.light) + ")") })},[_c('navigatable-item',{class:( _obj = {}, _obj[_vm.$style.link] = true, _obj[_vm.$style.belt] = true, _obj[_vm.$style.genre] = true, _obj[_vm.$style.genreWithIcon] = belt.icon, _obj ),style:({ backgroundColor: belt.backgroundColor }),attrs:{"tag":_vm.AppSlotButton,"focus-key":_vm.getFocusKey(index)},on:{"active":function (element) { return _vm.handleActiveItem({ item: belt, type: 'genres-belt', displayType: _vm.block.displayType }, element, index); },"focus":function($event){return _vm.onFocus(index)},"click":function($event){return _vm.openCatalogPage(belt)}}},[(belt.icon)?_c('app-image',{class:_vm.$style.beltIcon,attrs:{"src":belt.icon}}):_vm._e(),_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.beltTitle] = true, _obj$1[_vm.$style.beltTitleCentered] = !belt.icon, _obj$1 )},[_vm._v(" "+_vm._s(belt.name)+" ")])],1)],1)}):_vm._e(),(_vm.isContinueWatch)?_vm._l((_vm.watchingItems),function(watchingItem,index){
                var _obj, _obj$1;
return _c('li',{key:watchingItem.id,class:( _obj = {}, _obj[_vm.$style.item] = true, _obj[_vm.$style.itemKinom] = _vm.isKinomBlock([], _vm.watchingItems), _obj )},[_c('navigatable-item',{class:_vm.$style.link,attrs:{"active-class":_vm.$style.active,"tag":_vm.AppSlotButton,"tabindex":"0","focus-key":_vm.getFocusKey(index),"data-testid":_vm.getFocusKey(index),"on-long-press":_vm.onOpenContinueWatchEditMode},on:{"click":function () { return _vm.onContinueWatchClick(index); },"active":function (element) { return _vm.handleActiveItem(
                  { type: 'watching-item', item: watchingItem, displayType: _vm.block.displayType },
                  element,
                  index
                ); },"focus":function () { return _vm.onFocus(index, watchingItem); }}},[(_vm.isUnavailable(watchingItem) || _vm.isAvailableSoon(watchingItem))?_c('div',{class:_vm.$style.overlay},[_c('span',{class:_vm.$style.overlayText},[_vm._v(" "+_vm._s(_vm.getAvailabilityMessage(watchingItem, { withHourOrMinute: false, withLicenseLockedInfo: false }))+" ")])]):_vm._e(),(_vm.isKinomBlock([], _vm.watchingItems))?_c('app-image',{class:_vm.$style.preview,attrs:{"src":watchingItem.background,"width":_vm.CONTENT_POSTER_WIDTH,"is-loading":_vm.isLoading}}):_c('app-image',{class:_vm.$style.poster,attrs:{"is-loading":_vm.isLoading,"src":watchingItem.poster,"width":_vm.CONTENT_POSTER_WIDTH}}),(_vm.isContinueWatchEditMode)?_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.itemDelete] = true, _obj$1[_vm.$style.itemDeleteActive] = _vm.selectedPlaylistItem.item === watchingItem, _obj$1 )},[_c('icon-delete')],1):_vm._e(),(!(_vm.isUnavailable(watchingItem) || _vm.isAvailableSoon(watchingItem)))?_c('div',{class:_vm.$style.itemProgressWrapper},[_c('div',{class:_vm.$style.itemProgressText},[_vm._v(" "+_vm._s(_vm.getContinueWatchItemProgressText(watchingItem.duration, watchingItem.watchingItem.offset))+" ")]),_c('div',{class:_vm.$style.itemProgress},[_c('div',{class:_vm.$style.itemProgressDone,style:({
                    width: _vm.getContinueWatchItemProgressWidth(watchingItem.duration, watchingItem.watchingItem.offset),
                  })})])]):_vm._e()],1)],1)}):_vm._e(),(_vm.isChannels)?_vm._l((_vm.channels),function(channel,index){return _c('li',{key:channel.id,class:_vm.$style.tvItem},[_c('channel-item',{attrs:{"channel":channel,"index":index,"row-index":_vm.rowIndex},on:{"active":function (element) { return _vm.handleActiveItem({ item: channel, type: 'channel', displayType: _vm.block.displayType }, element, index); },"focus":function($event){return _vm.onFocus(index)}}})],1)}):_vm._e()],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }