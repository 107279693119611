import type { DeviceService } from 'src/services/device/device-service';
import type { RouterService } from 'src/services/router/router-service';

export abstract class MarketingFeature {
  protected static deviceService: DeviceService;
  protected static routerService: RouterService;

  static init(deviceService: DeviceService, routerService: RouterService) {
    MarketingFeature.deviceService = deviceService;
    MarketingFeature.routerService = routerService;
  }

  protected get deviceService(): DeviceService {
    return MarketingFeature.deviceService;
  }

  protected get routerService(): RouterService {
    return MarketingFeature.routerService;
  }

  abstract onAppStarted(): void;
  abstract onAppMounted(): void;
}
