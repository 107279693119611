<template>
  <section ref="el" :class="$style.filtersModal">
    <header :class="$style.header">
      <NavigatableItem
        :active-class="$style.active"
        :class="$style.closeButton"
        :tag="AppButton"
        transition-duration="0.1s"
        @click="$emit('close')"
      >
        <template #icon>
          <IconClose :class="$style.iconClose" />
        </template>
      </NavigatableItem>

      <span>{{ $t('pages.catalog.filters') }}</span>
    </header>

    <div :class="$style.filters">
      <div :class="$style.genres">
        <div :class="$style.filterHeader">{{ $t('pages.catalog.genres') }}</div>
        <ScrollViewport
          ref="genresScroll"
          :class="$style.scroll"
          orientation="vertical"
          @vue:mounted="onGenresVNodeMounted"
        >
          <ColumnGrid v-slot="{ id, item }" :class="$style.grid" :items="filters.genres" :column-number="2">
            <NavigatableItem
              :class="$style.filter"
              :tag="FilterButton"
              :text="item.title"
              :active-class="$style.active"
              :variation="'smart-button-secondary'"
              :selected="item.selected"
              :focus-key="FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('genres', id)"
              transition-duration="0.1s"
              @active="onScroll('genres', $event)"
              @click="$emit('filter:select', item)"
              @blur="onBlur('genres')"
              @focus="onFocus('genres')"
            />
          </ColumnGrid>
        </ScrollViewport>
      </div>
      <template v-if="isGenresMounted">
        <div :class="$style.delimiter" />
        <div :class="$style.countries">
          <div :class="$style.filterHeader">{{ $t('pages.catalog.country') }}</div>
          <ScrollViewport ref="countriesScroll" :class="$style.scroll" orientation="vertical">
            <ColumnGrid v-slot="{ id, item }" :class="$style.grid" :items="filters.countries" :column-number="2">
              <NavigatableItem
                :class="$style.filter"
                :tag="FilterButton"
                :text="item.title"
                :active-class="$style.active"
                :variation="'smart-button-secondary'"
                :selected="item.selected"
                :focus-key="FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('countries', id)"
                transition-duration="0.1s"
                @active="onScroll('countries', $event)"
                @click="$emit('filter:select', item)"
                @blur="onBlur('countries')"
                @focus="onFocus('countries')"
              />
            </ColumnGrid>
          </ScrollViewport>
        </div>
        <div :class="$style.delimiter" />
        <div :class="$style.periods">
          <div :class="$style.filterHeader">{{ $t('pages.catalog.periods') }}</div>
          <ScrollViewport ref="periodsScroll" :class="$style.scroll" orientation="vertical">
            <ColumnGrid v-slot="{ id, item }" :class="$style.grid" :items="filters.periods" :column-number="1">
              <NavigatableItem
                :class="$style.filter"
                :tag="FilterButton"
                :text="item.title"
                :active-class="$style.active"
                :variation="'smart-button-secondary'"
                :selected="item.selected"
                :focus-key="FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('periods', id)"
                transition-duration="0.1s"
                @active="onScroll('periods', $event)"
                @click="$emit('filter:select', item)"
                @blur="onBlur('periods')"
                @focus="onFocus('periods')"
              />
            </ColumnGrid>
          </ScrollViewport>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { customEventsService, scrollToElement } from '@package/smarttv-base/src';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconClose from '@SMART/assets/icons/33x33/close.svg';
import { FocusKeys } from '@SMART/index';
import { onMounted, onUnmounted, provide, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import ColumnGrid from '@/components/grid/ColumnGrid.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import FilterButton from './FilterButton.vue';

export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppButton,
    ColumnGrid,
    FilterButton,
    IconClose,
    NavigatableItem,
    ScrollViewport,
  },
  setup(props, { emit }) {
    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: FocusKeys.FILTERS_MODAL,
      isFocusBoundary: true,
    });
    provide('parentFocusKey', focusKey.value);

    const { isVNodeMounted: isGenresMounted, onVNodeMounted: onGenresVNodeMounted } = useVNodeMounted({
      withTimeout: false,
    });

    const genresScroll = ref(null);
    const countriesScroll = ref(null);
    const periodsScroll = ref(null);
    const previousType = ref('');
    const previousFocusKeyMap = ref({
      genres: FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('genres', '0:0'),
      countries: FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('countries', '0:0'),
      periods: FocusKeys.CATALOG_FILTERS_CATEGORY_ITEM('periods', '0:0'),
    });

    const scrolls = {
      genresScroll,
      countriesScroll,
      periodsScroll,
    };

    const onScroll = (type, item) => {
      scrollToElement(scrolls[type + 'Scroll'].value?.$el, { top: item?.offsetTop });
    };

    const onBlur = (type) => {
      const currentFocusKey = SpatialNavigation.getCurrentFocusKey();

      previousType.value = type;
      previousFocusKeyMap.value[type] = currentFocusKey;
    };

    const onFocus = (type) => {
      if (previousType.value === type) {
        return;
      }

      const currentFocusKey = SpatialNavigation.getCurrentFocusKey();

      if (currentFocusKey === previousFocusKeyMap.value[type]) {
        return;
      }

      SpatialNavigation.setFocus(previousFocusKeyMap.value[type]);
    };

    onMounted(() => {
      focusSelf();

      customEventsService.setOnPressBackCallback(() => {
        emit('close');
      }, true);
    });

    onUnmounted(() => {
      customEventsService.setOnPressBackCallback();
      SpatialNavigation.setFocus(FocusKeys.CATALOG_FILTERS_BUTTON);
    });

    return {
      el,
      isGenresMounted,
      onGenresVNodeMounted,
      genresScroll,
      countriesScroll,
      periodsScroll,
      onScroll,
      onBlur,
      onFocus,
      FocusKeys,
      AppButton,
      FilterButton,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.filtersModal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: adjust.adjustPx(700px);
  border-radius: adjust.adjustPx(40px) adjust.adjustPx(40px) 0 0;
  background-color: var(--color-bg-modal);

  padding: {
    top: adjust.adjustPx(40px);
    left: adjust.adjustPx(32px);
    right: adjust.adjustPx(32px);
  }
}

.iconClose {
  width: 33px;
  height: 33px;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: adjust.adjustPx(40px);
  width: 100%;
  min-height: adjust.adjustPx(88px);

  @include smartTvFonts.SmartTvTitle-3();

  .closeButton {
    position: absolute;
    left: 0;
    width: adjust.adjustPx(88px);
    height: adjust.adjustPx(88px);
  }
}

.filters {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.filterHeader {
  margin-bottom: adjust.adjustPx(32px);
  padding: 0 adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.scroll {
  height: calc(100% - adjust.adjustPx(96px));
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}

.filter {
  &:hover {
    background-color: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.genres {
  padding-right: adjust.adjustPx(40px);
  width: 40%;
  max-width: 40%;
  height: adjust.adjustPx(532px);
  overflow: hidden;
}

.grid {
  position: relative;
}

.countries {
  padding: 0 adjust.adjustPx(40px);
  width: 40%;
  max-width: 40%;
  height: adjust.adjustPx(532px);
  overflow: hidden;
}

.periods {
  padding-left: adjust.adjustPx(40px);
  width: 20%;
  max-width: 20%;
  height: adjust.adjustPx(532px);
  overflow: hidden;
}

.delimiter {
  width: 2px;
  height: adjust.adjustPx(500px);
  background-color: var(--color-stroke-primary);
}
</style>
