<template>
  <div :class="$style.channel">
    <NavigatableItem
      :class="$style.link"
      :active-class="$style.active"
      :tag="AppSlotButton"
      :focus-key="getFocusKey(index)"
      @active="(element) => $emit('active', element)"
      @focus="$emit('focus')"
      @click="openTvChannelPage(channel)"
    >
      <div :class="$style.tv">
        <app-image
          :use-fallback-icon="false"
          :src="channel.currentProgram.background || channel.background"
          :width="600"
        />

        <div
          v-if="Boolean(getTvChannelStickerText(channel.accessKind))"
          :class="{
            [$style.badge]: true,
            [$style.badgeRegistered]: isAfterRegistryChannel,
            [$style.badgeHide]: shouldHideBanner,
          }"
        >
          {{ getTvChannelStickerText(channel.accessKind) }}
        </div>

        <div :class="$style.header" />
        <div :class="$style.progressText">
          {{ channelProgressText }}
        </div>
        <div v-if="channel.currentProgram.startTime" :class="$style.progress">
          <div
            :class="$style.done"
            :style="{
              width: channelProgress,
            }"
          />
        </div>
      </div>
    </NavigatableItem>

    <div v-if="channel.currentProgram.title || channel.currentProgram.startTime" :class="$style.channelInfo">
      <div :class="$style.logoContainer">
        <app-image
          v-show="channelLogo"
          :use-fallback-icon="false"
          :src="channelLogo"
          :class="$style.logo"
          :alt="channel.title"
          :width="420"
        />
      </div>

      <div :class="$style.programInfo">
        <div :class="$style.programTitle">{{ channel.currentProgram.title }}</div>
        <div :class="$style.programTime">{{ startTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { FocusKeys, storeToRefs, translate, useSessionStore, useTvChannelActions } from '@SMART/index';
import { computed } from '@vue/composition-api';
import { differenceInSeconds, format, intervalToDuration, isValid, parseISO } from 'date-fns';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  components: {
    AppImage,
    AppSlotButton,
  },
  setup(props, { emit }) {
    const { user } = storeToRefs(useSessionStore());
    const { getTvChannelStickerText, openTvChannelPage } = useTvChannelActions();

    const channelProgress = computed(() => {
      const startTime = parseISO(props.channel.currentProgram?.startTime);
      const duration = props.channel.currentProgram?.duration;

      if (!duration || !isValid(startTime)) {
        return 0;
      }

      const progress = (differenceInSeconds(new Date(), startTime) / duration) * 100;

      return progress >= 100 ? '100%' : `${progress}%`;
    });

    const channelProgressText = computed(() => {
      if (!props.channel.currentProgram?.startTime) {
        return '';
      }

      const startTime = parseISO(props.channel.currentProgram?.startTime);
      const duration = props.channel.currentProgram?.duration;

      if (!duration || !isValid(startTime)) {
        return '';
      }

      const offset = differenceInSeconds(new Date(), startTime);
      const diff = duration - offset > 0 ? duration - offset : 0;

      const { hours, minutes } = intervalToDuration({ start: 0, end: diff * 1000 });

      if (hours) {
        return translate('pages.main.remainTime', { hours, minutes: minutes ?? '' });
      }

      if (!minutes) {
        return '';
      }

      return translate('pages.main.remainTimeShort', { minutes: minutes ?? '' });
    });

    const startTime = computed(() => {
      const time = parseISO(props.channel.currentProgram?.startTime);

      if (isValid(time)) {
        return format(time, 'HH:mm');
      }

      return '';
    });

    const channelLogo = computed(() => props.channel.logoWhite || props.channel.logoBlack || props.channel.logo);

    const isForAllUsersChannel = computed(() => {
      if (!user.value?.id && props.channel?.accessKind === 'all_users') {
        return true;
      }

      if (user.value?.id && !user.value?.subscription?.active && props.channel?.accessKind !== 'subscription') {
        return true;
      }

      return false;
    });

    const isSubscriptionChannel = computed(() => {
      if (user.value?.id && !user.value?.subscription?.active && props.channel?.accessKind === 'subscription') {
        return true;
      }

      return false;
    });

    const isAfterRegistryChannel = computed(() => {
      if (!user.value?.id && props.channel?.accessKind === 'register') {
        return true;
      }

      return false;
    });

    const shouldHideBanner = computed(
      () => !isForAllUsersChannel.value && !isAfterRegistryChannel.value && !isSubscriptionChannel.value,
    );

    const getFocusKey = (col) => FocusKeys.PLAYLIST_ITEM(props.rowIndex, col);

    return {
      channelProgress,
      channelProgressText,
      startTime,
      channelLogo,
      isForAllUsersChannel,
      isSubscriptionChannel,
      isAfterRegistryChannel,
      shouldHideBanner,
      getFocusKey,
      getTvChannelStickerText,
      openTvChannelPage,
      AppSlotButton,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';

.channel {
  display: flex;
  flex-direction: column;
  border-radius: adjustPx(24px);
}

.channelInfo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 adjustPx(8px);

  .logoContainer {
    margin-right: adjustPx(12px);
    padding: 0 adjustPx(12px);
    width: adjustPx(128px);
    height: adjustPx(72px);
    border-radius: adjustPx(16px);
    background: var(--color-notheme-bg-stream);
  }

  .logo {
    object-fit: contain;
  }

  .programInfo {
    flex-grow: 1;

    @include smartTvFonts.SmartTvBody-3();
  }

  .programTitle {
    max-width: adjustPx(324px);
    height: adjustPx(40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .programTime {
    height: adjustPx(40px);
    color: var(--color-text-secondary);
  }
}

.active {
  border: solid adjustPx(8px) var(--color-bg-accent) !important;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: adjustPx(16px);
  min-width: adjustPx(480px);
  height: adjustPx(270px);
  border: solid adjustPx(8px) transparent;
  border-radius: adjustPx(32px);

  &::after {
    display: none;
  }

  &:hover {
    cursor: pointer;
    border: solid adjustPx(8px) var(--color-bg-accent) !important;
  }
}

.tv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & div:first-child {
    height: 100%;
  }

  img {
    border-radius: adjustPx(24px);
  }

  .header {
    border-bottom-left-radius: adjustPx(24px);
    border-bottom-right-radius: adjustPx(24px);

    position: absolute;
    bottom: 0;
    width: 100%;
    height: adjustPx(108px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  }

  .progress {
    position: absolute;
    bottom: adjustPx(20px);
    left: adjustPx(20px);
    right: adjustPx(20px);
    height: adjustPx(6px);
    border-radius: adjustPx(8px);
    background-color: var(--color-notheme-white-20);
  }

  .progressText {
    position: absolute;
    bottom: adjustPx(30px);
    right: adjustPx(20px);
    color: var(--color-text-secondary);

    @include smartTvFonts.SmartTvBody-3();
  }

  .done {
    position: relative;
    left: 0;
    height: adjustPx(6px);
    border-radius: adjustPx(8px);
    background-color: var(--color-bg-accent);
  }

  .badge {
    position: absolute;
    top: adjustPx(12px);
    right: adjustPx(12px);
    display: flex;
    flex-direction: row;
    padding: adjustPx(16px) adjustPx(24px);
    border-radius: adjustPx(24px);

    background: var(--color-notheme-dim-black-60);
    color: var(--color-text-primary);

    @include smartTvFonts.SmartTvLabel-3();

    &:empty {
      display: none;
    }

    &Registered {
      color: var(--color-notheme-text-action);
    }

    &Hide {
      display: none;
    }
  }
}
</style>
