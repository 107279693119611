<template>
  <section ref="el" :class="$style.content">
    <slot name="catalog-shuffle" />

    <div v-if="isContentEmpty" :class="$style.emptyHeader">
      <slot name="not-found">
        <UITypography :class="$style.contentEmptyHeader" shimmer-variant="subtitle">
          {{ emptyHeader }}
        </UITypography>
      </slot>
    </div>

    <ScrollViewport v-if="showShimmer" ref="scroll1" tag="ul" orientation="vertical" :y="offset" role="list">
      <ui-content-row :class="$style.row" :items="[1, 2, 3]">
        <UIPoster
          v-for="rowItem in 5"
          :key="rowItem"
          tabindex="0"
          :class="{ [$style.contentCell]: true, ceil: true, [$style.shimmer]: true }"
          :is-loading="true"
        />
      </ui-content-row>
    </ScrollViewport>

    <ScrollViewport
      v-show="normalizedItems.length >= items.length"
      ref="scroll"
      :class="$style.scroll"
      tag="ul"
      orientation="vertical"
      :y="offset"
      role="list"
    >
      <ui-content-row
        v-slot="{ item, index }"
        ref="momentElements"
        :class="$style.row"
        :items="normalizedItems"
        @vue:mounted="onVNodeMounted"
      >
        <UIPoster
          v-for="rowItem in item.row"
          :key="rowItem.id + contentAssemblyId"
          :data-key="rowItem.id + contentAssemblyId"
          tabindex="0"
          :class="{ [$style.contentCell]: true, ceil: true }"
          :scroll-block="scrollBlock"
          :src="contentType === CollectionContentType.ContentMoment ? rowItem.preview : rowItem.poster"
          :title="rowItem.title"
          :size="posterSize"
          @active="onActive(rowItem, item.id, index, $event)"
          @vue:activated="onPosterActivated"
          @clicked="onSelect(rowItem, item.id)"
        />
      </ui-content-row>
    </ScrollViewport>
  </section>
</template>

<script>
import useLogger from '@package/logger/src/use-logger';
import { CollectionContentType } from '@package/sdk/src/api';
import { debounce } from '@package/sdk/src/core';
import { scrollToElement } from '@package/smarttv-base/src';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys, useCatalogStore } from '@SMART/index';
import { computed, nextTick, onActivated, provide, ref, watch } from '@vue/composition-api';

import UiContentRow from '@/components/content/UiContentRow.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import UIPoster from '../poster/UIPoster.vue';
import UITypography from '../typography/UITypography.vue';

export default {
  props: {
    onLoadChunk: Function,
    contentType: String,
    emptyHeader: String,
    focusBoundaryDirections: Array,
    forceUpdate: Boolean,
    itemsPerRow: {
      type: Number,
      default: 3,
    },
    itemsPerScroll: {
      type: Number,
      default: 3,
    },
    firstLoadSize: {
      type: Number,
      default: 27,
    },
    variant: {
      type: String,
      default: 'column',
    },
    splitFirstLoad: Number,
    scrollBlock: {
      type: String,
      default: 'center',
    },
    setActiveOnMount: {
      type: Boolean,
      default: false,
    },
    isChunkLoading: Boolean,
  },
  components: {
    UiContentRow,
    ScrollViewport,
    UIPoster,
    UITypography,
  },
  setup(props, { emit }) {
    const catalogStore = useCatalogStore();
    const logger = useLogger('UIContent.vue');

    const showShimmer = computed(
      () =>
        normalizedItems.value.length < items.value.length ||
        (!normalizedItems.value.length && !items.value.length && !isContentWasLoaded.value),
    );

    const { el, focusKey, focusSelf, removeFocusable, addFocusable } = useNavigatable({
      focusKey: FocusKeys.UI_CONTENT,
      saveLastFocusedChild: true,
      hasGlobalAccess: true,
      ...(props.focusBoundaryDirections && {
        isFocusBoundary: true,
        focusBoundaryDirections: props.focusBoundaryDirections,
      }),
    });
    provide('parentFocusKey', focusKey.value);

    const isContentEmpty = computed(
      () => !isLoading.value && !isContentChanging.value && !items.value.length && isContentWasLoaded.value,
    );

    const onPosterActivated = debounce(() => {
      emit('activated');
    }, 20);

    const isContentWasLoaded = ref(false);
    const isContentWasNavigated = ref(false);

    const setContentLoaded = async () => {
      if (isContentWasLoaded.value) {
        return;
      }

      isContentWasLoaded.value = true;

      if (!items.value.length) {
        return;
      }

      addFocusable();

      if (!props.setActiveOnMount || isContentWasNavigated.value) {
        return;
      }

      await nextTick();
      focusSelf();
      isContentWasNavigated.value = true;
    };

    const momentElements = ref([]);
    const offset = ref(0);

    const scrollerRef = ref(null);

    const isLoading = ref(false);
    const isContentChanging = ref(false);
    const items = ref([]);

    const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({
      withTimeout: true,
      timeout: 1500,
    });

    const normalizedItems = computed(() => (isVNodeMounted.value ? items.value : items.value.slice(0, 1)));

    const posterSize = computed(() => (props.contentType === CollectionContentType.ContentMoment ? 'kinom' : 'medium'));

    // TODO поменять на ulid
    const contentAssemblyId = ref(Math.random());

    const getBoundariesIds = () => {
      if (!items.value.length) {
        return { firstId: 1, lastId: 1 };
      }

      return {
        firstId: Math.floor(items.value[0].id / props.itemsPerScroll) + 1,
        lastId: Math.ceil((items.value[items.value.length - 1]?.id + 1) / props.itemsPerScroll),
      };
    };

    const splitItemsIntoRows = (data, lineIndex) => {
      const rows = [];

      for (let rowIndex = 0, lindeId = lineIndex; rowIndex < data.length; rowIndex += props.itemsPerRow, lindeId++) {
        rows.push({
          row: data.slice(rowIndex, rowIndex + props.itemsPerRow),
          id: lindeId,
        });
      }

      return rows;
    };

    let isSplitted = false;

    const loadChunk = async (direction, size, page) => {
      const assemblyId = contentAssemblyId.value;

      const { data, lineIndex } = await props.onLoadChunk({
        boundaries: getBoundariesIds(),
        direction,
        size,
        page,
      });
      const rows = splitItemsIntoRows(data, lineIndex);

      if (assemblyId !== contentAssemblyId.value) {
        return;
      }

      items.value = [...items.value, ...rows];

      emit('update:items', items.value);
    };

    const loadChunks = async () => {
      try {
        isLoading.value = true;

        if (!props.splitFirstLoad) {
          return;
        }

        let chunkIndex = items.value.length
          ? Math.floor(
              props.splitFirstLoad -
                ((items.value.length * props.itemsPerRow) / props.firstLoadSize) * props.splitFirstLoad,
            )
          : props.splitFirstLoad - 1;

        let lineIndex = Math.floor(items.value.length / props.itemsPerRow) + 1;

        const size = Math.floor(props.firstLoadSize / props.splitFirstLoad);

        let itemsLoadedLength = items.value.reduce((result, row) => {
          result = result + row.row.length;
          return result;
        }, 0);

        if (itemsLoadedLength < size) {
          return;
        }

        while (chunkIndex--) {
          itemsLoadedLength = items.value.reduce((result, row) => {
            result = result + row.row.length;
            return result;
          }, 0);
          if (itemsLoadedLength < size * (lineIndex - 1)) {
            break;
          }

          await loadChunk(1, size, ++lineIndex);
        }

        isSplitted = true;
      } catch {
        isSplitted = false;
      } finally {
        window.requestAnimationFrame(() => {
          isLoading.value = false;
        });
      }
    };

    const updateItems = (rows, direction) => {
      if (direction > 0 && rows.length) {
        items.value = [...items.value, ...rows];

        if (props.splitFirstLoad && !isSplitted) {
          loadChunks();
        }
      } else if (rows.length) {
        items.value = [...rows, ...items.value.slice(0, -props.itemsPerScroll)];
      }

      emit('update:items', items.value);
    };

    const onLoadLines = async (dir, page) => {
      const assemblyId = contentAssemblyId.value;

      if (!isLoading.value || isContentChanging.value) {
        try {
          const boundaries = getBoundariesIds();

          if (dir < 0 && boundaries.firstId === 1) {
            return;
          }

          isLoading.value = true;

          const { data, lineIndex } = await props.onLoadChunk({
            boundaries,
            direction: dir,
            ...(props.splitFirstLoad &&
              !isSplitted && { size: Math.floor(props.firstLoadSize / props.splitFirstLoad) }),
            page,
          });

          if (assemblyId !== contentAssemblyId.value) {
            return;
          }

          const rows = splitItemsIntoRows(data, lineIndex);
          updateItems(rows, dir);
          setContentLoaded();

          emit('loaded', data.length);
          return data;
        } catch (e) {
          logger.info(e);
        } finally {
          window.requestAnimationFrame(() => {
            isLoading.value = false;
          });
        }
      }
    };

    watch(items, (value) => {
      if (!value.length) {
        emit('loaded', 0);
      }
    });

    const clearContent = () => {
      isContentChanging.value = true;
      isLoading.value = true;
      isLastPage.value = false;
      isContentWasLoaded.value = false;
      removeFocusable();

      items.value = [];
      // TODO поменять на ulid
      contentAssemblyId.value = Math.random();
    };

    const onReloadContent = async () => {
      clearContent();

      emit('update:items', items.value);

      try {
        scrollerRef.value?.scrollToTop();
        await onLoadLines(1, 1);
      } catch {
        setContentLoaded();
      } finally {
        isContentChanging.value = false;
        isLoading.value = false;
      }
    };

    watch(
      () => props.forceUpdate,
      (value) => {
        if (value) {
          onReloadContent();
        }
      },
    );

    const isMounted = ref(false);

    onActivated(async () => {
      if (isMounted.value && !items.value.length) {
        return onReloadContent();
      }

      if (items.value.length && !isSplitted && isMounted.value) {
        setContentLoaded();
        return loadChunks();
      }
    });

    const onSelect = (contentItem, id) => {
      catalogStore.updateSelectedItem({ value: contentItem, rowId: id });

      const flatItems = items.value.reduce((acc, item) => [...acc, ...item.row], []);
      const index = flatItems.findIndex((item) => item.id === contentItem.id);

      emit('select:moment', contentItem, index === -1 ? 0 : index);
    };

    const isLastPage = ref(false);

    const onLoadNext = async (index) => {
      if (index >= items.value.length - 4 && !isLoading.value && !isLastPage.value) {
        const page = Math.ceil(items.value.length / props.itemsPerScroll) + 1;

        const data = await onLoadLines(1, page);

        if (!data?.length) {
          isLastPage.value = true;
        }
      }
    };

    const scroll = ref(null);

    const onActive = async (contentItem, id, index, el) => {
      const rows = items.value.length;

      if (rows >= props.firstLoadSize / props.itemsPerRow && rows - index <= 3) {
        onLoadNext(index);
      }

      scrollToElement(scroll.value?.$el, { top: el?.offsetTop });

      catalogStore.updateSelectedItem({ value: contentItem, rowId: id });

      emit('active');
    };

    return {
      el,
      showShimmer,
      isContentEmpty,
      momentElements,
      offset,
      scrollerRef,
      isLoading,
      isContentChanging,
      items,
      normalizedItems,
      posterSize,
      contentAssemblyId,
      isContentWasLoaded,
      isContentWasNavigated,
      setContentLoaded,
      getBoundariesIds,
      splitItemsIntoRows,
      isSplitted,
      loadChunk,
      loadChunks,
      updateItems,
      onLoadLines,
      clearContent,
      onReloadContent,
      isMounted,
      onSelect,
      isLastPage,
      onLoadNext,
      scroll,
      onActive,
      onPosterActivated,
      onVNodeMounted,
      FocusKeys,
      CollectionContentType,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@import '@package/ui/src/styles/shimmers';

$poster-height: adjust.adjustPx(560px);

.contentCell {
  margin-right: adjust.adjustPx(16px);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &EmptyHeader {
    max-width: adjust.adjustPx(716px);

    @include smartTvFonts.SmartTvBody-2();
  }
}

.row {
  &:last-child {
    margin-bottom: 100%;
  }
}

.posters {
  display: flex;
  flex-flow: row;
  margin-bottom: adjust.adjustPx(16px);
  height: $poster-height;
  overflow: hidden;

  &Kinom {
    height: adjust.adjustPx(325px);
  }
}

.kinom {
  margin-right: adjust.adjustPx(16px);
  overflow: hidden;
}

.videoWrapper {
  outline: none;
}

.video {
  min-width: adjust.adjustPx(413px);
  max-width: adjust.adjustPx(413px);
  min-height: adjust.adjustPx(310px);
  max-height: adjust.adjustPx(310px);
  border-radius: adjust.adjustPx(35px);
  overflow: hidden;

  video {
    transform: scale(2, 2);
    min-width: adjust.adjustPx(413px);
    max-width: adjust.adjustPx(413px);
    min-height: adjust.adjustPx(310px);
    max-height: adjust.adjustPx(310px);
  }
}

.videoActive {
  border: adjust.adjustPx(7px) solid var(--color-bg-accent);
  border-radius: adjust.adjustPx(40px);
}

.scroll {
  position: relative;
}

.active {
  border: adjust.adjustPx(7) solid var(--color-bg-accent);
}
</style>
