import AbstractConstantsConfig from './abstract-constants-config';

const CONSTANTS = {
  currentActionViewHideTimeoutMs: 600,
  doubleTapPlaybackTimeoutMs: 500,

  rewindPlayTimeoutMs: 1200,
  longTapTimeoutMs: 1500,
  rewindOnboardingTimeoutMs: 5000,
  changeCurrentTimeThrottleMsWeb: 100,
  changeCurrentTimeThrottleMsSmartTV: 100,

  chromeCastScriptSrc: 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1',
  controlsVisibleSmartTvTimeoutMs: 6000, // 4500
  controlsVisibleWebDefaultTimeoutMs: 3000,
  controlsVisibleWebLinkedKinomActiveTimeoutMs: 15000, // 15000

  oneDayMs: 86400000,
  httpRequestTimeoutMs: 30000,
  videoFramesAmbientControllerTimeoutMs: 3500,
  linkedKinomShowTimeoutMs: 8000, // 8000
  adultContentWarningTimeoutMs: 8000,
  analyticTimelineSetThrottleMs: 500,

  volumeChangeTimeoutMs: 1000,
  chromecastLoadScriptTimeoutMs: 100,

  // сhunk length x 3 (default value for edge)
  liveSegmentsDelay: 3,
  liveTimeoutEdgeSeconds: 3 * 6,
  goToLiveTimeoutEdgeSeconds: 30,
  rewindTimeSecondsWebMax: 10,
  rewindTimeSecondsWebMin: 10,

  rewindTimeSecondsSmartTvMax: 5,
  rewindTimeSecondsSmartTvMin: 3,

  rewindOnboardingTimesShown: 2,

  autoPlayerStreamingMinRangeSeconds: 1,

  vrVideoWidthSegmentSize: 128,
  vrVideoHeightSegmentSize: 64,

  secondsToTheNextEpisode: 6000,

  freeContentThresholdWatchTimeFloat: 0.8,

  userWatchingItemUpdateThreshold: 0.95,

  seekedSendRemoteControlEventTimeoutMs: 2000,

  isOnboardingEnabled: false,
  seekedTimeout: 5000,
  setVolumeTimeoutDebounce: 500,
  chainExternalVolumeDebounce: 1000,
  videoInteractionAttributeName: 'data-video-interaction',

  hoverPositionShiftPx: 35,
  hoverPositionShiftForPlayerPagePx: 21,

  lowBatteryModeThresholdLevel: 0.3, // from 0.0 to 1.0
  defaultDateFormat: 'DD-MM-YYYY',
};

class PlayerConstantsConfigInstance extends AbstractConstantsConfig<typeof CONSTANTS> {
  constructor(constantsMap: typeof CONSTANTS) {
    super(constantsMap);
  }
}

const ConstantsConfigPlayer = new PlayerConstantsConfigInstance(CONSTANTS);

export default ConstantsConfigPlayer;
