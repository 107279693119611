<template>
  <div :class="$style.container">
    <div
      v-for="(column, id) in resolvedItems"
      :key="`column-${id}`"
      :class="$style.column"
      :style="{ 'max-width': columnWidthPercent }"
    >
      <div v-for="(item, index) in column" :key="`item-${index}`" :class="$style.item">
        <slot :id="`${index}:${id}`" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { toPercent } from '@package/sdk/src/core';
import { computed } from '@vue/composition-api';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    columnNumber: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const columnWidthPercent = computed(() => toPercent(100 / props.columnNumber));

    const resolvedItems = computed(() => {
      if (!props.items.length) {
        return [];
      }

      const result = [];

      for (let i = 0; i < props.items.length; i++) {
        const item = props.items[i];
        const column = i % props.columnNumber;

        if (!result[column]) {
          result[column] = [];
        }

        result[column].push(item);
      }

      return result;
    });

    return {
      columnWidthPercent,
      resolvedItems,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.container {
  display: flex;

  flex-wrap: nowrap;
  width: 100%;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.item {
  margin: {
    bottom: adjust.adjustPx(12px);
    left: adjust.adjustPx(6px);
    right: adjust.adjustPx(6px);
  }
}
</style>
