<template>
  <div ref="el" :class="$style.exitModal">
    <h1 :class="$style.exitModalTitle">{{ $t(`${translationPage}.title`) }}</h1>
    <span :class="$style.exitModalHint">{{ $t(`${translationPage}.hint`) }}</span>

    <div :class="$style.exitModalActions">
      <NavigatableItem
        :class="$style.leftButton"
        :active-class="$style.active"
        :tag="AppButton"
        :text="$t(`${translationPage}.actionClose`)"
        @click="$emit('close')"
      />
      <NavigatableItem
        :active-class="$style.active"
        :tag="AppButton"
        :text="$t(`${translationPage}.actionExit`)"
        variation="smart-button-secondary"
        @click="$emit('exit')"
      />
    </div>
  </div>
</template>

<script>
import AppButton from '../app-button/AppButton.vue';
import NavigatableItem from '../navigation/NavigatableItem.vue';

export default {
  components: {
    AppButton,
    NavigatableItem,
  },
  props: {
    translationPage: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.exitModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &Title {
    margin-bottom: adjust.adjustPx(16px);

    @include smartTvFonts.SmartTvTitle-3();
  }

  &Hint {
    margin-bottom: adjust.adjustPx(60px);

    @include smartTvFonts.SmartTvBody-1();
  }

  &Actions {
    display: flex;
  }
}

.leftButton {
  margin-right: adjust.adjustPx(24px);
}

.active {
  border-color: var(--c-hover-color);
  color: var(--color-text-link);
}
</style>
