<template>
  <NavigatableItem
    :class="[{ [$style.play]: true, [$style.primary]: variation === 'primary' }, className]"
    :active-class="activeClass ? activeClass : $style.playActive"
    :tag="AppButton"
    :extra-props="extraProps"
    :focus-key="focusKey || FocusKeys.PLAY_CONTENT_BUTTON"
    :text="buttonText || primaryButtonText"
    :disabled="isContentDisabled && shouldDisableContentWhenUnavailable"
    @click="onPlayContent"
    @active="$emit('active')"
  />
</template>

<script>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import { AvailableContentType, ContentAccessTypes, MediaContentType } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import {
  FocusKeys,
  RouterPage,
  storeToRefs,
  translateContentButtonText,
  useAuthStore,
  useContentStore,
  useMediaContentActions,
  useOfferActions,
  useSessionStore,
  useSessionVariables,
} from '@SMART/index';
import { computed, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';

export default {
  props: {
    className: String,
    activeClass: String,
    contentType: String,
    shouldDisableContentWhenUnavailable: Boolean,
    contentId: String,
    extraProps: Object,
    skipModal: Boolean,
    withAccessKind: Boolean,
    focusKey: String,
    moment: Object,
    variation: String,
    onPlayCallback: Function,
    buttonText: String,
    target: String,
  },
  setup(props, { emit, root: { $route: route } }) {
    const contentStore = useContentStore();
    const authStore = useAuthStore();
    const { openOffersPage } = useOfferActions();
    const { openPlayerPage, getContentPage, openContentPage } = useMediaContentActions();
    const { isAvailable, isUnavailableSoon } = useMediaContentAvailability();
    const { getContentPlayButtonText, getPlayButtonTextForSerial, getPlayButtonTextForMovie } =
      useContentMediaButtonTexts();

    const { isActiveSubscription, isPartnerSubscription, isPartnerUser, subscription, currentOffer, hasTrialOffer } =
      storeToRefs(useSessionStore());

    const { isAuth } = useSessionVariables();
    const { content, isLoadingContent } = storeToRefs(contentStore);

    const isUnavailableContentModalShown = ref(false);
    const availableEpisodeId = ref('');

    const isContentAvailable = computed(() => isAvailable(content.value) || isUnavailableSoon(content.value));

    const isContentDisabled = computed(
      () => !isContentAvailable.value || isLoadingContent.value || (isPartnerUser.value && !isActiveSubscription.value),
    );

    const getAvailableEpisodeId = () => {
      const serial = content.value;

      if (!serial?.seasons) {
        return;
      }

      const seasons = serial.seasons;
      const count = seasons.length;

      for (let availableSeasonIndex = 0; availableSeasonIndex < count; availableSeasonIndex++) {
        const season = seasons[availableSeasonIndex];
        if (season.availability === AvailableContentType.AVAILABLE) {
          const availableEpisodeIndex = seasons[availableSeasonIndex].episodes.findIndex(
            (episode) => episode.availability === AvailableContentType.AVAILABLE,
          );

          if (availableEpisodeIndex !== -1) {
            return {
              id: seasons[availableSeasonIndex].episodes[availableEpisodeIndex].id,
              availableEpisodeIndex,
              availableSeasonIndex,
            };
          }
        }
      }
    };

    const findSeasonEpisodeById = (episodeId) => {
      if (!episodeId) return;
      const serial = content.value;

      if (!serial?.seasons) {
        return;
      }

      const seasons = serial.seasons;
      const count = seasons.length;

      for (let seasonIndex = 0; seasonIndex < count; seasonIndex++) {
        const episodeIndex = seasons[seasonIndex].episodes.findIndex((episode) => episode.id === episodeId);

        if (episodeIndex !== -1) {
          return {
            id: seasons[seasonIndex].episodes[episodeIndex].id,
            episodeNumber: episodeIndex + 1,
            seasonNumber: seasonIndex + 1,
            episode: seasons[seasonIndex].episodes[episodeIndex],
          };
        }
      }
    };

    const primaryButtonText = computed(() => {
      const episodeInfo = getAvailableEpisodeId();
      const serial = content.value;
      const isSerial = serial?.seasons;

      let episodeData;
      let episodeAccessKind;

      const tvChannelData = {
        tvChannelSlug: content.value?.tvChannelSlug,
        timeshiftEndTime: content.value?.timeshiftEndTime,
      };

      if (isSerial && episodeInfo) {
        const season = serial.seasons[episodeInfo?.availableSeasonIndex];
        const episode = season.episodes[episodeInfo?.availableEpisodeIndex];
        episodeAccessKind = episode.accessKind;

        episodeData = {
          season: season.number,
          episode: episode.number,
        };

        tvChannelData.tvChannelSlug =
          tvChannelData.tvChannelSlug || season.episodes[episodeInfo?.availableEpisodeIndex]?.tvChannelSlug;
        tvChannelData.timeshiftEndTime =
          tvChannelData.timeshiftEndTime || season.episodes[episodeInfo?.availableEpisodeIndex]?.timeshiftEndTime;
      }

      const watchingItem = findSeasonEpisodeById(content.value?.watchingItem?.id);
      tvChannelData.tvChannelSlug = tvChannelData.tvChannelSlug || watchingItem?.episode?.tvChannelSlug;
      tvChannelData.timeshiftEndTime = tvChannelData.timeshiftEndTime || watchingItem?.episode?.timeshiftEndTime;

      const contentPlayButtonText = props.target
        ? isSerial
          ? getPlayButtonTextForSerial
          : getPlayButtonTextForMovie
        : getContentPlayButtonText;

      return translateContentButtonText(
        contentPlayButtonText({
          isActiveSubscription: isActiveSubscription.value,
          isAuth: isAuth.value,
          isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
          accessKind: props.withAccessKind ? episodeAccessKind : undefined,
          offer: currentOffer.value,
          canContinueWatch: isDefined(content.value?.watchingItem),
          episodeData,
          watchingItem: content.value?.watchingItem
            ? {
                ...content.value?.watchingItem,
                ...watchingItem,
              }
            : undefined,
          target: props.target,
          content: content.value,
          tvChannelData,
        }),
      );
    });

    const onPlayContent = async () => {
      if (!content.value) {
        throw new UnexpectedComponentStateError('content');
      }

      const isSerial = props.contentType
        ? route.params.type === MediaContentType.Serial || props.contentType === MediaContentType.Serial
        : content.value.contentType !== MediaContentType.Movie;

      const serial = content.value;

      const firstSeason = serial?.seasons && serial.seasons[0];
      const firstEpisode = firstSeason?.episodes && firstSeason.episodes[0];

      let episodeId = isSerial
        ? content.value.watchingItem?.contentId || availableEpisodeId.value || firstEpisode?.id || props.contentId
        : undefined;

      let seasonIndex = props.moment?.seasonNumber ? String(props.moment.seasonNumber) : undefined;
      let episodeIndex = props.moment?.episodeNumber ? String(props.moment.episodeNumber) : undefined;

      const isRedirectToContentPage = [
        RouterPage.MyChannelPage,
        RouterPage.MomentsPage,
        RouterPage.MediaCardPage,
      ].includes(route.name);

      if (isRedirectToContentPage) {
        authStore.setBackUrl(
          getContentPage({ id: content.value.id, contentType: content.value.contentType, title: '' }),
        );
      }

      if (
        !isActiveSubscription.value &&
        (isPartnerSubscription.value || firstEpisode?.accessKind !== ContentAccessTypes.AllUsers)
      ) {
        return openOffersPage();
      }

      if (isSerial && !serial?.watchingItem && serial && !availableEpisodeId.value) {
        const seasons = serial?.seasons;

        if (seasons && !isAvailable(firstEpisode)) {
          const result = getAvailableEpisodeId();
          episodeId = result?.id;
        }
      }

      let willContinue = isSerial && serial?.watchingItem;

      if (willContinue) {
        const isEpisodeAvailable = serial.seasons.find((season) =>
          season.episodes.find((episode) => {
            if (episode.id === serial.watchingItem?.contentId) {
              return episode.availability === AvailableContentType.AVAILABLE;
            } else {
              return false;
            }
          }),
        );

        willContinue = isEpisodeAvailable;

        if (!isEpisodeAvailable) {
          const result = getAvailableEpisodeId();
          episodeId = result?.id;
          seasonIndex = String(result?.availableSeasonIndex);
          episodeIndex = String(result?.availableEpisodeIndex);
        }
      }

      if (!isContentAvailable.value) {
        if (isActiveSubscription.value) {
          return openContentPage({
            contentType: content.value.contentType,
            id: content.value.id,
            title: content.value.title,
          });
        }

        return openOffersPage();
      }

      isUnavailableContentModalShown.value = false;

      emit('on-play-content');

      if (props.onPlayCallback) {
        return props.onPlayCallback();
      }

      return openPlayerPage({
        id: content.value.id,
        kinomId: props.moment?.id,
        kinomTitle: props.moment?.title,
        episodeId,
        ...(!willContinue && {
          seasonIndex,
          episodeIndex,
        }),
        title: content.value.title,
        contentType: content.value.contentType,
      });
    };

    return {
      primaryButtonText,
      isContentDisabled,
      onPlayContent,
      AppButton,
      FocusKeys,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.play {
  display: flex;
  align-items: center;

  &Active {
    background-color: var(--color-bg-accent);
  }

  svg {
    display: inline-flex;
    margin-right: adjustPx(15px);
    width: adjustPx(34px);
    height: adjustPx(34px);
  }
}

.primary {
  border-color: transparent;
  background-color: var(--color-bg-button-secondary);
  overflow: hidden;

  &.active,
  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: var(--color-bg-accent);
  }
}

.modalUnavailable {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--color-bg-secondary-60);

  &Title {
    width: adjustPx(800px);
    font-weight: 500;
    font-size: adjustPx(48px);
    text-align: center;
  }

  &Navigation {
    display: flex;
    flex-direction: column;
    margin-top: adjustPx(119px);

    &Control {
      button {
        min-width: adjustPx(287px);

        &::after {
          box-shadow: none !important;
        }
      }

      &:first-child {
        margin-bottom: adjustPx(20px);
      }
    }
  }
}
</style>
