export const tizenUserAgents = {
  '7.0': {
    value:
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 7.0) AppleWebKit/537.36 (KHTML, like Gecko) 94.0.4606.31/7.0 TV Safari/537.36',
    version: 7,
  },
  '6.5': {
    value:
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.5) AppleWebKit/537.36 (KHTML, like Gecko) 85.0.4183.93/6.5 TV Safari/537.36',
    version: 6.5,
  },
  '6.0': {
    value:
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.0) AppleWebKit/537.36 (KHTML, like Gecko) 76.0.3809.146/6.0 TV Safari/537.36',
    version: 6,
  },
  '5.5': {
    value:
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.5) AppleWebKit/537.36 (KHTML, like Gecko) 69.0.3497.106.1/5.5 TV Safari/537.36',
    version: 5.5,
  },
  '5.0': {
    value:
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/5.0 TV Safari/537.36',
    version: 5,
  },
  '4.0': {
    value:
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 TV Safari/537.36',
    version: 4,
  },
  '3.0': {
    value: 'Mozilla/5.0 (SMART-TV; LINUX; Tizen 3.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/3.0 TV Safari/538.1',
    version: 3,
  },
  '2.4': {
    value:
      'Mozilla/5.0 (SMART-TV; Linux; Tizen 2.4.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/2.4.0 TV Safari/538.1',
    version: 2.4,
  },
  '2.3': {
    value:
      'Mozilla/5.0 (SMART-TV; Linux; Tizen 2.2) AppleWebkit/538.1 (KHTML, like Gecko) SamsungBrowser/1.0 TV Safari/538.1',
    version: 2.3,
  },
};

export function getTizenVersionByUserAgent(userAgent: string): string {
  try {
    const agents = userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Tizen') {
        return String(parseFloat(agents[i + 1]));
      }
    }

    return 'Tizen Unknown';
  } catch (error) {
    return 'Tizen Unknown';
  }
}
