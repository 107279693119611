<template>
  <div :class="$attrs.class">
    <template v-if="isLoading">
      <div v-for="line in lines" :key="line" :class="[$style.shimmer, loadingClass, $style[shimmerVariant]]" />
    </template>
    <div v-else><slot /></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Number,
      default: 1,
    },
    loadingClass: {
      type: String,
      default: '',
    },
    shimmerVariant: {
      type: String,
      default: 'body',
      validator: (value) => ['title', 'subtitle', 'body'].includes(value),
    },
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/shimmers' as shimmers;
@import '@package/ui/src/styles/shimmers';

.title {
  @include shimmers.SmartTvShimmerTitle();
}

.subtitle {
  @include shimmers.SmartTvShimmerSubtitle();
}
.body {
  @include shimmers.SmartTvShimmerBody();
}
</style>
