<template>
  <input
    :class="{
      [$style.input]: true,
      [$style.inputBorderless]: Boolean(borderless),
    }"
    :placeholder="placeholder"
    :disabled="disabled"
    :type="type"
    :value="text"
  />
</template>

<script>
import { inputMask } from '@SMART/index';
import { computed } from '@vue/composition-api';

export default {
  props: {
    disabled: { type: Boolean },
    placeholder: { type: String },
    type: { type: String, default: 'text' },
    input: { type: String },
    borderless: { type: Boolean },
    inputMask: { type: String },
  },
  setup(props, { emit }) {
    const text = computed({
      get: () => (props.inputMask ? inputMask(props.inputMask, props.input) : props.input),
      set: (value) => emit('update:input', value),
    });

    return { text };
  },
};
</script>

<style module lang="scss">
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '@/styles/colors';

.input {
  box-sizing: border-box;
  padding: 0 adjustPx(24px);
  min-height: adjustPx(83px);
  border: none;
  border-radius: adjustPx(10px);
  outline: none;
  background-color: var(--color-state-bg-secondary-disabled);
  color: var(--color-text-primary);

  @include f-body;

  &Borderless {
    padding: 0;
    border: 1px solid var(--color-state-bg-secondary-disabled);
    border-width: 0 0 1px 0;
    border-radius: 0;
    background-color: transparent;
    color: var(--color-text-quaternary);
  }
}
input::placeholder {
  color: var(--color-text-quaternary);
}
</style>
