<template>
  <section ref="el" :class="$style.help">
    <BackButton v-if="!isAuth" :on-click="() => $emit('close')" :focus-key="FocusKeys.BACK_BUTTON_MODAL" />

    <help-contacts-text-block @vue:mounted="onVNodeMounted" />

    <div :class="$style.qrWrapper">
      <help-contacts-qr-block v-if="isVNodeMounted" />
    </div>
  </section>
</template>

<script>
import { FocusKeys } from '@package/smarttv-base/src';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { useSessionVariables } from '@SMART/index';
import { onMounted } from '@vue/composition-api';

import BackButton from '@/components/back-button/BackButton.vue';
import HelpContactsQrBlock from '@/pages/settings/components/HelpContactsQrBlock.vue';
import HelpContactsTextBlock from '@/pages/settings/components/HelpContactsTextBlock.vue';

export default {
  components: {
    BackButton,
    HelpContactsQrBlock,
    HelpContactsTextBlock,
  },
  setup() {
    const { isAuth } = useSessionVariables();
    const { isVNodeMounted, onVNodeMounted } = useVNodeMounted();

    onMounted(() => {
      onVNodeMounted();
    });

    return {
      isAuth,
      isVNodeMounted,
      onVNodeMounted,
      FocusKeys,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.help {
  min-height: adjust.adjustPx(394px) !important;
}

.qrWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: adjust.adjustPx(474px);
}
</style>
