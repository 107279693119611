<template>
  <div :class="$style.card">
    <div :class="$style.poster">
      <app-image v-if="poster" :key="poster" :src="poster" :width="332" />
    </div>

    <div :class="$style.content">
      <p v-if="subtitle.length" :class="$style.subtitle">
        <span :class="{ [$style.subtitleText]: true, [$style.subtitleTextShadow]: subtitle.length > 50 }">
          {{ subtitle }}
        </span>

        <span v-if="limit" :class="$style.limit">
          {{ limit }}
        </span>
      </p>

      <h3 :class="$style.title">
        {{ title }}
      </h3>

      <p :class="$style.description">
        {{ description }}
      </p>

      <div :class="$style.controls">
        <div :class="$style.saveContainer">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.save"
            :active-class="$style.active"
            @click="isAddedToCollection ? onRemoveCollectionItem() : onSaveCollectionItem()"
            @active="onSaveActive(true)"
            @inactive="onSaveActive(false)"
          >
            <template v-if="isAddedToCollection" #icon>
              <IconCheck />
            </template>
            <template v-else #icon>
              <IconSave />
            </template>
          </NavigatableItem>

          <div :class="$style.tooltip">
            <UITooltip v-show="shouldShowHelp" :message="$t(saveToCollectionKey)" position="bottom" />
          </div>
        </div>

        <NavigatableItem
          :focus-key="FocusKeys.SELECT_MEDIA_BUTTON"
          :tag="AppButton"
          :class="$style.select"
          :active-class="$style.active"
          :disabled="disabled"
          :text="contentTitle"
          @click="onSelectContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import IconCheck from '@SMART/assets/icons/40x40/check.svg';
import IconSave from '@SMART/assets/icons/40x40/save.svg';
import {
  AlertMessageTypes,
  alertService,
  collectionService,
  FocusKeys,
  storeToRefs,
  translate,
  useCatalogStore,
  useContentStore,
  useMediaContentActions,
} from '@SMART/index';
import { computed, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import UITooltip from '@/components/tooltip/UITooltip.vue';

export default {
  props: {
    id: String,
    contentType: [String, Object],
    title: String,
    subtitle: String,
    description: String,
    limit: String,
    poster: String,
    disabled: Boolean,
  },
  components: {
    AppButton,
    AppImage,
    UITooltip,
    IconCheck,
    IconSave,
  },
  setup(props) {
    const catalogStore = useCatalogStore();
    const contentStore = useContentStore();

    const { openContentPage } = useMediaContentActions();

    const { content } = storeToRefs(contentStore);

    const isAddedToCollection = ref(
      content.value?.inUserCollection || collectionService.savedFilmsItems.includes(props.id),
    );
    const shouldShowHelp = ref(false);

    const saveToCollectionKey = computed(() =>
      isAddedToCollection.value ? 'pages.mediaCard.removeFromCollection' : 'pages.mediaCard.saveToCollection',
    );

    const contentTitle = computed(() => {
      if (props.contentType === MediaContentType.Serial) {
        return translate('pages.catalog.goToSerial');
      }

      return translate('pages.catalog.goToMovie');
    });

    const onSelectContent = () => {
      return openContentPage({
        contentType: props.contentType,
        id: props.id,
        title: props.title,
        isRandomItem: true,
      });
    };

    const onSaveCollectionItem = async () => {
      try {
        await collectionService.saveItems([props.id], props.contentType);
        const type = props.contentType === MediaContentType.Movie ? translate('movie') : translate('serial');

        isAddedToCollection.value = true;
        // update in order to reload user collection
        catalogStore.setUpdated(true);

        alertService.addAlert({
          message: translate('pages.mediaCard.addedToCollection', { type }),
          type: AlertMessageTypes.Success,
        });
      } catch (e) {
        alertService.addAlert({ message: translate(e.message), type: AlertMessageTypes.Error });
      }
    };

    const onRemoveCollectionItem = async () => {
      // update in order to reload user collection
      catalogStore.setUpdated(true);
      await collectionService.removeItems([props.id]);
      isAddedToCollection.value = false;
    };

    const onSaveActive = (value) => {
      shouldShowHelp.value = value;
    };

    watch(
      () => props.id,
      () => {
        isAddedToCollection.value =
          content.value?.inUserCollection || collectionService.savedFilmsItems.includes(props.id);
      },
    );

    return {
      isAddedToCollection,
      shouldShowHelp,
      saveToCollectionKey,
      contentTitle,
      onSelectContent,
      onSaveCollectionItem,
      onRemoveCollectionItem,
      onSaveActive,
      FocusKeys,
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';

.card {
  display: flex;
  flex: 1;
  padding: adjust.adjustPx(64px);
  width: 100%;
  border-radius: adjust.adjustPx(36px);
  background-color: var(--color-bg-secondary-60);
}

.poster {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: adjust.adjustPx(330px);
  height: adjust.adjustPx(460px);
  border-radius: adjust.adjustPx(40px);
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  flex-flow: column;
  padding-left: adjust.adjustPx(64px);
}

.subtitle {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: var(--color-text-tertiary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvCaption-1();

  &Text {
    position: relative;
    display: inline-flex;
    max-width: adjust.adjustPx(750px);
    overflow: hidden;
    white-space: nowrap;

    &Shadow {
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: adjust.adjustPx(69px);
        background: linear-gradient(270deg, rgba(0, 17, 19, 1) 0%, rgba(0, 17, 19, 0.04) 88.64%);
        content: '';
      }
    }
  }
}

.limit {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-left: adjust.adjustPx(16px);
  width: adjust.adjustPx(47px);
  height: adjust.adjustPx(23px);
  border: adjust.adjustPx(2px) solid var(--color-text-secondary);
  border-radius: adjust.adjustPx(8px);
  font-size: adjust.adjustPx(18px);
}

.title {
  display: -webkit-box;
  margin-top: adjust.adjustPx(8px);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @include smartTvFonts.SmartTvSubtitle-1();
}

.description {
  display: -webkit-box;
  margin-top: adjust.adjustPx(24px);
  overflow: hidden;
  color: var(--color-text-secondary);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @include smartTvFonts.SmartTvBody-2();
}

.controls {
  position: relative;
  display: flex;
  margin-top: auto;
  padding-top: adjust.adjustPx(32px);
}

.active {
  background-color: var(--color-bg-accent);
}

.saveContainer {
  position: relative;
}

.select {
  margin-left: adjust.adjustPx(16px);
}

.save {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: adjust.adjustPx(83px);
  height: adjust.adjustPx(83px);

  svg {
    display: inline-flex;
    width: adjust.adjustPx(34px);
    height: adjust.adjustPx(34px);
  }
}

.tooltip {
  position: absolute;
  left: adjust.adjustPx(32px);

  div > div {
    top: adjust.adjustPx(12px);
    left: adjust.adjustPx(-248px);
  }
}
</style>
