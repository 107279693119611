<template>
  <section :class="$style.code">
    <UITypography :class="$style.header" shimmer-variant="title" :is-loading="isLoading">
      {{ $t('pages.auth.qrLogin.codeTitle') }}
    </UITypography>

    <UITypography :class="$style.hintBlock" shimmer-variant="body" :is-loading="isLoading" :lines="2">
      <span v-for="index in 5" :key="index" :class="{ [$style.hintHighlighted]: index % 2 == 0 }">
        {{ $t(`pages.auth.qrLogin.codeHintPart${index}`, { code_url: codeUrl }) }}
      </span>
    </UITypography>

    <div>
      <UITypography :class="[$style.hint, $style.codeEnter]" shimmer-variant="body" :is-loading="isLoading">
        {{ $t('pages.auth.qrLogin.codeEnter') }}
      </UITypography>
      <UITypography :class="$style.codeValue" shimmer-variant="title" :is-loading="isLoading">
        {{ code }}
      </UITypography>
    </div>
  </section>
</template>

<script>
import UITypography from '@/components/typography/UITypography.vue';

export default {
  props: {
    codeUrl: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UITypography,
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.header {
  margin-bottom: adjust.adjustPx(16px);

  @include smartTvFonts.SmartTvTitle-3();
}

.code {
  width: 35%;
}

.codeEnter {
  margin-bottom: adjust.adjustPx(16px);
}

.codeValue {
  @include smartTvFonts.SmartTvTitle-3();
}

.hint {
  line-height: adjust.adjustPx(52px);
  color: var(--color-text-tertiary);
}

.hintBlock {
  margin-bottom: adjust.adjustPx(48px);
  min-height: adjust.adjustPx(165px);
  line-height: adjust.adjustPx(52px);
  color: var(--color-text-tertiary);
}

.hintHighlighted {
  color: var(--color-text-primary);
}
</style>
