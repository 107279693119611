<template>
  <section ref="el" :class="$style.page">
    <div :class="$style.pageContainer">
      <header :class="$style.tabs">
        <ContentSelect
          ref="contentTabs"
          :disabled="isLoading"
          :content-type-prop="contentType"
          @update:type="onChangeContent"
          @update:filters="onChangeFilters"
        />
      </header>

      <UIContent
        scroll-block="start"
        :on-load-chunk="onLoadCollectionChunk"
        :content-type="contentType"
        :items-per-row="itemsPerRow"
        :items-per-scroll="itemsPerScroll"
        :first-load-size="firstLoadSize"
        :force-update="shouldUpdateContent"
        :class="$style.content"
        :style="{ top: contentOffset }"
        :empty-header="$t('pages.catalog.empty')"
        :split-first-load="1"
        set-active-on-mount
        :is-chunk-loading="isFirstChunkLoading"
        @activated="onActivatedContent"
        @select:moment="onSelectContent"
      >
        <template #not-found>
          <div :class="$style.notFound">
            <IconEmptySearch :class="$style.notFoundIcon" />

            <div :class="$style.notFoundTitle">
              <p>{{ $t('pages.catalog.emptySearch1') }}</p>
              <p>{{ $t('pages.catalog.emptySearch2') }}</p>
            </div>

            <NavigatableItem
              :active-class="$style.active"
              :focus-key="FocusKeys.CATALOG_RESET_FILTERS_EMPTY_CONTENT"
              :prop-parent-focus-key="FocusKeys.CATALOG_PAGE"
              :tag="AppButton"
              :text="$t('pages.catalog.resetFilters')"
              @click="contentTabs.resetFilters"
            />
          </div>
        </template>
      </UIContent>
    </div>
  </section>
</template>

<script>
import { ItemPageFrom, useCatalogPageAnalytics } from '@package/sdk/src/analytics';
import { MediaContentType } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconEmptySearch from '@SMART/assets/icons/33x33/empty-search.svg';
import {
  adjustPx,
  analyticService,
  catalogService,
  FocusKeys,
  RouterPage,
  useCatalogStore,
  useMediaContentActions,
} from '@SMART/index';
import { computed, nextTick, onActivated, onMounted, provide, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIContent from '@/components/content/UIContent.vue';
import { useUiContent } from '@/components/content/useUiContent';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import ContentSelect from './components/ContentSelect.vue';

export default {
  components: {
    AppButton,
    UIContent,
    NavigatableItem,
    ContentSelect,
    IconEmptySearch,
  },
  name: RouterPage.CatalogPage,
  setup(_, { root: { $route: route } }) {
    const { el, focusKey, focusSelf } = useNavigatable({ focusKey: FocusKeys.CATALOG_PAGE, autoRestoreFocus: true });
    provide('parentFocusKey', focusKey.value);

    const catalogStore = useCatalogStore();

    const { openContentPage } = useMediaContentActions();
    const catalogPageAnalytics = useCatalogPageAnalytics(analyticService.sender);

    let contentLoaded = false;

    const shouldUpdateContent = ref(false);
    const filters = ref({ genres: [], countries: [], periods: [] });

    const shuffleModalOpened = computed(() => contentTabs.value?.isShuffleModalVisible);

    const contentTabs = ref();
    const pagePadding = adjustPx(60) + 'px';
    const menuWidth = adjustPx(128) + 'px';

    const contentOffset = computed(() => {
      return adjustPx(contentTabs.value?.filterItems.length ? 308 : 188) + 'px';
    });

    const isContentChanging = async () => {
      if (shouldUpdateContent.value) {
        contentLoaded = false;
      }

      return shouldUpdateContent.value;
    };

    const onSelect = (content, _, index) => {
      openContentPage({
        title: content.title,
        contentType: content.contentType,
        id: content.id,
        from: ItemPageFrom.Catalog,
        position: index,
      });
    };

    const fetchItems = async (type, params) => {
      if (type === MediaContentType.MOVIE) {
        return await catalogService.fetchMovies({
          ...filters.value,
          ...params,
        });
      }

      if (type === MediaContentType.SERIAL) {
        return await catalogService.fetchSerials({
          ...filters.value,
          ...params,
        });
      }

      if (type === MediaContentType.ALL) {
        return await catalogService.fetchAll({
          ...filters.value,
          ...params,
        });
      }

      return [];
    };

    const {
      contentType,
      firstLoadSize,
      itemsPerRow,
      itemsPerScroll,
      isLoading,
      isFirstChunkLoading,
      onLoadCollectionChunk,
      onSelectItem,
    } = useUiContent({
      onSelect,
      isContentChanging,
      onLoaded: () => {
        shouldUpdateContent.value = false;
      },
      fetchItems,
      initialLoadSize: 20,
      itemsNumber: {
        perRow: 5,
        perScroll: 4,
      },
    });

    onActivated(async () => {
      catalogPageAnalytics.onShowCatalogPage();

      if (shuffleModalOpened.value) {
        SpatialNavigation.setFocus(FocusKeys.SHUFFLE_MODAL);
        return;
      }

      if (lastNavigationFocusKey.value) {
        SpatialNavigation.setFocus(lastNavigationFocusKey.value);
        lastNavigationFocusKey.value = undefined;
      } else {
        SpatialNavigation.setFocus(FocusKeys.UI_CONTENT);
      }
    });

    const onChangeContent = (value) => {
      shouldUpdateContent.value = false;

      if (contentType.value !== value) {
        catalogService.abort('onChangeContent');
      }
      contentType.value = value;
      lastNavigationFocusKey.value = undefined;

      window.setTimeout(async () => {
        shouldUpdateContent.value = true;
      }, 10);
    };

    const onChangeFilters = (value) => {
      const oldValueNormalized = JSON.stringify(filters.value);
      const newValueNormalized = JSON.stringify(value);
      filters.value = value;
      shouldUpdateContent.value = false;
      if (oldValueNormalized !== newValueNormalized) {
        catalogService.abort('onChangeFilters');
      }

      window.setTimeout(() => {
        shouldUpdateContent.value = true;
      }, 10);
    };

    const onActivatedContent = async () => {
      if (contentLoaded) {
        return;
      }

      contentLoaded = true;
      if (lastNavigationFocusKey.value) {
        SpatialNavigation.setFocus(lastNavigationFocusKey.value);
      } else {
        SpatialNavigation.setFocus(FocusKeys.UI_CONTENT);
      }
    };

    const lastNavigationFocusKey = ref();

    const onSelectContent = (content, index) => {
      lastNavigationFocusKey.value = SpatialNavigation.getCurrentFocusKey();
      onSelectItem(content, index);
    };

    onMounted(async () => {
      await nextTick();
      catalogStore.updateSelectedItem(null);
    });

    return {
      el,
      contentTabs,
      shouldUpdateContent,
      filters,
      shuffleModalOpened,
      pagePadding,
      menuWidth,
      contentOffset,
      isLoading,
      isFirstChunkLoading,
      onLoadCollectionChunk,
      onSelectContent,
      onChangeContent,
      onChangeFilters,
      onActivatedContent,
      FocusKeys,
      AppButton,
      contentType,
      itemsPerRow,
      itemsPerScroll,
      firstLoadSize,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding: {
    left: adjust.adjustPx(128px);
  }
}

.pageContainer {
  position: absolute;

  display: flex;
  flex-direction: column;

  width: calc(100% - adjust.adjustPx(60px) - adjust.adjustPx(60px));
  height: 100%;
  padding: {
    top: adjust.adjustPx(60px);
    left: adjust.adjustPx(60px);
    right: adjust.adjustPx(60px);
  }
}

.tabs {
  display: flex;
  margin-bottom: adjust.adjustPx(32px);
  width: 100%;
}

.content {
  position: fixed;
  // top: v-bind(contentOffset);
  display: flex;
  width: calc(100% - adjust.adjustPx(128px) - adjust.adjustPx(60px));
  height: calc(100% - adjust.adjustPx(188px));
  overflow: hidden;

  :global(.row) {
    margin-bottom: adjust.adjustPx(24px);
  }

  :global(.ceil) {
    margin-right: adjust.adjustPx(24px);
  }
}

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: adjust.adjustPx(200px);

  .notFoundIcon {
    width: adjust.adjustPx(48px);
    height: adjust.adjustPx(48px);
  }

  .notFoundTitle {
    margin-top: adjust.adjustPx(32px);
    margin-bottom: adjust.adjustPx(40px);
    text-align: center;

    @include smartTvFonts.SmartTvHeadline-2();
  }
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}
</style>
