<template>
  <div role="tooltip" :class="$style.help">
    <IconTriangle
      :class="{
        [$style.icon]: true,
        [$style.rightIcon]: position === 'right',
      }"
    />
    <div
      :class="{
        [$style.text]: true,
        [$style.rightText]: position === 'right',
      }"
    >
      <span :class="$style.message">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import IconTriangle from '@SMART/assets/icons/33x33/triangle.svg';

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
      validator: (value) => ['bottom', 'right'].includes(value),
    },
  },
  components: {
    IconTriangle,
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';
@import '@/styles/fonts';

.help {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-pseudo-elements);
  width: fit-content;
}

.icon {
  position: absolute;
  width: adjustPx(23px);
  height: adjustPx(14px);
}

.text {
  position: absolute;
  display: table;
  padding: adjustPx(14px) adjustPx(24px);
  border-color: var(--color-stroke-input-border);
  border-radius: adjustPx(70px);
  background-color: var(--color-stroke-input-border);
  color: var(--color-text-primary);
  white-space: nowrap;

  @include f-body;
}

.message {
  @include f-body;
}

.rightIcon {
  top: adjustPx(25px);
  transform: rotate(-90deg);
}

.rightText {
  top: adjustPx(-1px);
  left: adjustPx(15px);
}
</style>
