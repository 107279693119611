import type { Country } from '../../api/content/types/country';
import type { Genre } from '../../api/content/types/genre';
import type { Year } from '../../api/content/types/year';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';

export function useCatalogPageAnalytics(sender: AnalyticEventSender) {
  const getSlugs = (filters: (Country | Genre | Year)[]) => filters.map((filter) => filter.slug);

  const onClickCatalogFilterCountrySet = (filters: Country[]) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogFilterCountrySet,
      page: AnalyticPageName.Catalog,
      values: [
        {
          label: AnalyticPayloadLabelName.Filter,
          value: getSlugs(filters),
        },
      ],
    });
  };

  const onClickCatalogFilterCountryRemove = (filters: Country[]) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogFilterCountryRemove,
      page: AnalyticPageName.Catalog,
      values: [
        {
          label: AnalyticPayloadLabelName.Filter,
          value: getSlugs(filters),
        },
      ],
    });
  };

  const onClickCatalogFilterGenreSet = (filters: Genre[]) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogFilterGenreSet,
      page: AnalyticPageName.Catalog,
      values: [
        {
          label: AnalyticPayloadLabelName.Filter,
          value: getSlugs(filters),
        },
      ],
    });
  };

  const onClickCatalogFilterGenreRemove = (filters: Genre[]) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogFilterGenreRemove,
      page: AnalyticPageName.Catalog,
      values: [
        {
          label: AnalyticPayloadLabelName.Filter,
          value: getSlugs(filters),
        },
      ],
    });
  };

  const onClickCatalogFilterPeriodSet = (filters: Year[]) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogFilterPeriodSet,
      page: AnalyticPageName.Catalog,
      values: [
        {
          label: AnalyticPayloadLabelName.Filter,
          value: getSlugs(filters),
        },
      ],
    });
  };

  const onShowCatalogPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowCatalogPage,
      page: AnalyticPageName.Catalog,
    });
  };

  const onGotoCatalogPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.GotoCatalogPage,
      page: sender.getCurrentAnalyticPageValue(),
    });
  };

  const onClickCatalogFilterPeriodRemove = (filters: Year[]) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogFilterPeriodRemove,
      page: AnalyticPageName.Catalog,
      values: [
        {
          label: AnalyticPayloadLabelName.Filter,
          value: getSlugs(filters),
        },
      ],
    });
  };

  const onClickCatalogMovies = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogMovies,
      page: AnalyticPageName.Catalog,
    });
  };

  const onClickCatalogSeries = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickCatalogSeries,
      page: AnalyticPageName.Catalog,
    });
  };

  return {
    onGotoCatalogPage,
    onShowCatalogPage,
    onClickCatalogSeries,
    onClickCatalogMovies,
    onClickCatalogFilterCountryRemove,
    onClickCatalogFilterGenreSet,
    onClickCatalogFilterCountrySet,
    onClickCatalogFilterGenreRemove,
    onClickCatalogFilterPeriodSet,
    onClickCatalogFilterPeriodRemove,
  };
}
