<template>
  <div :class="$style.container">
    <template v-if="isLoading">
      <div :class="[$style.shimmer, shimmerClass]" :style="{ width: widthPx }" />
    </template>
    <img
      v-if="!isLoading && (isFallback || !hasError)"
      data-image
      :width="width"
      :src="normalizedSrc"
      :loading="loading"
      :alt="alt"
      v-bind="$attrs"
      :class="{ [$style.fallback]: isFallback, ...imageClasses }"
      @error="handleError"
    />
  </div>
</template>

<script>
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import IconPhoto from '@SMART/assets/icons/51x51/placeholder.svg?inline';
import { computed, ref, watch } from '@vue/composition-api';

export default {
  props: {
    src: String,
    alt: String,
    width: {
      type: Number,
      default: 200,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    useFallbackIcon: {
      type: Boolean,
      default: true,
    },
    isLoading: Boolean,
    shimmerClass: String,
  },
  inheritAttrs: true,
  setup(props) {
    const { getCDNLink, isCDNLink } = useCDNImage();

    const hasError = ref(false);
    const handleError = () => {
      hasError.value = true;
    };

    const isFallback = computed(() => (!props.src || hasError.value) && props.useFallbackIcon);
    const widthPx = computed(() => props.width + 'px');

    const normalizedSrc = computed(() => {
      if (isFallback.value) {
        return IconPhoto;
      }

      if (isCDNLink(props.src)) {
        return props.src;
      }

      return getCDNLink(props.src, props.width);
    });

    const imageClasses = ref([]);

    watch(
      () => props.src,
      () => (hasError.value = false),
    );

    return {
      imageClasses,
      hasError,
      handleError,
      isFallback,
      widthPx,
      normalizedSrc,
      props,
    };
  },
  mounted() {
    this.imageClasses = [...this.$el.classList].reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: true,
      };
    }, {});
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@package/ui/src/styles/shimmers';

.container {
  height: 100%;
}

.fallback {
  transform: none !important;
  align-self: center;
  width: adjustPx(44.891px) !important;
  height: adjustPx(44.891px) !important;
  border-radius: 0;
}
</style>
