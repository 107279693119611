<template>
  <div :class="$style.container">
    <div
      v-for="alert in alertService.alerts.value"
      :key="alert.message"
      :class="{
        [$style.message]: true,
        [$style.messageError]: [AlertMessageTypes.ErrorVoiceSearch, AlertMessageTypes.Error].includes(alert.type),
        [$style.messageSuccess]: alert.type === AlertMessageTypes.Success,
        [$style.messageWarning]: alert.type === AlertMessageTypes.Warning,
      }"
    >
      <slot name="icon">
        <div v-if="!alert.hideIcon" :class="$style.icon">
          <AlertCircleIcon v-if="alert.type === AlertMessageTypes.Error" />
          <AudioIcon v-if="alert.type === AlertMessageTypes.ErrorVoiceSearch" :class="$style.iconAudio" />
          <AlertCircleIcon v-if="alert.type === AlertMessageTypes.Warning" />
          <CheckIcon v-if="alert.type === AlertMessageTypes.Success" :class="$style.iconSuccess" />
        </div>
      </slot>
      <slot name="text">
        <span>{{ alert.message }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@SMART/assets/icons/40x40/check.svg';
import AlertCircleIcon from '@SMART/assets/icons/51x51/alert-circle.svg';
import AudioIcon from '@SMART/assets/icons/51x51/audio.svg';
import { AlertMessageTypes, alertService } from '@SMART/index';

import UIModal from '@/components/modal/UIModal.vue';

export default {
  components: {
    UIModal,
    CheckIcon,
    AlertCircleIcon,
    AudioIcon,
  },
  setup() {
    return {
      alertService,
      AlertMessageTypes,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.message {
  position: fixed;
  top: adjust.adjustPx(80px);
  right: adjust.adjustPx(24px);
  z-index: map-get($map: $layers, $key: --z-index-alert);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: adjust.adjustPx(28px);
  padding-right: adjust.adjustPx(28px);
  min-height: adjust.adjustPx(120px);
  border-radius: adjust.adjustPx(20px);
  word-break: break-all;

  @include smartTvFonts.SmartTvBody-1();

  &Error {
    border: adjust.adjustPx(4px) solid var(--color-bg-negative);
    background: var(--color-bg-notification-negative);
    color: var(--color-bg-negative);
  }

  &Success {
    border: adjust.adjustPx(4px) solid var(--color-bg-accent);
    background: var(--color-bg-notification-positive);
    color: var(--color-bg-accent);
  }

  &Warning {
    border: adjust.adjustPx(4px) solid var(--color-bg-negative);
    background: var(--color-bg-notification-negative);
    color: var(--color-bg-negative);
  }
}

.icon {
  display: flex;
  align-items: center;
  margin-right: adjust.adjustPx(16px);

  &Success {
    width: adjust.adjustPx(40px);
    height: adjust.adjustPx(40px);
  }

  &Warning {
    width: adjust.adjustPx(40px);
    height: adjust.adjustPx(40px);
  }

  &Audio {
    width: adjust.adjustPx(40px);
    height: adjust.adjustPx(40px);
  }
}
</style>
