// @ts-nocheck

import type { Logger } from '@package/logger/src/logger/logger';
import { isUndefinedOrNull } from '@package/sdk/src/core';
import { getTizenVersionByUserAgent, tizenUserAgents } from '@package/sdk/src/core/smarttv/tizen/tizen-user-agents';

namespace TizenOS {
  export interface ApplicationInstance {
    exit(): void;
    appInfo: {
      id: string;
    };
  }

  export interface TVInputDeviceService {
    registerKey(key: string): void;
  }

  export interface ApplicationService {
    getCurrentApplication(): ApplicationInstance;
  }

  export interface NetworkService {
    isConnectedToGateway(): boolean;
  }

  export interface Instance {
    tvinputdevice: TVInputDeviceService;
    application: ApplicationService;
    network: NetworkService;
  }
}

declare global {
  interface Window {
    tizen: TizenOS.Instance;
  }
}

export const TIZEN_CHROME_VERSION = {
  '8': 108,
  '7': 94,
  '6.5': 85,
  '6': 76,
  '5.5': 69,
  '5': 63,
  '4': 56,
  '3': 47,
};

export const VENDOR_NAME = 'Samsung';
export const DEFAULT_DEVICE_NAME = `${VENDOR_NAME}`;
export const DEFAULT_APP_ID = 'samsung.unknown.appId';

export const registerTizenKeys = [
  'MediaPlay',
  'MediaPause',
  'MediaStop',
  'MediaRewind',
  'MediaFastForward',
  'ChannelUp',
  'ChannelDown',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const getSafeHumanReadableModelName = (logger: Logger) => {
  if (isUndefinedOrNull(window.webapis)) {
    return DEFAULT_DEVICE_NAME;
  }

  try {
    const realModelName = window.webapis.productinfo.getRealModel();
    const realModelCode = window.webapis.productinfo.getModelCode();

    if (realModelName) {
      return `${VENDOR_NAME} ${realModelName} ${realModelCode}`;
    }
  } catch (error) {
    logger.error(error);

    return DEFAULT_DEVICE_NAME;
  }

  return DEFAULT_DEVICE_NAME;
};

export const getSafeApplicationId = () => {
  try {
    const id = window.tizen.application.getCurrentApplication().appInfo.id;

    if (id) {
      return id;
    }

    return DEFAULT_APP_ID;
  } catch (error) {
    return DEFAULT_APP_ID;
  }
};

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('Tizen 7.0 return 7', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['7.0'].value)).toBe('7');
  });

  it('Tizen 6.0 return 6', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['6.0'].value)).toBe('6');
  });

  it('Tizen 6.5 return 6.5', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['6.5'].value)).toBe('6.5');
  });

  it('Tizen 6.0 return 6', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['6.0'].value)).toBe('6');
  });

  it('Tizen 5.5 return 5.5', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['5.5'].value)).toBe('5.5');
  });

  it('Tizen 5.0 return 5', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['5.0'].value)).toBe('5');
  });

  it('Tizen 4.0 return 4', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['4.0'].value)).toBe('4');
  });

  it('Tizen 3.0 return 3', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['3.0'].value)).toBe('3');
  });

  it('Tizen 2.4 return 2.4', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['2.4'].value)).toBe('2.4');
  });

  it('Tizen 2.3 return 2.3', () => {
    expect(getTizenVersionByUserAgent(tizenUserAgents['2.3'].value)).toBe('2.2');
  });

  it('None Tizen platform return 0', () => {
    expect(getTizenVersionByUserAgent('broken user agent')).toBe('Tizen Unknown');
  });
}
