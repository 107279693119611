<template>
  <FullScreenModal>
    <div :class="$style.modal">
      <h1 :class="$style.modalTitle">{{ $t('pages.subscription.partnerSubscriptionModalTitle') }}</h1>

      <p :class="$style.modalSubtitle">{{ $t('pages.subscription.partnerSubscriptionModalSubtitle') }}</p>

      <div :class="$style.modalControls">
        <NavigatableItem
          :tag="AppButton"
          :focus-key="FocusKeys.CHANNELS_MODAL_CLOSE"
          :text="$t('pages.subscription.partnerSubscriptionModalButtonOk')"
          @click="$emit('close')"
        />
      </div>
    </div>
  </FullScreenModal>
</template>

<script>
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys } from '@SMART/index';
import { onMounted, provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import FullScreenModal from '@/components/modal/FullScreenModal.vue';

export default {
  components: {
    AppButton,
    FullScreenModal,
  },
  setup() {
    const emit = (event) => {
      if (event === 'close') {
        this.$emit('close');
      }
    };

    const { focusKey } = useNavigatable({
      focusKey: FocusKeys.CHANNELS_MODAL,
      isFocusBoundary: true,
      saveLastFocusedChild: true,
      focusBoundaryDirections: ['right'],
    });

    provide('parentFocusKey', focusKey.value);

    onMounted(() => {
      SpatialNavigation.setFocus(FocusKeys.CHANNELS_MODAL_CLOSE);
    });

    return {
      FocusKeys,
      AppButton,
      emit,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: adjust.adjustPx(1000px);

  &Title {
    display: flex;
    justify-content: center;

    @include smartTvFonts.SmartTvTitle-3();
  }

  &Subtitle {
    display: flex;
    justify-content: center;
    margin-top: adjust.adjustPx(16px);
    text-align: center;

    @include smartTvFonts.SmartTvBody-1();
  }

  &Controls {
    display: flex;
    justify-content: center;
    margin-top: adjust.adjustPx(60px);
  }
}
</style>
