import {
  type ContentAccessKind,
  type Episode,
  type Media,
  MediaContentType,
  type Subscription,
  type TVChannelAccessKind,
  type WatchingItem,
} from '@package/sdk/src/api';
import type { OfferV2, PromoOfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { isDefined } from '@package/sdk/src/core';

import useMediaContentAvailability from './use-media-content-availability';

interface EpisodeData {
  season?: number;
  episode?: number;
}

interface SubscribePromoTexts {
  subscribeButtonText?: string;
  subscribeButtonSubtext?: string;
}
export type PlayerTextTarget = 'SeriesMenu' | 'PromoSubModal' | 'TvChannels' | 'MediaCard' | 'MyChannel';
export interface TvData {
  tvChannelSlug?: string;
  timeshiftEndTime?: string;
}

interface GetContentPlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth?: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: ContentAccessKind;
  offer: OfferV2;
  promoOffer?: PromoOfferV2;
  canContinueWatch: boolean;
  episodeData?: EpisodeData;
  watchingItem?: WatchingItem & { episode?: Partial<Episode> };
  target?: string;
  tvChannelData?: TvData;
  content?: Media;
}

interface GetLivePlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: TVChannelAccessKind;
  offer: OfferV2;
  subscribePromoTexts?: SubscribePromoTexts;
  hasTrialOffer?: boolean;
  promoOffer?: PromoOfferV2;
  subscription?: Subscription;
}

interface GetTVSliderButtonTextOptions {
  isAuth: boolean;
  accessKind?: TVChannelAccessKind;
}

interface GetMyChannelPlayButtonTextOptions {
  isForceSimpleWatchButtonText?: boolean;
  contentType?: MediaContentType;
}

interface GetFreeEpisodePopupButtonTextOptions {
  offer: OfferV2;
  promoOffer?: PromoOfferV2;
}

export interface TranslateArgs {
  key: string;
  params?: Record<string, string | number>;
}

export interface TPluralArgs {
  key: string;
  count?: number | string;
  params?: Record<string, string | number>;
}

export default function useContentMediaButtonTexts() {
  const DEFAULT_TRIAL_PRICE = 1;
  const DEFAULT_TRIAL_DURATION = 14;

  const passTranslateArgs = (key: string, params?: EpisodeData | TvData) => {
    return { key, params };
  };

  const passTPluralArgs = (key: string, count: number, params?: Record<string, string | number>) => {
    return { key, count, params };
  };

  const getContentPlayButtonText = (options: GetContentPlayButtonTextOptions) => {
    const {
      isActiveSubscription,
      isPartnerSubscription,
      accessKind,
      offer,
      promoOffer,
      canContinueWatch,
      episodeData,
    } = options;

    if (isPartnerSubscription && !isActiveSubscription) {
      return passTranslateArgs('contentPage.watchAfterSub');
    }

    if (!isActiveSubscription && accessKind === 'all_users') {
      return passTranslateArgs('button.watchForFree');
    }

    if (isActiveSubscription) {
      if (canContinueWatch) {
        return passTranslateArgs('button.continueWatch');
      }

      if (episodeData?.episode && episodeData?.season) {
        return passTranslateArgs('button.watchEpisode', episodeData);
      }

      return passTranslateArgs('button.watchMovie');
    }

    if (promoOffer?.watchingButtonTitlePromo) {
      return promoOffer?.watchingButtonTitlePromo;
    }

    if (offer?.watchingButtonTitle) {
      return offer?.watchingButtonTitle;
    }

    return passTranslateArgs('button.watchDefault');
  };

  const getPlayButtonTextForMovie = (options: GetContentPlayButtonTextOptions) => {
    const { isAuth, canContinueWatch, isActiveSubscription, isPartnerSubscription, tvChannelData, content } = options;

    const { isAvailable } = useMediaContentAvailability();

    /**
     * Неавторизованный пользователь/пользователь без подписки
     */

    if (!isActiveSubscription || !isAuth) {
      /**
       * Для партнерских юзеров,
       * у которых нет возможности оформить подписку через нас
       */
      if (isPartnerSubscription) {
        return passTranslateArgs('button.watchMovie');
      }

      /**
       * Для фильма и сериала, который доступен еще Х дней
       * Для фильма и сериала, если включен VPN/юзер зарубежом
       */
      return getContentPlayButtonText(options);
    }

    /**
     * Авторизованный пользователь с подпиской
     */

    /**
     * Когда нет лицензии (не важно начинал смотреть или нет),
     * но есть на ТВ канале
     */
    if (!isAvailable(content) && tvChannelData?.tvChannelSlug) {
      return passTranslateArgs('button.watchOnTv', tvChannelData);
    }

    if (!isAvailable(content)) {
      return passTranslateArgs('button.watchMovie');
    }

    /**
     * Уже смотрел, остановился, лицензия есть,
     * поэтому продолжает с того же места, где остановился
     */
    if (canContinueWatch && isAvailable(content)) {
      return passTranslateArgs('button.continueWatch');
    }

    /**
     * Еще не смотрел, есть лицензия
     */
    return passTranslateArgs('button.watchMovie');
  };

  const getPlayButtonTextForSerial = (options: GetContentPlayButtonTextOptions) => {
    const {
      isAuth,
      canContinueWatch,
      isActiveSubscription,
      isPartnerSubscription,
      episodeData,
      watchingItem,
      tvChannelData,
      content,
    } = options;

    const { isAvailable } = useMediaContentAvailability();

    /**
     * Неавторизованный пользователь/пользователь без подписки
     */

    if (!isActiveSubscription || !isAuth) {
      /**
       * Для партнерских юзеров,
       * у которых нет возможности оформить подписку через нас
       */
      if (isPartnerSubscription) {
        return passTranslateArgs('button.watchSerial');
      }

      /**
       * Для фильма и сериала, который доступен еще Х дней
       * Для фильма и сериала, если включен VPN/юзер зарубежом
       */
      return getContentPlayButtonText(options);
    }

    /**
     * Авторизованный пользователь с подпиской
     */

    const isStart = episodeData && episodeData.season === 1 && episodeData.episode === 1;

    /**
     * Когда нет лицензии (не важно начинал смотреть или нет),
     * но есть на ТВ канале
     */
    if (!isAvailable(content) && tvChannelData?.tvChannelSlug) {
      return passTranslateArgs('button.watchOnTv', tvChannelData);
    }

    if (!isAvailable(content)) {
      return passTranslateArgs('button.watchSerial');
    }

    /**
     * Еще не смотрел,
     * есть 1 серия 1 сезона
     * и начинает с нее воспроизводиться
     */
    if (!canContinueWatch && isStart) {
      return passTranslateArgs('button.watchSerial');
    }

    /**
     * Еще не смотрел,
     * но нет 1 серии 1 сезона, поэтому предупреждаем,
     * что смотреть начнет с первой доступной Х серии Х сезона
     */
    if (!canContinueWatch && !isStart) {
      return passTranslateArgs('button.watchEpisode', episodeData);
    }

    /**
     * Уже начинал смотреть,
     * но нет серии на которой остановился (текущей или следующей),
     * поэтому показываем с какой начнет смотреть
     */
    if (canContinueWatch && !isAvailable(watchingItem?.episode as Media)) {
      return passTranslateArgs('button.watchEpisode', episodeData);
    }

    /**
     * Уже смотрел, остановился, лицензия есть,
     * поэтому продолжает с той же серии, где остановился
     */
    if (canContinueWatch && watchingItem && isAvailable(watchingItem?.episode as Media)) {
      return passTranslateArgs('button.continueEpisode', {
        season: watchingItem.seasonNumber!,
        episode: watchingItem.episodeNumber!,
      });
    }

    return passTranslateArgs('button.watchEpisode', episodeData);
  };

  const getLivePlayButtonText = (options: GetLivePlayButtonTextOptions) => {
    const { isActiveSubscription, isAuth, isPartnerSubscription, accessKind, offer, promoOffer } = options;

    if (isActiveSubscription) {
      return passTranslateArgs('button.watchLive');
    }

    if (isAuth && !isActiveSubscription) {
      const canWatchContent = isDefined(accessKind) && (accessKind === 'register' || accessKind === 'all_users');

      if (canWatchContent || isPartnerSubscription) {
        return passTranslateArgs('button.watchLive');
      }
    }

    if (isDefined(accessKind) && accessKind === 'register') {
      return passTranslateArgs('button.registerToWatchChannel');
    }

    if (isDefined(accessKind) && accessKind === 'all_users') {
      return passTranslateArgs('button.watchLive');
    }

    if (promoOffer?.watchingButtonTitlePromo) {
      return promoOffer?.watchingButtonTitlePromo;
    }

    if (offer?.watchingButtonTitle) {
      return offer?.watchingButtonTitle;
    }

    return passTranslateArgs('button.watchDefault');
  };

  const getTVSliderButtonText = (options: GetTVSliderButtonTextOptions) => {
    const { accessKind, isAuth } = options;

    if (accessKind === 'all_users') {
      return passTranslateArgs('button.watchLive');
    }

    if (accessKind === 'register') {
      if (!isAuth) {
        return passTranslateArgs('button.registerToWatchChannel');
      }
    }

    return passTranslateArgs('button.watchLive');
  };

  const getMyChannelPlayButtonText = (options: GetMyChannelPlayButtonTextOptions) => {
    const { isForceSimpleWatchButtonText, contentType } = options;

    if (isForceSimpleWatchButtonText) {
      return passTranslateArgs('button.watch');
    }

    if (contentType) {
      return passTranslateArgs(contentType === MediaContentType.MOVIE ? 'button.watchMovie' : 'button.watchSerial');
    }

    return passTranslateArgs('button.watch');
  };

  const getFreeEpisodePopupButtonText = (options: GetFreeEpisodePopupButtonTextOptions) => {
    const { offer, promoOffer } = options;

    if (promoOffer?.watchingButtonTitlePromo) {
      return promoOffer?.watchingButtonTitlePromo;
    }

    if (offer?.watchingButtonTitle) {
      return offer?.watchingButtonTitle;
    }

    return passTranslateArgs('button.watchDefault');
  };

  return {
    getContentPlayButtonText,
    getLivePlayButtonText,
    getTVSliderButtonText,
    getMyChannelPlayButtonText,
    getFreeEpisodePopupButtonText,
    DEFAULT_TRIAL_DURATION,
    DEFAULT_TRIAL_PRICE,
    passTPluralArgs,
    getPlayButtonTextForSerial,
    getPlayButtonTextForMovie,
  };
}
