<template>
  <section ref="container" :class="$style.wrapper">
    <h2 :class="$style.title">{{ title }}</h2>

    <div ref="el" :class="$style.collectionsWrapper">
      <ScrollViewport tag="ul" :class="$style.collections" :x="offsetLeftPx" role="list">
        <li v-for="(item, index) in items" :key="item.id" :class="$style.collection">
          <NavigatableItem
            :tag="AppButton"
            :data-testid="`collections-${index + 1}`"
            :data-index="index"
            :prop-parent-focus-key="FocusKeys.MEDIA_CARD_COLLECTIONS_SLIDER"
            :text="item.title"
            @active="(element) => handleActiveCollection(element)"
            @click="onSelectCollection(index)"
          />
        </li>
      </ScrollViewport>
    </div>

    <MediaCardCollectionsSliderList :items="currentCollectionContent" @active="handleActiveList" />
  </section>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys, useContentStore, useSliderOffset } from '@SMART/index';
import { computed, provide, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import MediaCardCollectionsSliderList from './MediaCardCollectionsSliderList.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    AppButton,
    NavigatableItem,
    ScrollViewport,
    MediaCardCollectionsSliderList,
  },
  setup(props, { emit }) {
    const { el, focusKey } = useNavigatable({
      focusKey: 'MEDIA_CARD_COLLECTIONS_SLIDER',
      saveLastFocusedChild: true,
      hasGlobalAccess: true,
      isFocusBoundary: true,
      focusBoundaryDirections: ['right'],
    });
    provide('parentFocusKey', focusKey.value);

    const contentStore = useContentStore();
    const { content } = contentStore;

    const { handleUpdateOffset, offsetLeftPx } = useSliderOffset();

    const container = ref(null);
    const activeCollectionIndex = ref(0);

    const currentCollection = computed(() => props.items[activeCollectionIndex.value]);
    const currentCollectionContent = computed(() =>
      currentCollection.value.contents.filter((contentItem) => contentItem.id !== content.value.id),
    );

    const handleActiveCollection = (element) => {
      handleUpdateOffset(element, el.value?.offsetWidth);
      emit('activated', container.value?.offsetTop || 0);
    };

    const handleActiveList = () => {
      emit('activated', container.value?.offsetTop || 0);
    };

    const onSelectCollection = (index) => {
      activeCollectionIndex.value = index;
    };

    return {
      el,
      container,
      offsetLeftPx,
      currentCollectionContent,
      handleActiveCollection,
      handleActiveList,
      onSelectCollection,
      AppButton,
      NavigatableItem,
      ScrollViewport,
      MediaCardCollectionsSliderList,
      FocusKeys,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(32px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
  height: 200px;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: adjust.adjustPx(32px);

  width: adjust.adjustPx(376px);
  min-width: adjust.adjustPx(376px);
  height: adjust.adjustPx(508px);
  min-height: adjust.adjustPx(508px);
  border-radius: var(--g-border-round-24);
  outline: none;
  background-color: var(--color-bg-tertiary);

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(516px);
    left: adjust.adjustPx(8px);

    display: -webkit-box;
    width: adjust.adjustPx(376px);
    min-width: adjust.adjustPx(376px);

    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}

.collectionsWrapper {
  margin-bottom: adjust.adjustPx(32px);
}

.collections {
  display: flex;
}

.collection {
  margin-right: adjust.adjustPx(24px);
  height: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(80px);
  border-radius: adjust.adjustPx(16px);
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--g-border-round-24);
  outline: none;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--g-border-round-24);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}
</style>
