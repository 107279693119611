<template>
  <canvas ref="canvasRef" :class="$style.codeCanvas" />
</template>

<script>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { qrService, userService } from '@package/smarttv-base/src';
import { onMounted, ref } from '@vue/composition-api';

export default {
  setup() {
    const canvasRef = ref(null);

    const setupQRCode = async () => {
      if (!canvasRef.value) {
        throw new UnexpectedComponentStateError('canvasRef');
      }

      await qrService.createQRCode({
        canvasEl: canvasRef.value,
        text: userService.getUserInformationForEmailSupport(),
      });
    };

    onMounted(setupQRCode);

    return {
      canvasRef,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.codeCanvas {
  display: flex;
  align-self: center;
  margin-top: adjust.adjustPx(80px);
  width: adjust.adjustPx(394px) !important;
  min-width: adjust.adjustPx(394px) !important;
  height: adjust.adjustPx(394px) !important;
  min-height: adjust.adjustPx(394px) !important;
  border-radius: adjust.adjustPx(16px);
}
</style>
