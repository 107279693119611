<template>
  <button :class="{ [$style.button]: true, [$style.selected]: selected }">
    <!-- <div>

    </div> -->
    <span :class="$style.text">{{ text }}</span>
    <IconCheckmark v-if="selected" :class="$style.icon" />
  </button>
</template>

<script>
import IconCheckmark from '@SMART/assets/icons/24x24/checkmark.svg';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    IconCheckmark,
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.button {
  display: flex;
  align-items: center;
  padding: adjust.adjustPx(16px) adjust.adjustPx(24px);
  width: 100%;
  border: adjust.adjustPx(4px) solid transparent;
  border-radius: adjust.adjustPx(24px);
  outline: none;
  background-color: transparent;
  color: var(--color-text-primary);
  text-align: start;
}

.selected {
  border: adjust.adjustPx(4px) solid var(--color-stroke-active);
}

.text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}
</style>
