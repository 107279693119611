import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { Disposable, EventEmitter } from '@package/sdk/src/core';

import type { DeviceService } from '../device/device-service';
import { OperationSystem } from '../device/device-types';
import globalEmitter from '../global-emitter/global-emitter';

interface LifecycleEventMap {
  'will-shutdown': void;
  'app-mounted': void;
}

const isClient = typeof window !== 'undefined';

export class LifecycleService extends Disposable {
  private emitter: EventEmitter<LifecycleEventMap> = new EventEmitter<LifecycleEventMap>();
  private isEnabled = true;
  private isInitialized = false;

  constructor(private readonly deviceService: DeviceService) {
    super();

    if (isClient) {
      this.registerListeners();
    }

    window.setTimeout(
      () => this.onAppLoaded(),
      ConstantsConfigInstanceSmartTV.getProperty('appInitializationTimeoutMs'),
    );
  }

  public onWillShutdown(callback: VoidFunction) {
    return this.emitter.on('will-shutdown', callback);
  }

  private registerListeners() {
    if (!this.isEnabled) {
      return;
    }

    this.emitter.on('app-mounted', () => this.handleAppLoaded());

    const isDesktop = this.deviceService.os === OperationSystem.Desktop;
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;

      this.emitter.emit('will-shutdown');
    };

    if (!isDesktop) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }
  }

  public onAppLoaded() {
    if (this.isInitialized) {
      return;
    }

    this.emitter.emit('app-mounted');
    globalEmitter.emit('app-mounted');
  }

  public handleAppLoaded() {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;

    setTimeout(() => {
      const splashScreenElement = document.getElementById('splash');
      splashScreenElement?.remove();
    }, 50);
  }
}
