<template>
  <div data-smart-tv-control>
    <NavigatableItem :tag="UIButton" variation="default" has-global-access :on-click="onClick" :class="$style.button">
      <div :class="$style.buttonSlot">
        <p :class="$style.title">{{ skipToMomentText }}</p>

        <p v-if="isLinkedKinomTargetContent" :class="{ [$style.subtitle]: true }">
          {{ startOffsetTime }}
        </p>
        <p v-else :class="{ [$style.subtitle]: true }">
          {{ episodeText }}
        </p>
      </div>
    </NavigatableItem>
  </div>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import { translate } from '@SMART/index';
import { computed } from '@vue/composition-api';

import UIButton from '@/components/button/UIButton.vue';

export default {
  props: {
    kinom: { type: Object, default: () => ({}) },
    activeEpisodeIndex: {
      type: Number,
      default: 0,
    },
    activeSeasonIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const skipToMomentText = translate('player.skipToMoment');
    const episodeText = translate('player.episodeNumber', {
      season: props.kinom?.seasonNumber || 0,
      episode: props.kinom?.episodeNumber || 0,
    });

    const rawStartTime = computed(() => props.kinom.startOffset);
    const startOffsetTime = computed(() => {
      const hours = Math.floor(rawStartTime.value / 3600);
      const minutes = Math.floor(rawStartTime.value / 60);
      const seconds = Math.floor((rawStartTime.value % 3600) % 60);

      const hoursText = String(hours).padStart(2, '0');
      const minutesText = String(minutes).padStart(2, '0');
      const secondsText = String(seconds).padStart(2, '0');
      return `${hoursText}:${minutesText}:${secondsText}`;
    });
    const isLinkedKinomTargetContent = computed(() => {
      if (props.kinom?.contentType === MediaContentType.Movie) {
        return true;
      }
      const episodeIndex = Number(props.activeEpisodeIndex || 0);
      const kinomEpisodeIndex = Number(props.kinom?.episodeNumber || 0);
      const seasonIndex = Number(props.activeSeasonIndex || 0);
      const kinomSeasonIndex = Number(props.kinom?.seasonNumber || 0);
      return kinomSeasonIndex === seasonIndex && kinomEpisodeIndex === episodeIndex;
    });

    const onClick = () => {
      emit('seek', rawStartTime.value);
    };

    return {
      skipToMomentText,
      rawStartTime,
      startOffsetTime,
      episodeText,
      UIButton,
      onClick,
      isLinkedKinomTargetContent,
    };
  },
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.button {
  display: flex;
  align-items: center;
  margin-left: adjust.adjustPx(24px);
  padding-bottom: adjust.adjustPx(13px);
  border: none;
  border-radius: adjust.adjustPx(18px);
  outline: none;
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);

  @include smartTvFonts.SmartTvLabel-2();
}

.buttonSlot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include smartTvFonts.SmartTvLabel-2();
}

.subtitle {
  @include smartTvFonts.SmartTvBody-3();

  color: var(--color-notheme-text-additional);
}

.focused {
  color: unset;
}
</style>
