<template>
  <section ref="el" :class="$style.keyboard">
    <slot name="input" :type="inputType" :input="text" :placeholder="placeholder">
      <div :class="$style.input">
        <UIInput
          :input="text"
          :class="{
            [$style.keyboardInput]: true,
            [$style.secondary]: inputVariant === 'secondary',
          }"
          :placeholder="placeholder"
          :type="inputType"
          :borderless="!text"
          :input-mask="inputMask"
          disabled
        />
      </div>
    </slot>

    <div v-if="errorMessage" :class="[$style.error, errorClass]">
      {{ errorMessage }}
    </div>

    <transition :name="transitionName">
      <div :class="$style.keys">
        <div :class="$style.suggestions">
          <template v-if="suggestions">
            <NavigatableItem
              v-for="suggestion in suggestions"
              :key="suggestion"
              :tag="UIButton"
              :class="$style.suggestionsItem"
              :on-click="() => onAppend(suggestion)"
              @active="$emit('active')"
            >
              {{ suggestion }}
            </NavigatableItem>
          </template>
        </div>

        <CustomKeyboard
          v-show="!isKeyboardHidden"
          :items="currentKeyboard"
          :upper-case="upperCaseLocal"
          :symbols-upper-case="symbolsUpperCase"
          :item-width="itemWidth"
          @key:common="onInput"
          @key:control="onControlKey"
          @active="$emit('active')"
        />
      </div>
    </transition>
  </section>
</template>

<script>
import { debounce, isString } from '@package/sdk/src/core';
import useTransitionName from '@package/smarttv-base/src/utils/use-transition-name';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys } from '@SMART/index';
import { computed, nextTick, onMounted, provide, ref } from '@vue/composition-api';

import UIButton from '@/components/button/UIButton.vue';
import UIInput from '@/components/input/UIInput.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import CustomKeyboard from '@/components/virtual-keyboard/CustomKeyboard.vue';

export default {
  props: {
    keyboards: Array,
    input: String,
    debounceMs: Number,
    upperCase: Boolean,
    symbolsUpperCase: Boolean,
    placeholder: String,
    isKeyboardHidden: Boolean,
    itemWidth: Number,
    suggestions: Array,
    inputType: String,
    onConcatSuggestion: Function,
    inputMask: String,
    inputVariant: String,
    maxLength: Number,
    errorMessage: String,
    errorClass: String,
  },
  components: {
    NavigatableItem,
    CustomKeyboard,
    UIInput,
  },
  setup(props, { emit }) {
    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.VIRTUAL_KEYBOARD,
      trackChildren: true,
      updateLayoutOnFocus: true,
      saveLastFocusedChild: true,
    });
    provide('parentFocusKey', focusKey.value);

    const { transitionName } = useTransitionName();

    const upperCaseLocal = ref(props.upperCase);

    const resolvedKeyboards = computed(() => {
      return props.keyboards.map((keyboard) => {
        return {
          type: keyboard.type,
          keys: keyboard.keys.map((row) => row.map((key) => (isString(key) ? { key } : key))),
        };
      });
    });

    const text = computed({
      get: () => props.input,
      set: (value) => emit('update:input', value),
    });

    const onInputRaw = (key) => {
      const value = props.input + key;

      emit('update:input', props.maxLength ? value.slice(0, props.maxLength) : value);
      emit('patch:input', key);
    };

    const onInput = props?.debounceMs ? debounce(onInputRaw, props.debounceMs) : onInputRaw;

    const onControlKeyRaw = (item) => {
      item.callback && item.callback();
    };

    const onControlKey = props?.debounceMs ? debounce(onControlKeyRaw, props.debounceMs) : onControlKeyRaw;

    const onAppend = (value) => {
      const { onConcatSuggestion: concat } = props;

      const input = concat ? concat(props.input, value) : props.input + value;

      emit('update:input', input);
    };

    const type = ref(resolvedKeyboards.value[0].type);

    const currentKeyboard = computed(() => {
      return (
        resolvedKeyboards.value.find((keyboard) => keyboard.type === type.value)?.keys ??
        resolvedKeyboards.value[0].keys
      );
    });

    const doActionWithPositionSave = async (callback, preferredFocusKey) => {
      const nextFocusKey = preferredFocusKey || SpatialNavigation.getCurrentFocusKey();

      callback();
      await nextTick();

      SpatialNavigation.setFocus(nextFocusKey);
    };

    const changeType = async (newType) => {
      doActionWithPositionSave(() => {
        type.value = newType;
      });
    };

    const setUpperCase = async (value) => {
      doActionWithPositionSave(() => {
        upperCaseLocal.value = value;
      });
    };

    onMounted(() => {
      emit('vue:mounted');
    });

    return {
      el,
      focusKey,
      transitionName,
      upperCaseLocal,
      resolvedKeyboards,
      text,
      onInput,
      onControlKey,
      onAppend,
      type,
      currentKeyboard,
      changeType,
      setUpperCase,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@mixin item($width) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: adjust.adjustPx(3px);
  padding: adjust.adjustPx(5px) adjust.adjustPx(14px);
  min-width: adjust.adjustPx($width);
  max-width: adjust.adjustPx($width);
  min-height: adjust.adjustPx(68px);
  max-height: adjust.adjustPx(68px);
  border: adjust.adjustPx(3px) solid var(--c-keyboard-color);
  border-radius: adjust.adjustPx(6px);
  background: var(--c-keyboard-color);
}

.input {
  display: flex;
}

.keyboard {
  &Input {
    width: 100%;
  }

  .secondary {
    padding-left: adjust.adjustPx(24px);
    border-radius: adjust.adjustPx(10px);
    background: var(--color-bg-input);
  }

  &Layout {
    display: flex;
    flex-flow: row;
    width: adjust.adjustPx(840px);
    height: adjust.adjustPx(290px);
  }
}

.error {
  margin-top: adjust.adjustPx(8px);
  color: var(--color-text-negative);

  @include smartTvFonts.SmartTvCaption-1();
}

.keys {
  margin-top: adjust.adjustPx(32px);
  width: min-content;
}

.suggestions {
  display: flex;

  &Item {
    @include item(222px);
  }

  &Item:last-child {
    @include item(145px);
  }
}
</style>
