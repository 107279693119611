<template>
  <div ref="el" :class="$style.wrapper">
    <div
      :class="{ [$style.background]: true, [$style.backgroundTransition]: enableBackgroundTransition }"
      :style="{ backgroundImage: `url(${channelBackground})` }"
    >
      <div :class="$style.gradient" />
    </div>

    <div ref="channelsContainer" :class="$style.channels">
      <ScrollViewport tag="ul" :class="$style.list" :y="offsetTopPx" role="list" @vue:mounted="onVNodeMounted">
        <li v-for="(channel, index) in channels" :key="channel.id" ref="list">
          <NavigatableItem
            :tag="AppSlotButton"
            :focus-key="FocusKeys.CHANNEL(index)"
            :class="{
              [$style.item]: true,
              [$style.itemSelected]: activeChannel && activeChannel.id === channel.id,
            }"
            @active="handleActiveItem(channel, list, index)"
            @click="handleChannelClick(channel)"
          >
            <app-image :class="$style.logo" :src="channel.logo" :width="330" />
          </NavigatableItem>
        </li>
      </ScrollViewport>
    </div>

    <div v-if="isVNodeMounted" :class="$style.details">
      <h2
        v-if="activeChannel && activeChannel.currentProgram && activeChannel.currentProgram.title"
        :class="$style.title"
      >
        {{ activeChannel && activeChannel.currentProgram && activeChannel.currentProgram.title }}
      </h2>

      <div v-if="activeChannel && activeChannel.currentProgram.startTime" :class="$style.time">
        {{
          getTvProgramStartTime(activeChannel && activeChannel.currentProgram && activeChannel.currentProgram.startTime)
        }}
        —
        {{
          getTvProgramEndTime(
            activeChannel && activeChannel.currentProgram.startTime,
            activeChannel && activeChannel.currentProgram.duration,
          )
        }}
      </div>

      <div
        v-if="isShowAboutMovie && activeChannel && activeChannel.currentProgram && activeChannel.lockedInfo"
        :class="$style.message"
      >
        {{ activeChannel.lockedInfo }}
      </div>

      <div v-if="isShowAboutMovie && activeChannel && activeChannel.currentProgram" :class="$style.controls">
        <NavigatableItem
          :tag="AppButton"
          :focus-key="FocusKeys.CHANNELS_CONTROL_OPEN"
          :active-class="$style.controlsActive"
          :text="activeChannelButtonText"
          :disabled="activeChannel && activeChannel.lockedInfo"
          @click="handleWatchClick"
        />
      </div>

      <div v-if="false" :class="$style.price" />

      <footer
        v-if="
          activeChannel && activeChannel.availability && activeChannel.nextProgram && activeChannel.nextProgram.title
        "
        :class="$style.footer"
      >
        <div :class="$style.footerTitle">
          {{ $t('pages.channels.nextMovieText') }}
        </div>

        <div :class="$style.program">
          <div
            v-if="activeChannel && activeChannel.nextProgram && activeChannel.nextProgram.startTime"
            :class="$style.programTime"
          >
            {{
              getTvProgramStartTime(
                (activeChannel && activeChannel.nextProgram && activeChannel.nextProgram.startTime) || '',
              )
            }}
          </div>

          <div :class="$style.programTitle">
            {{ activeChannel && activeChannel.nextProgram && activeChannel.nextProgram.title }}
          </div>
        </div>
      </footer>
    </div>

    <PartnerSubscriptionModal v-if="partnerSubscriptionModalShown" @close="closePartnerSubscriptionModal" />
  </div>
</template>

<script>
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { useTvPageAnalytics } from '@package/sdk/src/analytics';
import { ContentAccessTypes, MediaContentType } from '@package/sdk/src/api';
import { DisposableStore, indexOutOfRange, TvKeyCode, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import DefaultChannelsBackground from '@SMART/assets/images/default-channels-background.webp';
import {
  analyticService,
  channelsService,
  FocusKeys,
  keyboardEventHandler,
  RouterPage,
  routerService,
  storeToRefs,
  useCatalogStore,
  useMediaContentActions,
  useSessionStore,
  useSessionVariables,
  useTvChannelActions,
  useTvChannelsStore,
} from '@SMART/index';
import { computed, onActivated, onBeforeUnmount, onMounted, provide, ref } from '@vue/composition-api';
import { addSeconds, format, isValid, parseISO } from 'date-fns';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import { useImage } from '@/utils/use-image';

import PartnerSubscriptionModal from './components/PartnerSubscriptionModal.vue';

export default {
  components: {
    AppButton,
    AppImage,
    AppSlotButton,
    ScrollViewport,
    PartnerSubscriptionModal,
  },
  name: RouterPage.ChannelsPage,
  setup(_, { root: { $route: route } }) {
    const catalogStore = useCatalogStore();
    const tvChannelsStore = useTvChannelsStore();
    const tvPageAnalytics = useTvPageAnalytics(analyticService.sender);

    const { onVNodeMounted, isVNodeMounted } = useVNodeMounted({ withTimeout: true });

    const { selectedItem } = storeToRefs(catalogStore);
    const { channels, selectedChannelItem } = storeToRefs(tvChannelsStore);
    const { isActiveSubscription, isPartnerSubscription, subscription, currentOffer } = storeToRefs(useSessionStore());

    const { openTvChannelPage } = useTvChannelActions();
    const { openContentPage } = useMediaContentActions();
    const { isAuth } = useSessionVariables();
    const { isCDNLink, getCDNLink } = useCDNImage();
    const { loadImage } = useImage();

    const channelsContainer = ref(null);
    const list = ref([]);
    const offsetTopPx = ref(0);
    const activeChannel = ref(null);
    const channelBackground = ref(null);
    const partnerSubscriptionModalShown = ref(false);

    const { el, focusKey, focusSelf } = useNavigatable({
      focusKey: FocusKeys.CHANNELS_PAGE,
      saveLastFocusedChild: true,
    });

    provide('parentFocusKey', focusKey.value);

    const disposableStore = new DisposableStore();

    const enableBackgroundTransition = ref(false);

    const handleActiveItem = (channel, list = [], index) => {
      enableBackgroundTransition.value = false;

      tvPageAnalytics.onClickTvBeltSwiped(index > selectedItem.value?.rowId ? 'right' : 'left');
      catalogStore.updateSelectedItem({ rowId: index, value: channel });

      const background = channel.currentProgram?.background ?? channel.background;
      const backgroundSrc = !isCDNLink(background) ? getCDNLink(background, 250) : background;

      const onLoadHandler = async () => {
        if (channel.id !== activeChannel.value?.id) {
          return;
        }

        channelBackground.value = backgroundSrc;

        window.setTimeout(() => {
          enableBackgroundTransition.value = true;

          window.setTimeout(() => {
            if (activeChannel.value !== channel) {
              return;
            }

            const src = getCDNLink(background, 1000);

            channelBackground.value = src;
          }, 1000);
        }, 200);
      };

      const onErrorHandler = () => {
        if (channel.id !== activeChannel.value?.id) {
          return;
        }

        channelBackground.value = DefaultChannelsBackground;
      };

      loadImage(backgroundSrc, onLoadHandler, onErrorHandler);

      const element = list[index];

      if (!element) {
        throw new UnexpectedComponentStateError('element');
      }

      const maxOffsetTop = element.parentElement?.scrollHeight - channelsContainer.value?.offsetHeight;
      const offset = element.offsetTop === 0 ? 0 : element.offsetTop;

      offsetTopPx.value = offset > maxOffsetTop && maxOffsetTop > 0 ? maxOffsetTop : offset;
      activeChannel.value = channel;

      tvChannelsStore.updateSelectedChannelItem({ focusKey: FocusKeys.CHANNEL(index) });
    };

    const handleChannelClick = (channel) => {
      if (
        channel.accessKind === ContentAccessTypes.Subscription &&
        isPartnerSubscription.value &&
        !isActiveSubscription.value
      ) {
        partnerSubscriptionModalShown.value = true;
        return;
      }

      openTvChannelPage(channel);
    };

    const closePartnerSubscriptionModal = async () => {
      partnerSubscriptionModalShown.value = false;
      focusSelf();
    };

    const activeChannelButtonText = computed(() =>
      playerHelpers.getPlayButtonText({
        isActiveSubscription: isActiveSubscription.value,
        isAuth: isAuth.value,
        isPartnerSubscription: isPartnerSubscription.value,
        isLive: true,
        isVOD: false,
        subscription: subscription.value,
        offer: currentOffer.value,
      }),
    );

    const getTvProgramStartTime = (startTime) => {
      const time = parseISO(startTime);

      return isValid(time) ? format(time, 'HH:mm') : '';
    };

    const getTvProgramEndTime = (startTime, duration = 0) => {
      const time = parseISO(startTime);

      if (isValid(time)) {
        return format(addSeconds(time, duration), 'HH:mm');
      }

      return '';
    };

    const isShowAboutMovie = computed(
      () =>
        activeChannel.value?.availability &&
        activeChannel.value?.currentProgram?.contentId &&
        activeChannel.value?.currentProgram?.contentType,
    );

    const selectChannelRowByIndex = async (index) => {
      let idx = index;

      if (idx <= 0) {
        idx = 0;
      }

      if (idx >= channels.value.length) {
        idx = channels.value.length - 1;
      }

      SpatialNavigation.setFocus(FocusKeys.CHANNEL(idx));
    };

    const onPrevChannel = () => selectChannelRowByIndex((selectedItem.value?.rowId || 0) - 1);

    const onNextChannel = () => selectChannelRowByIndex((selectedItem.value?.rowId || 0) + 1);

    const selectChannelById = async () => {
      const query = Object.assign({}, route.query);

      const index = channels.value.findIndex((channel) => channel.id === query.channelId);

      if (!indexOutOfRange(index)) {
        delete query.channelId;

        await routerService.replace({ query });
        SpatialNavigation.setFocus(FocusKeys.CHANNEL(index));
      }
    };

    const restoreSelectedChannelItem = () => {
      const focusKey = selectedChannelItem.value.focusKey;

      if (SpatialNavigation.doesFocusableExist(focusKey)) {
        return SpatialNavigation.setFocus(focusKey);
      }

      SpatialNavigation.setFocus(FocusKeys.CHANNEL(selectedItem.value?.rowId || 0));
    };

    const handleWatchClick = () => {
      if (!activeChannel.value?.currentProgram) {
        return;
      }

      const contentType =
        activeChannel.value.currentProgram.contentType === MediaContentType.Episode
          ? MediaContentType.Serial
          : activeChannel.value.currentProgram.contentType;
      const id =
        activeChannel.value.currentProgram.contentType === MediaContentType.Episode
          ? activeChannel.value.currentProgram.serialId
          : activeChannel.value.currentProgram.contentId;

      openContentPage({
        id,
        contentType,
        title: activeChannel.value.currentProgram.title,
        episodeId: activeChannel.value.currentProgram.contentId,
        seasonNumber: activeChannel.value.currentProgram.seasonNumber,
        episodeNumber: activeChannel.value.currentProgram.episodeNumber,
      });
    };

    onActivated(async () => {
      tvPageAnalytics.onShowTvChannelsPage();

      if (!channels.value.length) {
        return;
      }

      restoreSelectedChannelItem();
    });

    onMounted(async () => {
      await channelsService.fetchChannels();

      disposableStore.add(keyboardEventHandler.on(TvKeyCode.CHANNEL_DOWN, onNextChannel));
      disposableStore.add(keyboardEventHandler.on(TvKeyCode.CHANNEL_UP, onPrevChannel));

      if (route.query.channelId) {
        return selectChannelById();
      }

      focusSelf();
    });

    onBeforeUnmount(() => {
      disposableStore.dispose();

      catalogStore.updateSelectedItem(null);
    });

    return {
      el,
      channelsContainer,
      list,
      offsetTopPx,
      activeChannel,
      channelBackground,
      partnerSubscriptionModalShown,
      enableBackgroundTransition,
      handleActiveItem,
      handleChannelClick,
      closePartnerSubscriptionModal,
      activeChannelButtonText,
      getTvProgramStartTime,
      getTvProgramEndTime,
      isShowAboutMovie,
      FocusKeys,
      AppButton,
      onVNodeMounted,
      isVNodeMounted,
      channels,
      AppSlotButton,
      handleWatchClick,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  display: flex;
  flex-direction: row;
  margin-left: adjust.adjustPx(140px);
  padding-top: adjust.adjustPx(60px);
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &Transition {
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
  }
}

.gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image:
    linear-gradient(0deg, var(--color-bg-primary) 0%, var(--color-bg-primary-transparent) 100%),
    linear-gradient(270deg, var(--color-bg-primary-transparent) 50%, var(--color-bg-primary) 100%),
    linear-gradient(180deg, var(--color-bg-primary) 0%, var(--color-bg-primary-transparent) 30%),
    linear-gradient(180deg, var(--color-bg-primary-transparent) 50%, var(--color-bg-primary) 100%);
}

.channels {
  position: absolute;
  top: adjust.adjustPx(60px);
  bottom: adjust.adjustPx(60px);
  left: adjust.adjustPx(160px);
  width: adjust.adjustPx(330px);
}

.list {
  position: relative;

  li {
    margin-top: adjust.adjustPx(20px);

    &:first-child {
      margin-top: 0;
    }
  }
}

.item {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  padding: adjust.adjustPx(40px) adjust.adjustPx(48px);
  width: adjust.adjustPx(330px);
  height: adjust.adjustPx(132px);
  border-radius: adjust.adjustPx(24px);
  outline: none;
  background-color: var(--color-bg-secondary);

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: var(--color-bg-secondary);
  }

  &Selected {
    background-color: var(--color-bg-tertiary);
  }
}

.logo {
  width: adjust.adjustPx(234px);
  height: adjust.adjustPx(51px);
}

.details {
  position: absolute;
  top: adjust.adjustPx(60px);
  bottom: adjust.adjustPx(60px);
  left: adjust.adjustPx(550px);
  right: adjust.adjustPx(60px);
}

.title {
  color: var(--color-text-primary);

  @include smartTvFonts.SmartTvTitle-2();
}

.time {
  margin-top: adjust.adjustPx(12px);
  color: var(--color-text-primary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvBody-1();
}

.message {
  margin-top: adjust.adjustPx(12px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-2();
}

.controls {
  margin-top: adjust.adjustPx(32px);

  &Active {
    background-color: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.price {
  margin-top: adjust.adjustPx(12px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvCaption-1();
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footerTitle {
  color: var(--color-text-secondary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvHeadline-3();
}

.program {
  display: flex;
  margin-top: adjust.adjustPx(32px);
  color: var(--color-text-primary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvBody-1();

  &Time {
    padding-right: adjust.adjustPx(40px);
  }

  &Title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
