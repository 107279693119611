import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender, GAValue } from '../index';

export enum OfferPageFrom {
  WatchButton = 'watch_button',
  AfterFreeWatch = 'after_free_watch',
  ActionButton = 'action_button',
  AfterAuth = 'after_auth',
  FreeTV = 'free_tv',
}

interface GotoOfferPagePayload {
  page: AnalyticPageName;
  from?: OfferPageFrom;
  itemId?: string;
  episodeId?: string;
  kinomId?: string;
}

export function useSubscribeAnalytics(sender: AnalyticEventSender) {
  const onShowSubscribeOfferPage = (offerType: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribeOfferPage,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.OfferType,
          value: offerType,
        },
      ],
    });
  };

  const onClickSubscribeOfferChoose = (offerId: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeOfferChoose,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Offer,
          value: offerId,
        },
      ],
    });
  };

  const onGotoOfferPage = (payload: GotoOfferPagePayload) => {
    const values: GAValue[] = [
      {
        label: AnalyticPayloadLabelName.From,
        value: payload.from,
      },
    ];

    if (payload.itemId) {
      values.push({
        label: AnalyticPayloadLabelName.ItemId,
        value: payload.itemId,
      });

      if (payload.kinomId) {
        values.push({
          label: AnalyticPayloadLabelName.KinomId,
          value: payload.kinomId,
        });
      }
    }

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoOfferPage,
      page: payload.page,
      values,
    });
  };

  return {
    onShowSubscribeOfferPage,
    onClickSubscribeOfferChoose,
    onGotoOfferPage,
  };
}
