var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container"},[_c('ScrollViewport',{ref:"el",class:_vm.$style.list,attrs:{"tag":"ul","x":_vm.offsetLeftPx,"role":"list"}},_vm._l((_vm.items),function(content,index){return _c('li',{key:content.id,class:_vm.$style.item},[_c('NavigatableItem',{class:_vm.$style.link,attrs:{"tag":_vm.AppSlotButton,"data-testid":("collection-" + (index + 1)),"data-index":index,"prop-parent-focus-key":_vm.FocusKeys.MEDIA_CARD_COLLECTION_LIST_SLIDER},on:{"active":_vm.handleActiveContent,"click":function($event){return _vm.openContentPage({
            contentType: content.contentType,
            id: content.id,
            title: content.title,
            titlesSelectionName: content.title,
            titlesSelectionPosition: index,
            from: _vm.ItemPageFrom.Compilation,
          })}}},[_c('app-image',{class:_vm.$style.preview,attrs:{"src":content.poster,"width":550}})],1),_c('section',{class:_vm.$style.subtitle},[_c('p',[_vm._v(_vm._s(content.title))])])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }