<template>
  <UITypography v-if="isLoading" is-loading :lines="2" shimmer-variant="body" />
  <header v-else :class="$style.header">
    <p v-if="restOfTime">
      {{
        $t('media.restOfTime', {
          remain: pluralize(restOfTime, 'pluralizedUnit.remain'),
          restOfTime,
          pluralizedText: pluralize(restOfTime, 'durationUnit.minute'),
        })
      }}
    </p>
    <p v-if="percent" :class="$style.approach">
      {{ $t('media.relevant', { percent }) }}
    </p>

    <p :class="$style.caption">
      {{ subtitle }}
      <span v-if="subtitle && limit" :class="$style.limit">
        {{ limit }}
      </span>
    </p>

    <h1 :class="$style.title">{{ title }}</h1>
  </header>
</template>

<script>
import { pluralize } from '@SMART/index';

import UITypography from '../typography/UITypography.vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    limit: {
      type: String,
      default: '',
    },
    percent: {
      type: [String, Number],
      default: '',
    },
    restOfTime: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UITypography,
  },
  setup() {
    return {
      pluralize,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.header {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-wrapper);
  min-height: adjust.adjustPx(135px);
}

.approach {
  display: inline-flex;
  align-items: center;
  margin-bottom: adjust.adjustPx(8px);
  margin-right: adjust.adjustPx(16px);
  color: var(--color-text-link);

  @include smartTvFonts.SmartTvCaption-1();
}

.title {
  max-width: adjust.adjustPx(970px);

  @include smartTvFonts.SmartTvTitle-1();
}

.caption {
  display: inline-flex;
  align-items: center;
  margin-bottom: adjust.adjustPx(12px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvCaption-1();
}

.limit {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-left: adjust.adjustPx(12px);
  width: adjust.adjustPx(47px);
  height: adjust.adjustPx(26px);
  border: adjust.adjustPx(2px) solid var(--color-text-secondary);
  border-radius: adjust.adjustPx(8px);
  font-size: adjust.adjustPx(18px);
}
</style>
