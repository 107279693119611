<template>
  <div ref="el" :class="$style.auth">
    <BackButton />

    <div v-show="!isQrLoginImageSectionMounted || isCodeWaiting" :class="$style.instructions">
      <shimmer-layout :class="$style.shimmer" :items="loginPageShimmers" />
    </div>

    <div v-show="isQrLoginImageSectionMounted && !isCodeWaiting" :class="$style.instructions">
      <qr-login-image-section
        :code="code"
        @update:code="onCodeUpdate"
        @mounted="
          () => {
            onQrLoginImageSectionMounted();
            setFocus();
          }
        "
      />
      <qr-login-code-section v-if="isQrLoginImageSectionMounted" :code-url="codeURL" :code="code" />
    </div>

    <section v-if="isQrLoginImageSectionMounted && !isCodeWaiting" :class="$style.controls">
      <NavigatableItem
        :tag="AppButton"
        :active-class="$style.active"
        :text="$t('pages.auth.qrLogin.loginManually')"
        :is-loading="isCodeWaiting"
        :focus-key="FocusKeys.AUTH_QR_LOGIN_MANUALLY"
        :on-click="onChangeLoginMethod"
      />

      <NavigatableItem
        :tag="AppButton"
        variation="smart-button-secondary"
        :active-class="$style.active"
        :text="$t('pages.auth.qrLogin.helpButton')"
        :is-loading="isCodeWaiting"
        @click="onToggleHelp(true)"
      />
    </section>

    <FullScreenModal v-if="isHelpSectionShown" :is-show-background="false">
      <HelpContacts @close="onToggleHelp(false)" />
    </FullScreenModal>
  </div>
</template>

<script>
import {
  AnalyticEventNameNew,
  AnalyticPageName,
  useAccountPageAnalytics,
  useAuthPageAnalytics,
} from '@package/sdk/src/analytics';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { analyticService, environmentService, FocusKeys, RouterPage, routerService, WebAppRoute } from '@SMART/index';
import { nextTick, onMounted, provide, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import BackButton from '@/components/back-button/BackButton.vue';
import FullScreenModal from '@/components/modal/FullScreenModal.vue';
import ShimmerLayout from '@/components/shimmer-layout/ShimmerLayout.vue';
import { loginPageShimmers } from '@/components/shimmer-layout/useShimmers';
import QrLoginCodeSection from '@/pages/auth/components/QrLoginCodeSection.vue';
import QrLoginImageSection from '@/pages/auth/components/QrLoginImageSection.vue';
import HelpContacts from '@/pages/settings/components/HelpContacts.vue';

export default {
  components: {
    AppButton,
    BackButton,
    FullScreenModal,
    ShimmerLayout,
    QrLoginCodeSection,
    QrLoginImageSection,
    HelpContacts,
  },
  setup() {
    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.QR_LOGIN,
      forceFocus: true,
      saveLastFocusedChild: false,
    });
    provide('parentFocusKey', focusKey.value);

    const { isVNodeMounted: isQrLoginImageSectionMounted, onVNodeMounted: onQrLoginImageSectionMounted } =
      useVNodeMounted({
        withTimeout: true,
        timeout: 500,
      });
    const authPageAnalytics = useAuthPageAnalytics(analyticService.sender);
    const accountPageAnalytics = useAccountPageAnalytics(analyticService.sender);

    const code = ref('');
    const isHelpSectionShown = ref(false);
    const isCodeWaiting = ref(true);

    const webAppURL = environmentService.getVariable('webAppURL');
    const codeURL = (webAppURL + WebAppRoute.SmartTvCode).replace('https://', '');

    const onCodeUpdate = (value) => {
      code.value = value;

      if (value) {
        setTimeout(() => {
          isCodeWaiting.value = false;
        }, 1000);
      }
    };

    const setFocus = () => {
      SpatialNavigation.setFocus(FocusKeys.AUTH_QR_LOGIN_MANUALLY);
    };

    const onChangeLoginMethod = () => {
      accountPageAnalytics.onClickAuthLoginTv();
      routerService.push({ name: RouterPage.LoginPage });
    };

    const onToggleHelp = (state) => {
      isHelpSectionShown.value = state;

      if (state) {
        analyticService.sendEvent({
          name: AnalyticEventNameNew.GotoContactsPage,
          page: AnalyticPageName.Account,
        });

        return;
      }

      nextTick(setFocus);
    };

    onMounted(() => {
      authPageAnalytics.onShowAuthPage();
    });

    return {
      el,
      code,
      isHelpSectionShown,
      isCodeWaiting,
      codeURL,
      isQrLoginImageSectionMounted,
      onQrLoginImageSectionMounted,
      setFocus,
      onChangeLoginMethod,
      onToggleHelp,
      loginPageShimmers,
      onCodeUpdate,
      AppButton,
      FocusKeys,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.auth {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  padding: {
    bottom: adjust.adjustPx(60px);
    left: adjust.adjustPx(60px);
    right: adjust.adjustPx(60px);
  }
}

.shimmer {
  display: flex;
  flex-wrap: wrap;
}

.instructions {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  flex-wrap: wrap;

  @include smartTvFonts.SmartTvBody-2;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: adjust.adjustPx(24px);
  width: 100%;

  > :first-child {
    margin-right: adjust.adjustPx(24px);
  }
}

.active {
  color: var(--color-notheme-text-accent);
}
</style>
