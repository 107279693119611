import {
  defaultFeatureToggleValues,
  type FeatureToggle,
  type FeatureToggleItem,
} from '@package/unleash/src/feature-toggle/index';
import type { UnleashApiGetRequestHeadersOptions } from '@package/unleash/src/main';
import { UnleashApi } from '@package/unleash/src/main';

import type { EnvironmentService } from '../environment/environment-service';
import type { RequestService } from '../request-service';

export class FeatureService {
  private featureFlags: FeatureToggleItem[] = [];
  private unleashApi: UnleashApi;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly requestService: RequestService,
  ) {
    this.unleashApi = new UnleashApi({
      baseURL: this.environmentService.getVariable('apiFlagsBaseURL'),
      adapter: 'xhr',
      getRequestHeaders: (options: UnleashApiGetRequestHeadersOptions) =>
        this.requestService.getRequestHeaders({
          method: options.method,
          path: options.request,
          body: options.body,
          withToken: true,
        }),
    });
  }

  public getFeatureFlag(name: FeatureToggle): FeatureToggleItem {
    const flag = this.featureFlags.find((feature) => feature.name === name);

    if (flag) {
      return flag;
    }

    return defaultFeatureToggleValues.find((feature) => feature.name) as FeatureToggleItem;
  }

  public async fetchFeatureFlags(): Promise<void> {
    this.featureFlags = await this.unleashApi.fetchByAliases();
  }
}
