import { ProcessingMapper } from '../base/processing-mapper';
import { ChannelMapper } from './channel';
import type { ApiChannelCategory } from './channel-types';
import type { ChannelCategory } from './types/channel';

export class ChannelCategoryMapper {
  static mapMany(items: ApiChannelCategory[]): ChannelCategory[] {
    return ProcessingMapper.process(items.map(ChannelCategoryMapper.map));
  }

  static map(data: ApiChannelCategory): ChannelCategory {
    const { name, channels } = data;

    return ProcessingMapper.process({
      name,
      channels: channels ? ChannelMapper.mapMany(channels) : [],
    });
  }
}
