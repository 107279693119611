<template>
  <div ref="el" :class="$style.episode">
    <div :class="$style.episodePreview">
      <app-image :src="episode.preview" :width="400" />
      <div v-if="!isActiveSubscription && !isPartnerSubscription" :class="$style.hint">
        {{ $t('player.freeContent.availableAfterSubscription') }}
      </div>
    </div>

    <h2 :class="$style.episodeTitle">
      <span>{{ $t('player.freeContent.episodeNumber', { number: episode && episode.number }) }}</span>
    </h2>

    <p :class="$style.episodeDescription">
      {{ episode && episode.description }}
    </p>

    <p :class="$style.episodeTimeLeft">
      {{ $t('player.freeContent.durationLeftInMinutes', { minutes: durationLeftInMinutes }) }}
    </p>
    <NavigatableItem
      v-if="!isActiveSubscription && !isPartnerSubscription"
      :class="$style.nextButton"
      :tag="UIButton"
      variation="default"
      has-global-access
      :on-click="requestNextEpisode"
      focus-key="NEXT_EPISODE_PREVIEW"
      @focus="$emit('active')"
      @blur="$emit('blur')"
    >
      <div :class="$style.nextButtonSlot">
        <p :class="$style.title">{{ primaryButtonText }}</p>
      </div>
    </NavigatableItem>
  </div>
</template>

<script>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import { AvailableContentType } from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';
import { storeToRefs, translateContentButtonText, useSessionStore, useSessionVariables } from '@SMART/index';
import { computed, onMounted, ref } from '@vue/composition-api';

import AppImage from '@/components/app-image/AppImage.vue';
import UIButton from '@/components/button/UIButton.vue';

export default {
  components: {
    AppImage,
    UIButton,
  },
  data: () => ({ UIButton }),
  props: {
    episode: { type: Object },
    season: { type: Object },
    seasons: { type: Array },
    requestNextEpisode: { type: Function },
  },
  setup(props) {
    const { isAuth } = useSessionVariables();
    const { isActiveSubscription, isPartnerSubscription, isPartnerUser, currentOffer } = storeToRefs(useSessionStore());
    const { getContentPlayButtonText } = useContentMediaButtonTexts();
    const durationLeftInMinutes = ref(0);

    const getAvailableEpisodeId = () => {
      const seasons = props.seasons;
      const count = seasons.length;

      for (let availableSeasonIndex = 0; availableSeasonIndex < count; availableSeasonIndex++) {
        const season = seasons[availableSeasonIndex];
        if (season.availability === AvailableContentType.AVAILABLE) {
          const availableEpisodeIndex = seasons[availableSeasonIndex].episodes.findIndex(
            (episode) => episode.availability === AvailableContentType.AVAILABLE,
          );

          if (availableEpisodeIndex !== -1) {
            durationLeftInMinutes.value = Math.round(
              seasons[availableSeasonIndex].episodes[availableEpisodeIndex].duration / 60,
            );

            return {
              id: seasons[availableSeasonIndex].episodes[availableEpisodeIndex].id,
              availableEpisodeIndex,
              availableSeasonIndex,
            };
          }
        }
      }
    };

    const primaryButtonText = computed(() => {
      const episodeInfo = getAvailableEpisodeId();

      let episodeData;
      let episodeAccessKind;

      if (props.seasons && episodeInfo) {
        const season = props.seasons[episodeInfo?.availableSeasonIndex];
        const episode = season.episodes[episodeInfo?.availableEpisodeIndex];
        episodeAccessKind = episode.accessKind;

        episodeData = {
          season: season.number,
          episode: episode.number,
        };
      }

      return translateContentButtonText(
        getContentPlayButtonText({
          isActiveSubscription: isActiveSubscription.value,
          isAuth: isAuth.value,
          isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
          accessKind: props.withAccessKind ? episodeAccessKind : undefined,
          offer: currentOffer.value,
          canContinueWatch: isDefined(props.episode?.watchingItem),
          episodeData,
        }),
      );
    });
    onMounted(() => {
      getAvailableEpisodeId();
    });

    return {
      isActiveSubscription,
      isPartnerSubscription,
      primaryButtonText,
      durationLeftInMinutes,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.episode {
  position: absolute;
  bottom: adjust.adjustPx(78px);
  left: adjust.adjustPx(-110px);
  transform: translateY(adjust.adjustPx(-90px));

  display: flex;
  flex-direction: column;
  padding: adjust.adjustPx(24px);
  width: 100%;
  width: adjust.adjustPx(500px);
  min-height: adjust.adjustPx(488px);
  border: none;
  border-radius: adjust.adjustPx(32px);
  background-color: var(--color-notheme-bg-secondary-80);
  text-align: left;

  & > span {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.episodePreview {
  position: relative;
  width: adjust.adjustPx(452px);
  height: adjust.adjustPx(230px);
  border-radius: adjust.adjustPx(16px);
  overflow: hidden;

  @include smartTvFonts.SmartTvLabel-1();

  img {
    width: adjust.adjustPx(452px);
    height: adjust.adjustPx(230px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--g-border-round-24);
    background: var(--color-notheme-dim-black-40);
  }
}

.episodePreviewTimeline {
  position: absolute;
  bottom: 0;
  z-index: 99;
  height: adjust.adjustPx(6px);
  border-radius: adjust.adjustPx(4px);
  background-color: var(--color-notheme-bg-accent);
}

.hint {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  width: adjust.adjustPx(230px);
  color: var(--color-text-primary);
  text-wrap: wrap;
  text-align: center;

  @include smartTvFonts.SmartTvLabel-3();
}

.episodeTitle {
  margin-top: adjust.adjustPx(32px);
  width: adjust.adjustPx(452px);
  overflow: hidden;
  color: var(--color-notheme-text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  @include smartTvFonts.SmartTvLabel-1();
}

.episodeDescription {
  margin: adjust.adjustPx(8px) 0 adjust.adjustPx(16px) 0;

  width: adjust.adjustPx(452px);
  overflow: hidden;
  color: var(--color-notheme-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  @include smartTvFonts.SmartTvBody-2();

  @supports (-webkit-line-clamp: 2) {
    display: -webkit-box;
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.episodeTimeLeft {
  margin: 0 0 adjust.adjustPx(16px) 0;
  width: adjust.adjustPx(368px);
  overflow: hidden;
  color: var(--color-notheme-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  @include smartTvFonts.SmartTvLabel-3();

  @supports (-webkit-line-clamp: 2) {
    display: -webkit-box;
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.episodeStatus {
  margin-top: adjust.adjustPx(16px);
  overflow: hidden;
  color: var(--color-notheme-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;

  @include smartTvFonts.SmartTvBody-1();
}

.nextButtonSlot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nextButton {
  display: flex;
  align-items: center;
  margin-left: adjust.adjustPx(24px);
  padding-bottom: adjust.adjustPx(13px);
  border: none;
  border-radius: adjust.adjustPx(18px);
  outline: none;
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);

  @include smartTvFonts.SmartTvLabel-2();
}

.title {
  @include smartTvFonts.SmartTvLabel-2();
}

.active {
  box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
}

.selected {
  background-color: var(--color-notheme-bg-hover-70);
}

.disabled {
  color: var(--color-notheme-text-disabled);
}
</style>
