<template>
  <div>
    <li v-for="(item, index) in items" ref="el" :key="index" :class="{ [$style.contentRow]: true }" v-bind="$attrs">
      <slot :item="item" :index="index" />
    </li>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      el: null,
    };
  },
  mounted() {
    this.el = this.$refs.el;
    this.$emit('vue:mounted');
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.contentRow {
  display: flex;
  margin-bottom: adjust.adjustPx(16px);
}
</style>
