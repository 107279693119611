<template>
  <div ref="el">
    <section :class="$style.linkToTop">
      <NavigatableItem
        :class="$style.button"
        :tag="AppButton"
        has-global-access
        :text="$t('pages.mediaCard.toTop')"
        @active="$emit('active')"
        @click="$emit('click')"
      />
    </section>
  </div>
</template>

<script>
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys } from '@SMART/index';
import { getCurrentInstance, provide } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';

export default {
  setup(_, { root }) {
    const { el, focusKey } = useNavigatable({
      focusKey: FocusKeys.MEDIA_CARD_TO_TOP_BUTTON,
      isFocusBoundary: true,
      hasGlobalAccess: true,
      focusBoundaryDirections: ['left', 'right', 'down'],
    });
    provide('parentFocusKey', focusKey.value);

    const emit = getCurrentInstance().emit;

    return {
      el,
      focusKey,
      emit,
      FocusKeys,
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/layers';

.linkToTop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .button {
    width: adjust.adjustPx(325px);
    height: adjust.adjustPx(88px);
    border-radius: var(--g-border-round-16);
    outline: none;
  }
}
</style>
