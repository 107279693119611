import { UTCDate } from '@date-fns/utc';
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { translate } from '@PLAYER/player/modules/localization/translate';

export function formatTime(seconds: number) {
  const displayedHours = Math.floor(Math.abs(seconds) / (60 * 60));
  const displayedMinutes = Math.floor(Math.abs(seconds) / 60) % 60;
  const displayedSeconds = Math.floor(Math.abs(seconds) % 60);

  const normalizeTime = (time: number) => time.toString().padStart(2, '0');

  const sign = seconds < 0 && Math.floor(Math.abs(seconds)) ? '-' : '';

  return `${sign}${normalizeTime(displayedHours)}:${normalizeTime(displayedMinutes)}:${normalizeTime(displayedSeconds)}`;
}

export function formatTimeWithLang(duration: number) {
  const mseconds = duration * 1000;

  const hours = new UTCDate(mseconds).getHours();
  const minutes = new UTCDate(mseconds).getMinutes();

  const formattedHours = translate('content.metaInfo.duration.hours', { hours });
  const formattedMinutes = translate('content.metaInfo.duration.minutes', { minutes });

  if (!hours) {
    return formattedMinutes;
  } else if (!minutes) {
    return formattedHours;
  } else {
    return `${formattedHours} ${formattedMinutes}`;
  }
}

export function getTimeInSeconds(date: Date) {
  const todayTimestampInMs = new Date(date).getTime() - new Date().setHours(0, 0, 0, 0);
  const todayTimestampInSeconds = todayTimestampInMs / 1000;

  return Math.round(todayTimestampInSeconds);
}

export function getNormalizedCurrentShownTime(timeInSeconds: number) {
  let normalizedCurrentShownTime = timeInSeconds;

  if (timeInSeconds < 0) {
    const oneDayInSeconds = ConstantsConfigPlayer.getProperty('oneDayMs') / 1000;
    normalizedCurrentShownTime += oneDayInSeconds;
  }

  return Math.trunc(normalizedCurrentShownTime);
}
