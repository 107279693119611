var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"el",class:_vm.$style.header,attrs:{"role":"tablist"}},[_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.contentContainer},[_c('UITabs',{class:_vm.$style.tabs,attrs:{"tab":_vm.contentTab},scopedSlots:_vm._u([{key:"tabs",fn:function(){
var _obj, _obj$1;
return [_c('NavigatableItem',{class:_vm.$style.tabsItem,attrs:{"tag":_vm.UITab,"selected":_vm.moviesSelected,"active-class":_vm.$style.active},on:{"click":function($event){return _vm.onTabSelected(_vm.MediaContentType.Movie)}}},[_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.$t('pages.catalog.filmsTab')))])]),_c('NavigatableItem',{class:( _obj = {}, _obj[_vm.$style.tabsItem] = true, _obj[_vm.$style.showDivider] = _vm.contentTab === 2, _obj ),attrs:{"tag":_vm.UITab,"selected":_vm.serialsSelected,"active-class":_vm.$style.active},on:{"click":function($event){return _vm.onTabSelected(_vm.MediaContentType.Serial)}}},[_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.$t('pages.catalog.serialsTab')))])]),_c('NavigatableItem',{class:( _obj$1 = {}, _obj$1[_vm.$style.tabsItem] = true, _obj$1[_vm.$style.showDivider] = _vm.contentTab === 0, _obj$1 ),attrs:{"tag":_vm.UITab,"selected":_vm.allSelected,"active-class":_vm.$style.active},on:{"click":function($event){return _vm.onTabSelected(_vm.MediaContentType.All)}}},[_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.$t('pages.catalog.allTab')))])])]},proxy:true}])}),_c('NavigatableItem',{attrs:{"focus-key":_vm.FocusKeys.CATALOG_FILTERS_BUTTON,"tag":_vm.AppButton,"disabled":_vm.disabled,"active-class":_vm.$style.active,"navigation-key":"filters","text":_vm.$t('pages.catalog.filters')},on:{"click":_vm.onFiltersOpen},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconFilters',{class:_vm.$style.icon})]},proxy:true}])})],1),_c('NavigatableItem',{class:_vm.$style.shuffle,attrs:{"focus-key":_vm.FocusKeys.CATALOG_SHUFFLE_BUTTON,"tag":_vm.AppButton,"disabled":_vm.disabled,"active-class":_vm.$style.active,"navigation-key":"shuffle","text":_vm.$t('pages.catalog.contentShuffle')},on:{"click":_vm.onSelectShuffle},scopedSlots:_vm._u([{key:"icon",fn:function(){
var _obj;
return [_c('IconShuffle',{class:( _obj = {}, _obj[_vm.$style.icon] = true, _obj[_vm.$style.iconDisabled] = _vm.disabled, _obj )})]},proxy:true}])})],1),(_vm.filterItems.length)?_c('div',{class:_vm.$style.filters},[_c('NavigatableItem',{class:( _obj = {}, _obj[_vm.$style.resetFilters] = true, _obj[_vm.$style.scrolled] = _vm.isFiltersScrolled, _obj ),attrs:{"tag":_vm.AppButton,"disabled":_vm.disabled,"active-class":_vm.$style.active,"text":_vm.$t('pages.catalog.resetFilters')},on:{"click":_vm.resetFilters},scopedSlots:_vm._u([{key:"icon",fn:function(){
          var _obj;
return [_c('IconDeleteOutlined',{class:( _obj = {}, _obj[_vm.$style.icon] = true, _obj[_vm.$style.iconDisabled] = _vm.disabled, _obj )})]},proxy:true}],null,false,1450047153)}),_c('div',{class:_vm.$style.filtersScrollWrapper},[_c('ScrollViewport',{ref:"filtersScroll",class:_vm.$style.filterItems,attrs:{"orientation":"horizontal","role":"list"}},_vm._l((_vm.filterItems),function(filter,index){return _c('NavigatableItem',{key:("filter-" + index),class:_vm.$style.filterItem,attrs:{"focus-key":_vm.FocusKeys.CATALOG_FILTERS_SLIDER_ITEM(index),"tag":_vm.AppButton,"disabled":_vm.disabled,"active-class":_vm.$style.filterItemActive,"text":filter.title},on:{"active":_vm.onFilterSelect,"click":function($event){return _vm.onFilterToggle(filter)}},scopedSlots:_vm._u([{key:"icon-right",fn:function(){return [_c('IconClose',{class:_vm.$style.filterItemIcon})]},proxy:true}],null,true)})}),1)],1)],1):_vm._e(),(_vm.isFiltersModalVisible)?_c('FiltersModal',{attrs:{"filters":_vm.filters},on:{"filter:select":_vm.onFilterToggle,"close":_vm.onFilterModalClose}}):_vm._e(),(_vm.isShuffleModalVisible)?_c('ShuffleModal',{attrs:{"content-type":_vm.contentType},on:{"close":function($event){_vm.isShuffleModalVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }