import { ApplicationError } from '@package/sdk/src/core';

export enum ShakaErrorCategory {
  ADS = 10.0,
  CAST = 8.0,
  DRM = 6.0,
  MANIFEST = 4.0,
  MEDIA = 3.0,
  NETWORK = 1.0,
  PLAYER = 7.0,
  STORAGE = 9.0,
  STREAMING = 5.0,
  TEXT = 2.0,
}

export enum ShakaErrorCode {
  AES_128_INVALID_IV_LENGTH = 4048.0,
  AES_128_INVALID_KEY_LENGTH = 4049.0,
  ALREADY_CASTING = 8002.0,
  ATTEMPTS_EXHAUSTED = 1010.0,
  BAD_ENCODING = 2004.0,
  BAD_HTTP_STATUS = 1001.0,
  BUFFER_READ_OUT_OF_BOUNDS = 3000.0,
  CANNOT_ADD_EXTERNAL_TEXT_TO_LIVE_STREAM = 4033.0,
  CANNOT_ADD_EXTERNAL_TEXT_TO_SRC_EQUALS = 2012.0,
  CANNOT_ADD_EXTERNAL_THUMBNAILS_TO_LIVE_STREAM = 4045.0,
  CANNOT_STORE_LIVE_OFFLINE = 9005.0,
  CAST_API_UNAVAILABLE = 8000.0,
  CAST_CANCELED_BY_USER = 8004.0,
  CAST_CONNECTION_TIMED_OUT = 8005.0,
  CAST_RECEIVER_APP_UNAVAILABLE = 8006.0,
  CHAPTERS_TRACK_FAILED = 2015.0,
  CONTENT_NOT_LOADED = 7004.0,
  CONTENT_TRANSFORMATION_FAILED = 3019.0,
  CONTENT_UNSUPPORTED_BY_BROWSER = 4032.0,
  CS_AD_MANAGER_NOT_INITIALIZED = 10001.0,
  CS_IMA_SDK_MISSING = 10000.0,
  CURRENT_DAI_REQUEST_NOT_FINISHED = 10004.0,
  DASH_CONFLICTING_AES_128 = 4050.0,
  DASH_CONFLICTING_KEY_IDS = 4010.0,
  DASH_DUPLICATE_REPRESENTATION_ID = 4018.0,
  DASH_EMPTY_ADAPTATION_SET = 4003.0,
  DASH_EMPTY_PERIOD = 4004.0,
  DASH_INVALID_PATCH = 4052.0,
  DASH_INVALID_XML = 4001.0,
  DASH_MULTIPLE_KEY_IDS_NOT_SUPPORTED = 4009.0,
  DASH_NO_COMMON_KEY_SYSTEM = 4008.0,
  DASH_NO_SEGMENT_INFO = 4002.0,
  DASH_PSSH_BAD_ENCODING = 4007.0,
  DASH_UNSUPPORTED_AES_128 = 4051.0,
  DASH_UNSUPPORTED_CONTAINER = 4006.0,
  DASH_UNSUPPORTED_XLINK_ACTUATE = 4027.0,
  DASH_WEBM_MISSING_INIT = 4005.0,
  DASH_XLINK_DEPTH_LIMIT = 4028.0,
  DEPRECATED_OPERATION_ABORTED = 9002.0,
  DOWNLOAD_SIZE_CALLBACK_ERROR = 9015.0,
  EBML_BAD_FLOATING_POINT_SIZE = 3003.0,
  EBML_OVERFLOW = 3002.0,
  ENCRYPTED_CONTENT_WITHOUT_DRM_INFO = 6010.0,
  ERROR_CHECKING_HDCP_VERSION = 6019.0,
  EXPIRED = 6014.0,
  FAILED_TO_ATTACH_TO_VIDEO = 6003.0,
  FAILED_TO_CREATE_CDM = 6002.0,
  FAILED_TO_CREATE_SESSION = 6005.0,
  FAILED_TO_GENERATE_LICENSE_REQUEST = 6006.0,
  HLS_COULD_NOT_GUESS_CODECS = 4025.0,
  HLS_EMPTY_MEDIA_PLAYLIST = 4053.0,
  HLS_INVALID_PLAYLIST_HIERARCHY = 4017.0,
  HLS_KEYFORMATS_NOT_SUPPORTED = 4026.0,
  HLS_MSE_ENCRYPTED_LEGACY_APPLE_MEDIA_KEYS_NOT_SUPPORTED = 4041.0,
  HLS_MSE_ENCRYPTED_MP2T_NOT_SUPPORTED = 4040.0,
  HLS_MULTIPLE_MEDIA_INIT_SECTIONS_FOUND = 4020.0,
  HLS_PLAYLIST_HEADER_MISSING = 4015.0,
  HLS_REQUIRED_ATTRIBUTE_MISSING = 4023.0,
  HLS_REQUIRED_TAG_MISSING = 4024.0,
  HLS_VARIABLE_NOT_FOUND = 4039.0,
  HTTP_ERROR = 1002.0,
  INCONSISTENT_DRM_ACROSS_PERIODS = 4038.0,
  INDEXED_DB_ERROR = 9001.0,
  INDEXED_DB_INIT_TIMED_OUT = 9017.0,
  INIT_DATA_TRANSFORM_ERROR = 6016.0,
  INTERSTITIAL_AD_MANAGER_NOT_INITIALIZED = 10006.0,
  INVALID_HLS_TAG = 4016.0,
  INVALID_MP4_CEA = 2010.0,
  INVALID_MP4_TTML = 2007.0,
  INVALID_MP4_VTT = 2008.0,
  INVALID_SERVER_CERTIFICATE = 6004.0,
  INVALID_TEXT_CUE = 2001.0,
  INVALID_TEXT_HEADER = 2000.0,
  INVALID_XML = 2005.0,
  JS_INTEGER_OVERFLOW = 3001.0,
  KEY_NOT_FOUND = 9012.0,
  LICENSE_REQUEST_FAILED = 6007.0,
  LICENSE_RESPONSE_REJECTED = 6008.0,
  LOAD_INTERRUPTED = 7000.0,
  LOCAL_PLAYER_INSTANCE_REQUIRED = 9008.0,
  MALFORMED_DATA_URI = 1004.0,
  MALFORMED_OFFLINE_URI = 9004.0,
  MALFORMED_TEST_URI = 1008.0,
  MEDIA_SOURCE_OPERATION_FAILED = 3014.0,
  MEDIA_SOURCE_OPERATION_THREW = 3015.0,
  MIN_HDCP_VERSION_NOT_MATCH = 6018.0,
  MISSING_STORAGE_CELL = 9013.0,
  MISSING_TEXT_PLUGIN = 2014.0,
  MODIFY_OPERATION_NOT_SUPPORTED = 9016.0,
  MP4_SIDX_INVALID_TIMESCALE = 3005.0,
  MP4_SIDX_TYPE_NOT_SUPPORTED = 3006.0,
  MP4_SIDX_WRONG_BOX_TYPE = 3004.0,
  MSS_INVALID_XML = 4046.0,
  MSS_LIVE_CONTENT_NOT_SUPPORTED = 4047.0,
  MSS_MISSING_DATA_FOR_TRANSMUXING = 3020.0,
  MSS_TRANSMUXING_FAILED = 3022.0,
  MT_AD_MANAGER_NOT_INITIALIZED = 10005.0,
  NEW_KEY_OPERATION_NOT_SUPPORTED = 9011.0,
  NO_CAST_RECEIVERS = 8001.0,
  NO_INIT_DATA_FOR_OFFLINE = 9007.0,
  NO_LICENSE_SERVER_GIVEN = 6012.0,
  NO_RECOGNIZED_KEY_SYSTEMS = 6000.0,
  NO_VARIANTS = 4036.0,
  NO_VIDEO_ELEMENT = 7002.0,
  NO_WEB_CRYPTO_API = 4042.0,
  OBJECT_DESTROYED = 7003.0,
  OFFLINE_SESSION_REMOVED = 6013.0,
  OPERATION_ABORTED = 7001.0,
  PERIOD_FLATTENING_FAILED = 4037.0,
  QUOTA_EXCEEDED_ERROR = 3017.0,
  REQUESTED_ITEM_NOT_FOUND = 9003.0,
  REQUESTED_KEY_SYSTEM_CONFIG_UNAVAILABLE = 6001.0,
  REQUEST_FILTER_ERROR = 1006.0,
  RESPONSE_FILTER_ERROR = 1007.0,
  RESTRICTIONS_CANNOT_BE_MET = 4012.0,
  SEGMENT_MISSING = 1011.0,
  SERVER_CERTIFICATE_REQUEST_FAILED = 6017.0,
  SERVER_CERTIFICATE_REQUIRED = 6015.0,
  SRC_EQUALS_PRELOAD_NOT_SUPPORTED = 7005.0,
  SS_AD_MANAGER_NOT_INITIALIZED = 10003.0,
  SS_IMA_SDK_MISSING = 10002.0,
  STORAGE_LIMIT_REACHED = 9014.0,
  STORAGE_NOT_SUPPORTED = 9000.0,
  STREAMING_ENGINE_STARTUP_INVALID_STATE = 5006.0,
  TEXT_COULD_NOT_GUESS_MIME_TYPE = 2011.0,
  TEXT_ONLY_WEBVTT_SRC_EQUALS = 2013.0,
  TIMEOUT = 1003.0,
  TRANSMUXING_FAILED = 3018.0,
  TRANSMUXING_NO_VIDEO_DATA = 3023.0,
  UNABLE_TO_DETECT_ENCODING = 2003.0,
  UNABLE_TO_EXTRACT_CUE_START_TIME = 2009.0,
  UNABLE_TO_GUESS_MANIFEST_TYPE = 4000.0,
  UNEXPECTED_CAST_ERROR = 8003.0,
  UNEXPECTED_TEST_REQUEST = 1009.0,
  UNSUPPORTED_EXTERNAL_THUMBNAILS_URI = 2017.0,
  UNSUPPORTED_SCHEME = 1000.0,
  VIDEO_ERROR = 3016.0,
  WEBM_CUES_ELEMENT_MISSING = 3007.0,
  WEBM_CUE_TIME_ELEMENT_MISSING = 3013.0,
  WEBM_CUE_TRACK_POSITIONS_ELEMENT_MISSING = 3012.0,
  WEBM_DURATION_ELEMENT_MISSING = 3011.0,
  WEBM_EBML_HEADER_ELEMENT_MISSING = 3008.0,
  WEBM_INFO_ELEMENT_MISSING = 3010.0,
  WEBM_SEGMENT_ELEMENT_MISSING = 3009.0,
}

export enum ShakaErrorSeverity {
  CRITICAL = 2.0,
  RECOVERABLE = 1.0,
}

export enum ShakaLoadMode {
  DESTROYED = 0.0,
  MEDIA_SOURCE = 2.0,
  NOT_LOADED = 1.0,
  SRC_EQUALS = 3.0,
}

export class ShakaRuntimeError extends ApplicationError {
  public readonly name = 'ShakaRuntimeError';

  // @ts-ignore
  constructor(private readonly shakaError: shaka.util.Error) {
    const message = `Code: ${shakaError.code}: Severity: ${shakaError.severity} - ${shakaError.message}`;

    super(message);
  }

  public toJSON(): Record<string, any> {
    return this.shakaError;
  }
}
