<template>
  <section
    :class="{
      [$style.content]: true,
      [$style.contentActive]: isKeyboardHidden,
    }"
  >
    <UITypography
      v-if="isInputEmpty"
      :class="$style.title"
      shimmer-variant="subtitle"
      :is-loading="isFirstChunkLoading"
    >
      {{ $t('pages.search.suggestTitle') }}
    </UITypography>
    <UITypography
      v-else-if="shouldShowNotFound"
      :class="$style.title"
      shimmer-variant="subtitle"
      :is-loading="isFirstChunkLoading"
    >
      {{ $t('pages.search.notFoundTitle') }}
    </UITypography>
    <UITypography
      v-else-if="shouldShowFound"
      :class="$style.title"
      shimmer-variant="subtitle"
      :is-loading="isFirstChunkLoading"
    >
      {{ $t('pages.search.foundTitle', { input }) }}
    </UITypography>

    <div :class="$style.posters">
      <UIContent
        scroll-block="start"
        :on-load-chunk="onLoadCollectionChunk"
        :content-type="CollectionContentType.Content"
        :items-per-row="itemsPerRow"
        :items-per-scroll="itemsPerScroll"
        :first-load-size="firstLoadSize"
        :force-update="shouldUpdateContent"
        :split-first-load="1"
        :is-chunk-loading="isLoading"
        @update:items="onResultsUpdate"
        @activated="onActivatedContent"
        @select:moment="onSelect"
        @active="onActiveContent"
      />
    </div>
  </section>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { ItemPageFrom, useSearchPageAnalytics } from '@package/sdk/src/analytics';
import { CollectionContentType, MediaContentType } from '@package/sdk/src/api';
import { debounce } from '@package/sdk/src/core';
import { analyticService, catalogService, searchService, useMediaContentActions } from '@package/smarttv-base/src';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import { useSessionVariables } from '@SMART/index';
import { computed, onMounted, ref, watch } from '@vue/composition-api';

import UIContent from '@/components/content/UIContent.vue';
import { useUiContent } from '@/components/content/useUiContent';

import UITypography from '../typography/UITypography.vue';

export default {
  props: {
    input: String,
    isSkeletonShown: Boolean,
    isKeyboardHidden: Boolean,
    isInputEmpty: Boolean,
    history: Array,
  },
  components: {
    UIContent,
    UITypography,
  },
  setup(props, { emit }) {
    const searchPageAnalytics = useSearchPageAnalytics(analyticService.sender);
    const { isAuth } = useSessionVariables();
    const { openContentPage } = useMediaContentActions();
    const contentLoaded = ref(false);
    const shouldUpdateContent = ref(false);

    const shouldShowNotFound = computed(() => !isContentFound.value);
    const shouldShowFound = computed(() => props.input && !props.isInputEmpty && isContentFound.value);

    const resultsCount = ref(0);
    const isContentFound = ref(true);

    const onActiveContent = () => {
      emit('keyboard:hide');
    };

    const onResultsUpdate = (items) => {
      if (props.input) {
        resultsCount.value = items.length;
      }
    };

    const onSelectPoster = async (item, _, index) => {
      if (isAuth.value && props.input && props.input.length >= 3 && resultsCount.value) {
        const query = props.input;
        const preparedQuery = query.replace(' ', '');
        const historyQueries = props.history.map(({ query }) => query.replace(' ', ''));

        if (!historyQueries.filter((value) => value.includes(preparedQuery) || preparedQuery.includes(value)).length) {
          await searchService.setSearchHistory({ query: props.input, resultCount: resultsCount.value });
        }
      }

      return openContentPage({
        title: item.title,
        contentType: item.contentType,
        from: ItemPageFrom.Search,
        searchText: props.input,
        numberInResults: index,
        id: item.id,
      });
    };

    const isContentChanging = async () => {
      if (shouldUpdateContent.value) {
        contentLoaded.value = false;
      }

      return shouldUpdateContent.value;
    };

    const onActivatedContent = async () => {
      if (contentLoaded.value) {
        return;
      }

      contentLoaded.value = true;
    };

    const fetchItems = async (_, params) => {
      if (props.isInputEmpty || !isContentFound.value) {
        const items = await catalogService.fetchMovies({ ...params });

        return items || [];
      }

      const items = await searchService.searchByText({
        text: props.input,
        limit: 100,
        ...params,
        perPage: params?.size,
      });
      emit('update:isSearchActive', false);

      if (params?.page === 1) {
        isContentFound.value = Boolean(items.length);

        if (!isContentFound.value) {
          window.setTimeout(() => (shouldUpdateContent.value = true));
        }
      }

      searchPageAnalytics.onSearchResultsDisplayed(props.input, items, true);

      return items || [];
    };

    const onReloadContent = async () => {
      try {
        shouldUpdateContent.value = true;

        if (isAuth.value) {
          emit('history:fetch');
        }
      } finally {
        emit('update:isSearchActive', false);
        emit('update:isInputEmpty', true);
      }
    };

    const search = debounce(() => {
      shouldUpdateContent.value = true;
    }, ConstantsConfigInstanceSmartTV.getProperty('debounceTimeoutModernMs'));

    watch(
      () => props.input,
      () => {
        if (!props.input.length) {
          return onReloadContent();
        }

        search();
      },
    );

    const lastNavigationFocusKey = ref('');

    const restoreFocusKey = (fallback) => {
      const target = lastNavigationFocusKey.value || '';

      SpatialNavigation.doesFocusableExist(target)
        ? SpatialNavigation.setFocus(target)
        : SpatialNavigation.setFocus(fallback);
    };

    const onSelect = (content, index) => {
      lastNavigationFocusKey.value = SpatialNavigation.getCurrentFocusKey();
      onSelectItem(content, index);
    };

    const {
      firstLoadSize,
      itemsPerRow,
      itemsPerScroll,
      onLoadCollectionChunk,
      onSelectItem,
      isLoading,
      isFirstChunkLoading,
    } = useUiContent({
      onSelect: onSelectPoster,
      isContentChanging,
      onLoaded: () => {
        shouldUpdateContent.value = false;
      },
      fetchItems,
      initialLoadSize: 20,
      itemsNumber: {
        perRow: 5,
        perScroll: 4,
      },
    });

    onMounted(() => {
      onReloadContent();
    });

    return {
      shouldShowNotFound,
      shouldShowFound,
      onActiveContent,
      onResultsUpdate,
      onSelectPoster,
      isContentChanging,
      onActivatedContent,
      fetchItems,
      onReloadContent,
      search,
      lastNavigationFocusKey,
      restoreFocusKey,
      onSelect,
      firstLoadSize,
      itemsPerRow,
      itemsPerScroll,
      onLoadCollectionChunk,
      onSelectItem,
      isLoading,
      isFirstChunkLoading,
      CollectionContentType,
      shouldUpdateContent,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/colors';

.content {
  position: absolute;
  top: adjust.adjustPx(680px);
  left: adjust.adjustPx(140px);
  right: 0;
  margin-left: adjust.adjustPx(45px);
  height: 100%;

  &Active {
    top: adjust.adjustPx(80px);
    left: adjust.adjustPx(140px);
    right: 0;
  }
}

.posters {
  position: relative;
  top: adjust.adjustPx(36px);
  display: flex;
  margin-left: adjust.adjustPx(3px);
  height: calc(100% - adjust.adjustPx(80px));
  overflow-x: scroll;

  :global(.row) {
    margin-bottom: adjust.adjustPx(24px);
  }

  :global(.ceil) {
    margin-right: adjust.adjustPx(24px);
  }
}
</style>
